import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Autocomplete, AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { FastField, Field, FieldProps, Form, Formik } from 'formik';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { useDispatch } from 'react-redux';
import { Thunk } from 'store/actions';
import {
  EditUsimValues,
  getMetadataOptions,
  getNodesOptions,
  getProfilesOptions,
  getSitesOptions,
} from 'store/actions/usims';
import { Usim, UsimFields } from 'store/models/usim';
import { boolean, lazy, object, ObjectSchema, string, StringSchema, Lazy, number, mixed, MixedSchema } from 'yup';
import { getTenantsAutocompleteOptions } from 'store/actions/tenants';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { iccidExactRegExp, imsiExactRegExp, keysRegExp, msisdnRegExp, tkRegExp } from 'store/models/usim';
import { Select } from '@athonet/ui/components/Input/Select';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { Fieldset } from '@athonet/ui/components/Input/Fieldset';
import { ToggleButtonGroup } from '@athonet/ui/components/Input/ToggleButton/ToggleButtonGroup';
import { ToggleButton } from '@athonet/ui/components/Input/ToggleButton';
import { FileUpload } from '@athonet/ui/components/Input/FileUpload';
import { CreateModalDescription } from './CreateModalDescription';
import { ConfirmationEditUsim } from './ConfirmationEdit';

type ShowFieldRule<F extends keyof UsimFields> = { field: F; condition: (values: EditUsimValues<F>) => boolean };

export type ShowField<F extends keyof UsimFields> = {
  label?: string;
  fields: F[];
  showIf?: ShowFieldRule<F>[];
  showFieldset?: (values: EditUsimValues<F>) => boolean;
};

export default function EditUsim<F extends keyof UsimFields>({
  onSubmit,
  show,
  initials,
  usims,
  usim,
  create,
}: {
  onSubmit: (values: EditUsimValues<F>) => void;
  show: ShowField<F>[];
  initials: EditUsimValues<F>;
  usims?: number;
  usim?: Usim;
  create?: boolean;
}) {
  const { formatMessage } = useIntl();
  const { dialogClose, confirmationDialogOpen } = useOverlay();
  const [options, setOptions] = useState<Partial<Record<F, AutocompleteItemProps[]>>>({});
  const [optionsLoading, setOptionsLoading] = useState<F | null>(null);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values: EditUsimValues<F>) => {
      if (!usim) {
        return onSubmit(values);
      }

      return confirmationDialogOpen({
        title: formatMessage({ id: 'usims.actions.edit.confirm.title' }, { elements: 1 }),
        onConfirm: async () => void onSubmit(values),
        content: <ConfirmationEditUsim values={values} show={show} usim={usim} />,
        alertMessage: formatMessage({ id: `usims.actions.confirm.itemsAffected` }, { elements: 1, usim: usim.imsi }),
        continueButtonText: formatMessage({ id: `usims.actions.edit.confirm.continueButton` }, { elements: 1 }),
        dataTestid: 'confirm-edit-usim',
      });
    },
    [confirmationDialogOpen, formatMessage, onSubmit, show, usim]
  );

  const handleOptionsChange = useCallback(
    async (query: string, field: F, onChange: (query: string) => Thunk<Promise<AutocompleteItemProps[]>>) => {
      if (!onChange) {
        return;
      }

      setOptionsLoading(field);

      setOptions((prevState) => {
        return {
          ...prevState,
          [field]: [],
        };
      });

      const searchResults = await dispatch(onChange(query));

      setOptions((prevState) => {
        return {
          ...prevState,
          [field]: searchResults,
        };
      });

      setOptionsLoading(null);
    },
    [dispatch]
  );

  useEffect(() => {
    show.forEach(({ fields }) => {
      fields.forEach((showField) => {
        switch (showField) {
          case 'site':
            void handleOptionsChange('', showField, getSitesOptions);
            break;
          case 'node':
            void handleOptionsChange('', showField, getNodesOptions);
            break;
          case 'profile':
            void handleOptionsChange('', showField, getProfilesOptions);
            break;
          case 'tenant':
            void handleOptionsChange('', showField, getTenantsAutocompleteOptions); // query
            break;
          case 'metadata_delete':
          case 'metadata':
            void handleOptionsChange('', showField, getMetadataOptions);
            break;
        }
      });
    });
  }, [handleOptionsChange, show]);

  const metadataSchema = useMemo(() => {
    return lazy((value: Record<string, string> | undefined): Lazy => {
      const keyOption = value ? options['metadata' as F]?.find(({ label }) => label === Object.keys(value)[0]) : null;

      if (keyOption?.label && keyOption?.validation) {
        return object().shape({
          [keyOption.label]: string().matches(new RegExp(keyOption.validation)),
        });
      } else {
        return object().required();
      }
    });
  }, [options]);

  const validationSchema = useMemo(() => {
    let obj: Partial<Record<F, ObjectSchema>> = {};

    show.forEach(({ fields }) => {
      fields.forEach((showField) => {
        switch (showField) {
          case 'name':
            obj = { ...obj, [showField]: string().required() };
            break;
          case 'upload':
            obj = {
              ...obj,
              [showField]: mixed().when('single', {
                is: false,
                then: (uploadSchema: MixedSchema) => uploadSchema.required(),
                otherwise: (uploadSchema: MixedSchema) => uploadSchema.notRequired(),
              }),
            };
            break;
          case 'imsi':
            obj = {
              ...obj,
              [showField]: string().when('single', {
                is: (single) => Boolean(!single && create),
                then: (imsiSchema: StringSchema<string>) => imsiSchema.nullable().matches(imsiExactRegExp),
                otherwise: (imsiSchema: StringSchema<string>) => imsiSchema.matches(imsiExactRegExp).required(),
              }),
            };
            break;
          case 'iccid':
            obj = {
              ...obj,
              [showField]: string().when('single', {
                is: (single) => Boolean(!single && create),
                then: (iccidSchema: StringSchema<string>) => iccidSchema.matches(iccidExactRegExp).notRequired(),
                otherwise: (iccidSchema: StringSchema<string>) =>
                  iccidSchema.required().matches(iccidExactRegExp).required(),
              }),
            };
            break;
          case 'ims_profile':
            obj = { ...obj, [showField]: boolean().required() };
            break;
          case 'msisdn':
            obj = {
              ...obj,
              [showField]: string().when('ims_profile', {
                is: true,
                then: (msisdnSchema: StringSchema<string>) => msisdnSchema.matches(msisdnRegExp).required(),
                otherwise: (msisdnSchema: StringSchema<string>) => msisdnSchema.matches(msisdnRegExp).nullable(),
              }),
            };
            break;
          case 'site':
          case 'node':
          case 'tenant':
            obj = { ...obj, [showField]: object().required() };
            break;
          case 'profile':
            obj = {
              ...obj,
              [showField]:
                show.length === 1 && show[0].fields?.length === 1 ? object().required() : object().nullable(),
            };
            break;
          case 'k':
            obj = {
              ...obj,
              [showField]: string().when(['single'], {
                is: (single) => Boolean((!single && create) || (usim && usim['k'])),
                then: string().notRequired().matches(keysRegExp),
                otherwise: string().matches(keysRegExp).required(),
              }),
            };
            break;
          case 'key_type':
            obj = { ...obj, [showField]: number().required().min(0).max(2) };
            break;
          case 'encrypt':
            obj = { ...obj, [showField]: number().min(0).max(1).required() };
            break;
          case 'use_key':
            obj = {
              ...obj,
              [showField]: string().when(['encrypt', 'use_default_tk'], {
                is: (encryptValue, use_default_tk) => encryptValue === 1 && !use_default_tk && usim && !usim['use_key'],
                then: string().matches(tkRegExp).required(),
                otherwise: string().nullable().matches(tkRegExp),
              }),
            };
            break;
          case 'op':
            obj = {
              ...obj,
              [showField]: string().when(['key_type', 'single'], {
                is: (key_type, single) => {
                  return (key_type === 1 && usim && !usim['op']) || (key_type === 1 && single);
                },
                then: string().required().matches(keysRegExp),
                otherwise: string().nullable().matches(keysRegExp),
              }),
            };
            break;
          case 'opc':
            obj = {
              ...obj,
              [showField]: string().when(['key_type', 'single'], {
                is: (key_type, single) => {
                  return (key_type === 2 && usim && !usim['opc']) || (key_type === 2 && single);
                },
                then: string().required().matches(keysRegExp),
                otherwise: string().nullable().matches(keysRegExp),
              }),
            };
            break;
          case 'metadata':
            obj = {
              ...obj,
              [showField]: metadataSchema,
            };
            break;
        }
      });
    });

    return object().shape(obj as any); // TODO: try to undestand the type to be pushed into yup shape
  }, [create, metadataSchema, show, usim]);

  const fieldSize = useMemo(
    () => (show.length > 2 || show.some((fieldset) => fieldset.fields.length > 2) ? 'small' : undefined),
    [show]
  );

  const fieldTexts = useCallback(
    (showField: F) => {
      switch (showField) {
        case 'site':
          return {
            label: formatMessage({ id: 'usims.actions.changeSite.site_id.label' }),
            helperText: formatMessage({ id: 'usims.actions.changeSite.site_id.error' }),
          };
        case 'node':
          return {
            label: formatMessage({ id: 'usims.actions.changeNode.node_id.label' }),
            helperText: formatMessage({ id: 'usims.actions.changeNode.node_id.error' }),
          };
        case 'profile':
          if (create) {
            return {
              label: formatMessage({ id: 'usims.form.usim.profile_id' }),
              placeholder: formatMessage({ id: 'usims.form.usim.profile_id.placeholder' }),
              helperText: formatMessage({ id: 'usims.form.usim.profile_id.error' }),
            };
          }
          return {
            label: formatMessage({ id: 'usims.form.usim.profile' }),
            helperText: formatMessage({ id: 'usims.actions.changeProfile.profile_id.error' }),
          };
        case 'tenant':
          if (create) {
            return {
              label: formatMessage({ id: 'usims.form.usim.tenant_id' }),
              placeholder: formatMessage({ id: 'usims.form.usim.tenant_id.placeholder' }),
              helperText: formatMessage({ id: 'usims.form.usim.tenant_id.error' }),
            };
          }
          return {
            label: formatMessage({ id: 'usims.actions.changeTenant.tenant_id.label' }),
            helperText: formatMessage({ id: 'usims.actions.changeTenant.tenant_id.error' }),
          };
        default:
          return {
            label: '',
            helperText: '',
          };
      }
    },
    [create, formatMessage]
  );

  const subtitle = useMemo(
    () =>
      typeof usims === 'string'
        ? formatMessage({ id: 'usims.actions.edit.subtitle' }, { usim: usims })
        : usims
        ? formatMessage({ id: 'usims.actions.edit.subtitle.multiple' }, { usims: usims })
        : formatMessage({ id: 'usims.actions.edit.subtitle' }, { usim: initials['imsi' as F] }),

    [formatMessage, initials, usims]
  );

  return (
    <>
      {!create && (
        <Text type="body2" color="text.secondary" sx={{ px: 2 }}>
          {subtitle}
        </Text>
      )}
      <Formik
        initialValues={initials}
        enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, setFieldValue, values }) => {
          return (
            <Form
              noValidate
              autoComplete="off"
              style={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Stack sx={{ flexGrow: 1, overflowY: 'auto', pr: 2, ...(!fieldSize && { pt: 1 }) }} nowrap>
                {show.map(({ fields, label, showIf, showFieldset }) =>
                  showFieldset && !showFieldset(values) ? null : (
                    <Fieldset label={label} direction="column" key={label?.replace(' ', '-') || 'fieldset'}>
                      {fields.map((showField) => {
                        const fieldRule = showIf?.find(({ field }) => field === showField);

                        if (fieldRule && !fieldRule.condition(values)) {
                          return null;
                        }
                        switch (showField) {
                          case 'single':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field }: FieldProps<UsimFields['single']>) => (
                                  <ToggleButtonGroup
                                    data-testid={`field-${showField}`}
                                    size="small"
                                    color={'secondary'}
                                    exclusive={true}
                                    {...field}
                                    onChange={(newValue) => {
                                      if (newValue !== null) setFieldValue(showField, newValue);
                                    }}
                                  >
                                    <ToggleButton value={true}>
                                      {formatMessage({ id: 'usims.form.usim.typology.single' })}
                                    </ToggleButton>
                                    <ToggleButton value={false}>
                                      {formatMessage({ id: 'usims.form.usim.typology.multiple' })}
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                )}
                              </Field>
                            );
                          case 'upload':
                            return (
                              <Fragment key={showField}>
                                <CreateModalDescription />
                                <FastField name={showField} key={showField}>
                                  {({ field }: FieldProps<UsimFields['upload']>) => (
                                    <FileUpload
                                      label={formatMessage({ id: `usims.form.usim.${showField}.label` })}
                                      onDropAccepted={(acceptedFiles: File[]) => {
                                        setFieldValue(field.name, acceptedFiles[0]);
                                      }}
                                      {...field}
                                      value={field.value ? [field.value] : []}
                                      onChange={(acceptedFiles: File[]) => {
                                        setFieldValue(field.name, acceptedFiles[0]);
                                      }}
                                      size="small"
                                      formError={
                                        errors[showField] && formatMessage({ id: `usims.form.usim.${showField}.error` })
                                      }
                                      placeHolder={formatMessage({ id: `usims.form.usim.${showField}.placeholder` })}
                                    />
                                  )}
                                </FastField>
                              </Fragment>
                            );
                          case 'name':
                          case 'imsi':
                          case 'iccid':
                          case 'msisdn':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field }: FieldProps<string>) => (
                                  <TextField
                                    size={fieldSize}
                                    {...field}
                                    value={field.value || ''}
                                    onChange={(e) => {
                                      setFieldValue(showField, e.target.value);
                                    }}
                                    label={formatMessage({ id: `usims.form.usim.${showField}` })}
                                    error={Boolean(errors[showField])}
                                    {...(errors[showField] && {
                                      helperText: formatMessage({ id: `usims.form.usim.${showField}.error` }),
                                    })}
                                    placeholder={formatMessage({ id: `usims.form.usim.${showField}.placeholder` })}
                                  />
                                )}
                              </Field>
                            );
                          case 'ims_profile':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field: { value, ...fieldProps } }: FieldProps<UsimFields['ims_profile']>) => {
                                  return (
                                    <Select
                                      {...fieldProps}
                                      size={fieldSize}
                                      multiple={false}
                                      value={value ? 1 : 0}
                                      onChange={(e) => {
                                        setFieldValue(showField, Boolean(e.target.value));
                                      }}
                                      label={formatMessage({ id: 'usims.form.usim.ims_profile' })}
                                    >
                                      <MenuItem value={1}>{formatMessage({ id: 'common.form.enabled' })}</MenuItem>
                                      <MenuItem value={0}>{formatMessage({ id: 'common.form.disabled' })}</MenuItem>
                                    </Select>
                                  );
                                }}
                              </Field>
                            );
                          case 'site':
                          case 'node':
                          case 'profile':
                          case 'tenant':
                            return (
                              <Field name={showField} key={showField}>
                                {({
                                  field: { value, ...fieldProps },
                                }: FieldProps<UsimFields['site' | 'node' | 'profile' | 'tenant']>) => (
                                  <Autocomplete
                                    {...fieldProps}
                                    size={fieldSize}
                                    value={
                                      value
                                        ? {
                                            value: value.id,
                                            label: value.name,
                                          }
                                        : null
                                    }
                                    loading={optionsLoading === showField}
                                    multiple={false}
                                    onChange={(_, newValue) => {
                                      if (typeof newValue !== 'string') {
                                        setFieldValue(
                                          showField,
                                          newValue && {
                                            id: newValue?.value,
                                            name: newValue?.label,
                                          }
                                        );
                                      }
                                    }}
                                    label={fieldTexts(showField).label}
                                    options={options[showField] || []}
                                    error={Boolean(errors[showField])}
                                    {...(errors[showField] && {
                                      helperText: fieldTexts(showField).helperText,
                                    })}
                                    placeholder={fieldTexts(showField).placeholder}
                                  />
                                )}
                              </Field>
                            );
                          case 'k':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field }: FieldProps<UsimFields['k']>) => (
                                  <TextField
                                    size={fieldSize}
                                    {...field}
                                    onChange={(e) => {
                                      setFieldValue(showField, e.target.value);
                                    }}
                                    label={
                                      usim && usim['k']
                                        ? formatMessage({ id: 'usims.form.usim.k.alreadySet' })
                                        : formatMessage({ id: 'usims.form.usim.k' })
                                    }
                                    placeholder={
                                      usim && usim['k']
                                        ? formatMessage({ id: 'usims.form.usim.k.alreadySetPlaceholder' })
                                        : formatMessage({ id: 'usims.form.usim.k.placeholder' })
                                    }
                                    error={Boolean(errors[showField])}
                                    {...(errors[showField] && {
                                      helperText: formatMessage({ id: 'usims.form.usim.k.error' }),
                                    })}
                                  />
                                )}
                              </Field>
                            );
                          case 'encrypt':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field }: FieldProps<UsimFields['encrypt']>) => (
                                  <Select
                                    size={fieldSize}
                                    {...field}
                                    label={formatMessage({ id: `usims.form.usim.${showField}` })}
                                    error={Boolean(errors[showField])}
                                    {...(errors[showField] && {
                                      helperText: formatMessage({ id: `usims.form.usim.${showField}.error` }),
                                    })}
                                    onChange={(e) => {
                                      const v = e.target.value;
                                      setFieldValue(showField, v);
                                      if (v === 0) {
                                        setFieldValue('use_key', '');
                                        setFieldValue('use_default_tk', true);
                                      }
                                    }}
                                  >
                                    <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.encrypt.1' })}</MenuItem>
                                    <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.encrypt.0' })}</MenuItem>
                                  </Select>
                                )}
                              </Field>
                            );
                          case 'use_default_tk':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field }: FieldProps<UsimFields['use_default_tk']>) => (
                                  <ToggleButtonGroup
                                    size="small"
                                    color={'secondary'}
                                    exclusive={true}
                                    {...field}
                                    onChange={(value) => {
                                      if (value !== null) setFieldValue(showField, value);
                                    }}
                                    data-testid={`field-${showField}`}
                                  >
                                    <ToggleButton value={true}>
                                      {formatMessage({ id: 'usims.form.usim.use_default_tk.true' })}
                                    </ToggleButton>
                                    <ToggleButton value={false}>
                                      {formatMessage({ id: 'usims.form.usim.use_default_tk.false' })}
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                )}
                              </Field>
                            );
                          case 'use_key':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field }: FieldProps<UsimFields['use_key']>) => (
                                  <TextField
                                    fullWidth
                                    size={fieldSize}
                                    {...field}
                                    value={field.value || ''}
                                    label={formatMessage({ id: `usims.form.usim.${showField}` })}
                                    placeholder={formatMessage({ id: `usims.form.usim.${showField}.placeholder` })}
                                    error={Boolean(errors[showField])}
                                    {...(errors[showField] && {
                                      helperText: formatMessage({ id: `usims.form.usim.${showField}.error` }),
                                    })}
                                    onChange={(e) => {
                                      setFieldValue(showField, e.target.value);
                                    }}
                                  />
                                )}
                              </Field>
                            );
                          case 'key_type':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field }: FieldProps<UsimFields['key_type']>) => (
                                  <Select
                                    size={fieldSize}
                                    {...field}
                                    label={formatMessage({ id: `usims.form.usim.${showField}` })}
                                    error={Boolean(errors[showField])}
                                    {...(errors[showField] && {
                                      helperText: formatMessage({ id: `usims.form.usim.${showField}.error` }),
                                    })}
                                    onChange={(e) => {
                                      const v = e.target.value;
                                      setFieldValue(showField, v);
                                      if (v === 0) {
                                        setFieldValue('op', '');
                                        setFieldValue('opc', '');
                                      }
                                      // OP
                                      if (v === 1 && fields.includes('opc' as F)) {
                                        setFieldValue('opc', '');
                                      }
                                      // OPC
                                      if (v === 2 && fields.includes('op' as F)) {
                                        setFieldValue('op', '');
                                      }
                                    }}
                                  >
                                    <MenuItem value={0}>
                                      {formatMessage({ id: 'usims.form.usim.key_type.defaultHSSOP' })}
                                    </MenuItem>
                                    {fields.includes('op' as F) && (
                                      <MenuItem value={1}>
                                        {formatMessage({ id: 'usims.form.usim.key_type.customOP' })}
                                      </MenuItem>
                                    )}
                                    {fields.includes('opc' as F) && (
                                      <MenuItem value={2}>
                                        {formatMessage({ id: 'usims.form.usim.key_type.customOPC' })}
                                      </MenuItem>
                                    )}
                                  </Select>
                                )}
                              </Field>
                            );
                          case 'key_override':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field }: FieldProps<UsimFields['key_override']>) => (
                                  <ToggleButtonGroup
                                    size="small"
                                    color={'secondary'}
                                    exclusive={true}
                                    {...field}
                                    onChange={(value) => {
                                      setFieldValue('key_override', value);
                                      if (!value) {
                                        setFieldValue('op', '');
                                        setFieldValue('opc', '');
                                      }
                                    }}
                                  >
                                    <ToggleButton value={false}>
                                      {formatMessage({ id: 'usims.form.usim.key_override.no' })}
                                    </ToggleButton>
                                    <ToggleButton value={true}>
                                      {formatMessage({ id: 'usims.form.usim.key_override.yes' })}
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                )}
                              </Field>
                            );
                          case 'op':
                          case 'opc':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field }: FieldProps<UsimFields['op' | 'opc']>) => (
                                  <TextField
                                    fullWidth
                                    size={fieldSize}
                                    {...field}
                                    value={field.value || ''}
                                    label={
                                      usim && usim[showField as keyof Usim]
                                        ? formatMessage({ id: `usims.form.usim.${showField}.alreadySet` })
                                        : formatMessage({ id: `usims.form.usim.${showField}` })
                                    }
                                    placeholder={
                                      usim && usim[showField as keyof Usim]
                                        ? formatMessage({
                                            id: `usims.form.usim.${showField}.alreadySetPlaceholder`,
                                          })
                                        : formatMessage({ id: `usims.form.usim.${showField}.placeholder` })
                                    }
                                    error={Boolean(errors[showField])}
                                    {...(errors[showField] && {
                                      helperText: formatMessage({ id: `usims.form.usim.${showField}.error` }),
                                    })}
                                    onChange={(e) => {
                                      setFieldValue(showField, e.target.value);
                                    }}
                                  />
                                )}
                              </Field>
                            );
                          case 'metadata_delete':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field }: FieldProps<string>) => (
                                  <Autocomplete
                                    size={fieldSize}
                                    {...field}
                                    loading={optionsLoading === showField}
                                    multiple={false}
                                    onChange={(_, newValue) => {
                                      setFieldValue(showField, newValue);
                                    }}
                                    label={formatMessage({ id: 'usims.actions.deletemetadata.key' })}
                                    placeholder={formatMessage({
                                      id: 'usims.actions.deletemetadata.key.placeholder',
                                    })}
                                    options={options[showField]?.map((key) => key.label) || []}
                                    error={Boolean(errors[showField])}
                                    {...(errors[showField] && {
                                      helperText: formatMessage({ id: 'usims.actions.deletemetadata.key.error' }),
                                    })}
                                  />
                                )}
                              </Field>
                            );
                          case 'metadata':
                            return (
                              <Field name={showField} key={showField}>
                                {({ field: { value, ...fieldProps } }: FieldProps<{ [key: string]: string }>) => {
                                  const keyName = value ? Object.keys(value)[0] : null;
                                  return (
                                    <Stack direction="row" justify="space-between">
                                      <Box sx={{ flex: '1 1 48%' }}>
                                        <Autocomplete
                                          size={fieldSize}
                                          {...fieldProps}
                                          value={keyName}
                                          loading={optionsLoading === showField}
                                          multiple={false}
                                          onChange={(_, newValue) => {
                                            if (!newValue) {
                                              setFieldValue(showField, null);
                                            } else {
                                              setFieldValue(showField, { [newValue]: '' });
                                            }
                                          }}
                                          label={formatMessage({ id: 'usims.actions.updateMetadata.key' })}
                                          placeholder={formatMessage({
                                            id: 'usims.actions.updateMetadata.key.placeholder',
                                          })}
                                          options={options[showField]?.map((key) => key.label) || []}
                                          error={Boolean(errors[showField])}
                                          {...(errors[showField] && {
                                            helperText: formatMessage({
                                              id: 'usims.actions.updateMetadata.key.error',
                                            }),
                                          })}
                                        />
                                      </Box>
                                      <Box sx={{ flex: '1 1 49%' }}>
                                        <TextField
                                          size={fieldSize}
                                          value={keyName ? value[keyName] : ''}
                                          onChange={(e) => {
                                            if (keyName) {
                                              setFieldValue(showField, { [keyName]: e.target.value });
                                            }
                                          }}
                                          disabled={!keyName}
                                          error={Boolean(errors[showField])}
                                        />
                                      </Box>
                                    </Stack>
                                  );
                                }}
                              </Field>
                            );
                          default:
                            return null;
                        }
                      })}
                    </Fieldset>
                  )
                )}
              </Stack>

              <DialogActions>
                <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                  <Button
                    variant="outlined"
                    data-testid="footer-cancel"
                    onClick={dialogClose}
                    text={formatMessage({ id: 'common.form.cancel' })}
                  />
                  <Button
                    data-testid="footer-continue"
                    type="submit"
                    text={formatMessage({ id: 'common.form.continue' })}
                  />
                </Stack>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
