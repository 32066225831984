// React
import { useRef } from 'react';
import PropTypes from 'prop-types';

// Intl
import { useIntl } from 'react-intl';

import { Dialog } from '@athonet/ui/components/Overlay/Dialog';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { useDispatch } from 'react-redux';
import { showInfoToast } from 'store/actions/toast';
import { Button } from '@athonet/ui/components/Input/Button';

const JsonViewer = ({ title, data, isOpen, onClose }) => {
  const { formatMessage } = useIntl();
  const elTextarea = useRef();
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const handleCopy = () => {
    elTextarea.current.select();
    document.execCommand('copy');
    dispatch(
      showInfoToast({
        message: 'jsonViewer.copied',
        intlMessage: true,
      })
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} title={title}>
      <DialogContent>
        <pre>{JSON.stringify(data, undefined, 2)}</pre>
      </DialogContent>

      <DialogActions>
        <textarea
          className="clipboard"
          style={{ position: 'absolute', left: '-9999px' }}
          ref={elTextarea}
          value={JSON.stringify(data, undefined, 2)}
        />
        <Button
          onClick={handleClose}
          variant="outlined"
          data-testid="footer-cancel"
          text={formatMessage({ id: 'common.form.cancel' })}
        />

        <Button onClick={handleCopy} data-testid="footer-copy" text={formatMessage({ id: 'jsonViewer.copy' })} />
      </DialogActions>
    </Dialog>
  );
};

JsonViewer.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default JsonViewer;
