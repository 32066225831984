import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { object, string, number } from 'yup';
import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';
import { FormStyled } from '../styled';
import { useDispatch } from 'react-redux';
import { editHss } from 'store/actions/nodes';
import { parseError } from 'utils/errorString';
import { sentryLogError } from 'sentry';

export default function Edit({ data, isOpen, onClose }) {
  const dispatch = useDispatch();

  const defaultOPActions = useMemo(() => {
    return {
      update: 0,
      remove: 1,
      donotchange: 2,
    };
  }, []);
  const { formatMessage } = useIntl();
  const [defaultOPDisabled, setDefaultOPDisabled] = useState(true);

  const toggleDefaultOPAction = useCallback(
    (setFieldValue, val) => {
      setDefaultOPDisabled(val.target.value !== defaultOPActions.update);
    },
    [defaultOPActions.update]
  );

  const schema = object().shape({
    name: string().trim().required(),
    agent_id: string().trim().required(),
    default_tk: string()
      .trim()
      .matches(/^[a-zA-Z0-9]+$/),
    default_op_action: number().required(),
    default_op: string().when('default_op_action', {
      is: defaultOPActions.update,
      then: (fieldSchema) =>
        fieldSchema
          .trim()
          .matches(/^[0-9a-fA-F]{32}?$/)
          .required(),
    }),
  });

  const initials = {
    name: data ? data.name : '',
    agent_id: data ? data.payload.agent_id : '',
    default_tk: data && data.payload.default_tk ? data.payload.default_tk : '',
    default_op_action: defaultOPActions.donotchange,
    default_op: '',
  };

  const default_op_status =
    ' (' +
    (data && data.payload.default_op
      ? formatMessage({ id: 'hss.form.defaultOP.placeholderAlreadySet' })
      : formatMessage({ id: 'hss.form.defaultOP.placeholderNotSet' })) +
    ')';
  const donotchangeLabel = formatMessage({ id: 'hss.form.defaultOPAction.doNotChange' }) + default_op_status;
  const updateLabel = formatMessage({ id: 'hss.form.defaultOPAction.update' }) + default_op_status;

  const defaultOPActionOptions = useMemo(() => {
    const OPActionOptions = [
      { value: defaultOPActions.donotchange, label: donotchangeLabel },
      { value: defaultOPActions.update, label: updateLabel },
    ];
    if (data && data.payload.default_op) {
      return OPActionOptions.concat([
        { value: defaultOPActions.remove, label: formatMessage({ id: 'hss.form.defaultOPAction.remove' }) },
      ]);
    }
    return OPActionOptions;
  }, [data, defaultOPActions, donotchangeLabel, updateLabel, formatMessage]);

  const fieldset = useMemo(
    () => [
      {
        title: formatMessage({ id: 'hss.form.data' }),
        data: [
          {
            name: 'name',
            label: formatMessage({ id: 'hss.form.name' }),
            placeholder: formatMessage({ id: 'hss.form.name.placeholder' }),
            error: formatMessage({ id: 'hss.form.name.error' }),
          },
          {
            name: 'agent_id',
            label: formatMessage({ id: 'hss.form.agentID' }),
            placeholder: formatMessage({ id: 'hss.form.agentID.placeholder' }),
            error: formatMessage({ id: 'hss.form.agentID.error' }),
          },
          {
            name: 'default_tk',
            label: formatMessage({ id: 'hss.form.defaultTK' }),
            placeholder: formatMessage({ id: 'hss.form.defaultTK.placeholder' }),
            error: formatMessage({ id: 'hss.form.defaultTK.error' }),
            gridClassName: 'fullwidth',
          },
          {
            name: 'default_op_action',
            label: formatMessage({ id: 'hss.form.defaultOPAction.label' }),
            options: defaultOPActionOptions,
            gridClassName: 'fullwidth',
            onChange: toggleDefaultOPAction,
          },
          {
            name: 'default_op',
            label: formatMessage({ id: 'hss.form.defaultOP' }),
            placeholder: formatMessage({ id: 'hss.form.defaultOP.placeholder' }),
            error: formatMessage({ id: 'hss.form.defaultOP.error' }),
            gridClassName: 'fullwidth',
            disabled: defaultOPDisabled,
          },
        ],
      },
    ],
    [formatMessage, defaultOPActionOptions, toggleDefaultOPAction, defaultOPDisabled]
  );

  const getDefaultOPValue = useCallback((dataValues) => {
    switch (dataValues.default_op_action) {
      case 0:
        //update
        return dataValues.default_op;
      case 1:
        //remove
        return null;
      default:
        return;
    }
  }, []);

  const handleSubmit = useCallback(
    async (values, callback) => {
      const editData = {
        name: values.name,
        payload: {
          agent_id: values.agent_id,
          default_tk: values.default_tk !== '' ? values.default_tk : null,
          default_op: getDefaultOPValue(values),
        },
      };
      try {
        await dispatch(editHss(data.id, editData));
        callback(true);
        onClose();
      } catch (e) {
        sentryLogError(e);
        callback(false, parseError(e));
      }
    },
    [data.id, dispatch, getDefaultOPValue, onClose]
  );

  return (
    <FormStyled>
      <Base
        schema={schema}
        isOpen={isOpen}
        onClose={onClose}
        initials={initials}
        fieldset={fieldset}
        onSubmit={handleSubmit}
        modalTitle={formatMessage({ id: 'hss.editHss' })}
      />
    </FormStyled>
  );
}

Edit.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func,
};
