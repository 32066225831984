// React
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// External Components
import { object, string, number, boolean } from 'yup';

import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';
import { FormStyled } from '../styled';
import { imsiExactRegExp, tkRegExp, keysRegExp } from 'store/models/usim';
import { useBootstrapSelector } from 'store/selectors/bootstrap';

const Create = ({ data, onSubmit, checkPermissions }) => {
  const bootstrap = useBootstrapSelector();
  const { formatMessage } = useIntl();
  const [profileSettingsDisabled, setProfileSettingsDisabled] = useState(false);
  const [dualImsiPoolDisabled, setDualImsiPoolDisabled] = useState(true);
  const [fieldlist, setFieldlist] = useState();
  const [encrypt, setEncrypt] = useState(1);
  const [keyType, setKeyType] = useState(1);
  const [useDefaultTK, setUseDefaultTK] = useState(false);

  const toggleProfileSettings = (setFieldValue, val) => {
    setProfileSettingsDisabled(val.target.value !== '');
  };

  const toggleDualImsiPool = (setFieldValue, val) => {
    setDualImsiPoolDisabled(val.target.value === false);
  };

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset()));
  }, [data, profileSettingsDisabled, dualImsiPoolDisabled, encrypt, keyType, useDefaultTK]);

  const schema = object().shape({
    msisdn_pool_id: string().required(),
    msisdn_type: string().required(),
    description: string().required(),
    first_imsi: string().matches(imsiExactRegExp),
    quantity: number().required().min(1).max(100000),
    customer: string().required(),
    profile: string().required(),
    is_dual_imsi: boolean().required(),
    imsi_pool_id: string().required(),
    imsi2_pool_id: string().when('is_dual_imsi', {
      is: true,
      then: (fieldSchema) => fieldSchema.required(),
    }),
    type: string().required(),
    graph_ref: string().required(),
    transport_key: string().required(),
    sim_id_1: string(),
    sim_id_2: string(),
    sim_id_3: string(),
    sim_id_4: string(),
    address_1: string(),
    address_2: string(),
    address_3: string(),
    address_4: string(),
    address_5: string(),
    key_type: number().required().min(0).max(1),
    op: string().when(['key_type'], {
      is: (key_type) => {
        return key_type === 1;
      },
      then: string().required().matches(keysRegExp),
      otherwise: string(),
    }),
    encrypt: number().required().min(0).max(1),
    use_key: string().when(['encrypt', 'use_default_tk'], {
      is: (encryptValue, use_default_tk) => encryptValue === 1 && !use_default_tk,
      then: string().matches(tkRegExp).required(),
      otherwise: string(),
    }),
  });

  const initials = {
    msisdn_pool_id: '',
    msisdn_type: 'new',
    description: 'New USIM order',
    first_imsi: '',
    quantity: 1,
    customer: '',
    type: '',
    preset_profile: 0,
    profile: '',
    is_dual_imsi: false,
    imsi_pool_id: '',
    imsi2_pool_id: '',
    graph_ref: '',
    transport_key: '',
    sim_id_1: '01',
    sim_id_2: '01',
    sim_id_3: '',
    sim_id_4: '',
    address_1: '',
    address_2: '',
    address_3: '',
    address_4: '',
    address_5: '',
    encrypt: 1,
    use_default_tk: false,
    use_key: '',
    key_type: 1,
    op: '',
  };
  const msisdnTypes = [
    { value: 'new', label: formatMessage({ id: 'usims.orders.form.order.msisdn.type.new' }) },
    { value: 'used', label: formatMessage({ id: 'usims.orders.form.order.msisdn.type.used' }) },
    { value: 'both', label: formatMessage({ id: 'usims.orders.form.order.msisdn.type.both' }) },
  ];
  const profiles = [{ value: 0, label: formatMessage({ id: 'usims.orders.form.order.profile.custom' }) }].concat(
    data.order_profiles
  );

  const fieldset = () => [
    {
      title: '',
      data: [
        {
          name: 'description',
          label: formatMessage({ id: 'usims.orders.form.order.description' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.description.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.description.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'customer',
          label: formatMessage({ id: 'usims.orders.form.order.customer' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.customer.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.customer.error' }),
          gridClassName: 'fullwidth',
        },
      ],
    },
    {
      title: formatMessage({ id: 'usims.orders.form.order.data' }),
      data: [
        {
          name: 'imsi_pool_id',
          label: formatMessage({ id: 'usims.orders.form.order.imsi' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.imsi.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.imsi.error' }),
          options: data.imsi_pools,
          gridClassName: 'fullwidth',
        },
        {
          name: 'first_imsi',
          label: formatMessage({ id: 'usims.orders.form.order.firstImsi' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.firstImsi.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.firstImsi.error' }),
        },
        {
          name: 'quantity',
          type: 'number',
          label: formatMessage({ id: 'usims.orders.form.order.quantity' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.quantity.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.quantity.error' }),
        },
        {
          name: 'msisdn_pool_id',
          label: formatMessage({ id: 'usims.orders.form.order.msisdn' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.msisdn.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.msisdn.error' }),
          options: data.msisdn_pools,
        },
        {
          name: 'msisdn_type',
          label: formatMessage({ id: 'usims.orders.form.order.msisdn.type.label' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.msisdn.type.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.msisdn.type.error' }),
          options: msisdnTypes,
        },
      ],
    },
    {
      title: formatMessage({ id: 'usims.orders.form.order.settings' }),
      data: [
        {
          name: 'preset_profile',
          label: formatMessage({ id: 'usims.orders.form.order.presetProfile' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.presetProfile.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.presetProfile.error' }),
          options: profiles,
          onChange: toggleProfileSettings,
          gridClassName: 'fullwidth',
        },
        {
          name: 'profile',
          label: formatMessage({ id: 'usims.orders.form.order.profile' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.profile.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.profile.error' }),
          disabled: profileSettingsDisabled,
        },
        {
          name: 'type',
          label: formatMessage({ id: 'usims.orders.form.order.type' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.type.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.type.error' }),
          disabled: profileSettingsDisabled,
        },
        {
          name: 'graph_ref',
          label: formatMessage({ id: 'usims.orders.form.order.graphReference' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.graphReference.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.graphReference.error' }),
          disabled: profileSettingsDisabled,
        },
        {
          name: 'transport_key',
          label: formatMessage({ id: 'usims.orders.form.order.transport' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.transport.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.transport.error' }),
          disabled: profileSettingsDisabled,
        },
        ...(bootstrap.dual_sim_service
          ? [
              {
                name: 'is_dual_imsi',
                label: formatMessage({ id: 'usims.orders.form.order.isDualImsi' }),
                placeholder: formatMessage({ id: 'usims.orders.form.order.isDualImsi.placeholder' }),
                error: formatMessage({ id: 'usims.orders.form.order.isDualImsi.error' }),
                options: [
                  { value: true, label: formatMessage({ id: 'usims.orders.form.order.isDualImsi.yes' }) },
                  { value: false, label: formatMessage({ id: 'usims.orders.form.order.isDualImsi.no' }) },
                ],
                onChange: toggleDualImsiPool,
              },
              {
                name: 'imsi2_pool_id',
                label: formatMessage({ id: 'usims.orders.form.order.dualImsiPool' }),
                placeholder: formatMessage({ id: 'usims.orders.form.order.dualImsiPool.placeholder' }),
                error: formatMessage({ id: 'usims.orders.form.order.dualImsiPool.error' }),
                options: data.imsi2_pools,
                disabled: dualImsiPoolDisabled,
              },
            ]
          : []),
      ],
    },
    {
      title: formatMessage({ id: 'usims.orders.form.order.simSettings' }),
      data: [
        {
          name: 'sim_id_1',
          label: formatMessage({ id: 'usims.orders.form.order.sim1' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.sim1.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.sim1.error' }),
          options: data.sim1,
          disabled: true,
        },
        {
          name: 'sim_id_2',
          label: formatMessage({ id: 'usims.orders.form.order.sim2' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.sim2.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.sim2.error' }),
          options: data.sim2,
          disabled: true,
        },
        {
          name: 'sim_id_3',
          label: formatMessage({ id: 'usims.orders.form.order.sim3' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.sim3.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.sim3.error' }),
          options: data.sim3,
        },
        {
          name: 'sim_id_4',
          label: formatMessage({ id: 'usims.orders.form.order.sim4' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.sim4.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.sim4.error' }),
          options: data.sim4,
        },
      ],
    },
    {
      title: formatMessage({ id: 'usims.orders.form.order.addressData' }),
      data: [
        {
          name: 'address_1',
          label: formatMessage({ id: 'usims.orders.form.order.address1' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.address1.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.address1.error' }),
        },
        {
          name: 'address_2',
          label: formatMessage({ id: 'usims.orders.form.order.address2' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.address2.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.address2.error' }),
        },
        {
          name: 'address_3',
          label: formatMessage({ id: 'usims.orders.form.order.address3' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.address3.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.address3.error' }),
        },
        {
          name: 'address_4',
          label: formatMessage({ id: 'usims.orders.form.order.address4' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.address4.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.address4.error' }),
        },
        {
          name: 'address_5',
          label: formatMessage({ id: 'usims.orders.form.order.address5' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.address5.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.address5.error' }),
        },
      ],
    },
    {
      title: 'Secret K for AKA authentication',
      data: [
        {
          name: 'encrypt',
          label: formatMessage({ id: 'usims.form.usim.encrypt' }),
          placeholder: formatMessage({ id: 'usims.form.usim.encrypt.placeholder' }),
          error: formatMessage({ id: 'usims.form.usim.encrypt.error' }),
          options: [
            { label: formatMessage({ id: 'usims.form.usim.encrypt.1' }), value: 1 },
            { label: formatMessage({ id: 'usims.form.usim.encrypt.0' }), value: 0 },
          ],
          gridClassName: 'fullwidth',
          onChange: (setFieldValue, val) => {
            setEncrypt(val.target.value);
          },
        },
        {
          name: 'use_default_tk',
          label: formatMessage({ id: 'usims.form.usim.use_default_tk' }),
          placeholder: formatMessage({ id: 'usims.form.usim.use_default_tk.placeholder' }),
          error: formatMessage({ id: 'usims.form.usim.use_default_tk.error' }),
          options: [
            { label: formatMessage({ id: 'usims.form.usim.use_default_tk.true' }), value: true },
            { label: formatMessage({ id: 'usims.form.usim.use_default_tk.false' }), value: false },
          ],
          gridClassName: useDefaultTK ? 'fullwidth' : undefined,
          onChange: (setFieldValue, val) => {
            setUseDefaultTK(val.target.value);
          },
          hidden: encrypt === 0,
        },
        {
          name: 'use_key',
          label: formatMessage({ id: 'usims.form.usim.use_key' }),
          placeholder: formatMessage({ id: 'usims.form.usim.use_key.placeholder' }),
          error: formatMessage({ id: 'usims.form.usim.use_key.error' }),
          hidden: encrypt === 0 || useDefaultTK,
        },
      ],
    },
    {
      title: 'Secret OP for AKA authentication',
      data: [
        {
          name: 'key_type',
          label: formatMessage({ id: 'usims.form.usim.key_type' }),
          placeholder: formatMessage({ id: 'usims.form.usim.key_type.placeholder' }),
          error: formatMessage({ id: 'usims.form.usim.key_type.error' }),
          options: [
            { label: formatMessage({ id: 'usims.form.usim.key_type.defaultHSSOP' }), value: 0 },
            { label: formatMessage({ id: 'usims.form.usim.key_type.customOP' }), value: 1 },
          ],
          gridClassName: 'fullwidth',
          onChange: (setFieldValue, val) => {
            setKeyType(val.target.value);
          },
        },
        {
          name: 'op',
          label: formatMessage({ id: 'usims.form.usim.op' }),
          placeholder: formatMessage({ id: 'usims.form.usim.op.placeholder' }),
          error: formatMessage({ id: 'usims.form.usim.op.error' }),
          gridClassName: 'fullwidth',
          hidden: keyType !== 1,
        },
      ],
    },
  ];

  return (
    <FormStyled>
      <Base
        schema={schema}
        elType="button"
        elColor="tool"
        elSize="small"
        initials={initials}
        fieldset={fieldlist}
        onSubmit={onSubmit}
        onClose={() => {
          //fastfix to reset the state, waiting for BASE refactor
          setEncrypt(1);
          setKeyType(1);
          setUseDefaultTK(false);
        }}
        modalTitle={formatMessage({ id: 'usims.orders.newOrder' })}
        buttonLabel={formatMessage({ id: 'usims.orders.newOrder' })}
        confirmation={true}
      />
    </FormStyled>
  );
};

Create.propTypes = {
  data: PropTypes.any,
};

export default Create;
