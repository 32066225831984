import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  filtersLoading,
  filtersFailure,
  filtersInject,
  sortSet,
  listLoading,
  listSuccess,
  listFailure,
  listClear,
} from 'store/actions/usimOrders';
import moment from 'moment';
import async from 'async';
import FileSaver from 'file-saver';
import { useIntl } from 'react-intl';
import axios from 'axios';
import Filters from 'components/Filters/UsimOrders';
import List from 'components/List';
import Create from 'components/Edit/UsimOrders/Create';
import Download from 'components/Edit/UsimOrders/Download';
import Spinner from 'components/Spinner';
import JsonViewer from 'components/JsonViewer';
import Upload from 'components/Edit/UsimOrders/Upload';
import DropdownTools from 'components/DropdownTools';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { ic_delete as ico_delete } from 'react-icons-kit/md/ic_delete';
import { code } from 'react-icons-kit/fa/code';
import { ic_cloud_download as ico_download } from 'react-icons-kit/md/ic_cloud_download';
import getFetchHeaders from 'utils/fetchHeaders';
import { U_PERMISSIONS, T_PERMISSIONS, checkPermissionsList, checkPermissionToUse } from 'utils/permissionCodes';
import { getFiltersByQueryString } from 'store/models/filters';
import { generateFiltersQuery } from 'store/models/filters';
import config from 'config';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { deleteScheduledOperation, setScheduledOperation } from 'store/actions/bulkOperations';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { Status, STATUS } from '@athonet/ui/components/Feedback/Status';
import { humanizeSnakeCase } from 'utils/string';
import { useFetchData } from 'hooks/useFetchData';
import { sentryLogError } from 'sentry';

const ORDER_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  READY: 'ready',
  UPDATING: 'updating',
  COMPLETED: 'completed',
};

const Orders = () => {
  const fetchData = useFetchData();
  const bootstrap = useBootstrapSelector();
  const [isExtraOpen, setIsExtraOpen] = useState(false);
  const [extraData, setExtraData] = useState();
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [idDownload, setIdDownload] = useState();
  const userdata = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const datalist = useSelector((state) => state.usimOrders.list.data);
  const loadingState = useSelector((state) => state.usimOrders.list.state);
  const filters = useSelector((state) => state.usimOrders.filters.values) || {};
  const filtersState = useSelector((state) => state.usimOrders.filters.state);
  const filtersData = useSelector((state) => state.usimOrders.filters.data) || [];
  const sort = useSelector((state) => state.usimOrders.sort);
  const { confirmationDialogOpen } = useOverlay();

  const [configLocale] = locale.split('-');
  const localizedConfig = config[configLocale] || config.en;

  useEffect(() => {
    loadFilters();

    return () => {
      dispatch(listClear());
    };
  }, []);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      loadData();
    }
  }, [filters]);

  useEffect(() => {
    if (sort !== '' && (filtersState === 1 || filtersState === 2)) {
      loadData();
    }
  }, [sort, filtersState]);

  const getIMSIPoolName = (values) => {
    return values.name + ' [' + values.first_imsi + '-' + values.last_imsi + ']';
  };

  const getMSISDNPoolName = (values) => {
    return values.name + ' [' + values.first_msisdn + '-' + values.last_msisdn + ']';
  };

  const getFiltersData = () => {
    const options = {
      tenants: [],
      keys: [],
      private_keys: [],
      msisdn_pools: [],
      imsi_pools: [],
      ...(bootstrap?.dual_sim_service && { imsi2_pools: [] }),
      order_profiles: [],
      providers: [],
    };

    for (let i in filtersData.tenants) {
      options.tenants.push({ label: filtersData.tenants[i].name, value: filtersData.tenants[i].id });
    }

    for (let i in filtersData.msisdn_pools) {
      options.msisdn_pools.push({
        label: getMSISDNPoolName(filtersData.msisdn_pools[i]),
        value: filtersData.msisdn_pools[i].id,
      });
    }

    for (let i in filtersData.imsi_pools) {
      options.imsi_pools.push({
        label: getIMSIPoolName(filtersData.imsi_pools[i]),
        value: filtersData.imsi_pools[i].id,
      });
    }

    if (bootstrap?.dual_sim_service) {
      for (let i in filtersData.imsi2_pools) {
        options.imsi2_pools.push({
          label: getIMSIPoolName(filtersData.imsi2_pools[i]),
          value: filtersData.imsi2_pools[i].id,
        });
      }
    }

    for (let i in filtersData.order_profiles) {
      options.order_profiles.push({
        label: filtersData.order_profiles[i].name,
        value: filtersData.order_profiles[i].id,
      });
    }

    for (let i in filtersData.providers) {
      options.providers.push({ label: filtersData.providers[i].name, value: filtersData.providers[i].id });
    }

    for (let i in filtersData.keys) {
      options.keys.push({ label: filtersData.keys[i].name, value: filtersData.keys[i].id });
    }

    for (let i in filtersData.keys) {
      if (filtersData.keys[i].private)
        options.private_keys.push({ label: filtersData.keys[i].name, value: filtersData.keys[i].id });
    }
    return options;
  };

  const loadFilters = () => {
    dispatch(filtersLoading());

    const calls = {};

    calls.tenants = (cb) => {
      const options = {
        url: `${config.apis.getTenantWithSubtenants
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((response) => {
          cb(null, response.tenants);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    calls.keys = (cb) => {
      const options = {
        url: `${config.apis.getGpgKeys
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          cb(null, result.items);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    calls.msisdn_pools = (cb) => {
      const options = {
        url: `${config.apis.getPools
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '&type=match{{msisdn}}')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          cb(null, result.items);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    calls.imsi_pools = (cb) => {
      const options = {
        url: `${config.apis.getPools
          .replace('{sort}', 'name')
          .replace('{limit}', 1000)
          .replace('{page}', 0)
          .replace('{filters}', '&type=match{{imsi}}')}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          cb(null, result.items);
        })
        .catch((e) => {
          sentryLogError(e);
          cb(null, []);
        });
    };

    if (bootstrap?.dual_sim_service) {
      calls.imsi2_pools = (cb) => {
        const options = {
          url: `${config.apis.getPools
            .replace('{sort}', 'name')
            .replace('{limit}', 1000)
            .replace('{page}', 0)
            .replace('{filters}', '&type=match{{dualimsi}}')}`,
          method: 'GET',
          headers: getFetchHeaders(userdata),
        };

        fetchData(options)
          .then((result) => {
            cb(null, result.items);
          })
          .catch((e) => {
            sentryLogError(e);
            cb(null, []);
          });
      };
    }

    calls.order_profiles = (cb) => {
      //cb(null, [{id: "3", name: "N76Fx"}])
      cb(null, []);
    };

    calls.providers = (cb) => {
      cb(null, [{ id: 'st_microelectronics', name: 'ST Microelectronics' }]);
    };

    async.parallel(calls, (err, results) => {
      if (err) {
        sentryLogError(err);
        dispatch(filtersFailure());
      } else {
        dispatch(
          filtersInject({ state: 1, data: Object.assign(filtersData, results), values: getFiltersByQueryString() })
        );
      }
    });
  };

  const loadData = (page = 0) => {
    if (
      // load if..
      loadingState !== 0 &&
      sort !== '' // .. sort is set
    ) {
      dispatch(listLoading());

      const query = generateFiltersQuery(filters, '&');

      const options = {
        url: `${config.apis.getUsimOrders
          .replace('{sort}', sort)
          .replace('{limit}', bootstrap.pageLimit)
          .replace('{page}', page)
          .replace('{filters}', query)}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          dispatch(
            listSuccess({
              data: result.items,
              total: result.total_items,
              page,
            })
          );
        })
        .catch(() => {
          dispatch(listFailure());
        });
    }
  };

  const handlePageChange = (page) => {
    loadData(page);
  };

  useEffect(() => {
    loadData();
  }, [bootstrap.pageLimit]);

  const onOrderChange = (orderQuery) => {
    dispatch(sortSet(orderQuery));
  };

  const newOrder = (values, callback) => {
    let orderData = {
      address_1: values.address_1,
      address_2: values.address_2,
      address_3: values.address_3,
      address_4: values.address_4,
      address_5: values.address_5,
      customer: values.customer,
      description: values.description,
      first_imsi: values.first_imsi !== '' ? values.first_imsi : undefined,
      graph_ref: values.graph_ref,
      imsi2_pool_id: bootstrap.dual_sim_service && values.is_dual_imsi ? values.imsi2_pool_id : undefined,
      imsi_pool_id: values.imsi_pool_id,
      msisdn_pool_id: values.msisdn_pool_id,
      msisdn_type: values.msisdn_type,
      profile: values.profile,
      quantity: values.quantity,
      sim_id_1: values.sim_id_1,
      sim_id_2: values.sim_id_2,
      sim_id_3: values.sim_id_3,
      sim_id_4: values.sim_id_4,
      transport_key: values.transport_key,
      type: values.type,
      encrypt: values.encrypt,
      use_key: values.encrypt === 1 && !values.use_default_tk ? values.use_key : null,
      op: values.key_type === 1 ? values.op : null,
    };

    const options = {
      url: `${config.apis.createUsimOrder}`,
      method: 'POST',
      headers: getFetchHeaders(userdata),
      data: orderData,
    };

    fetchData(options)
      .then(() => {
        loadData();
        callback(true, formatMessage({ id: 'usims.orders.actions.inProgress' }));
      })
      .catch((e) => {
        let errorText = e.response ? (e.response.data.error ? e.response.data.error : undefined) : undefined;
        callback(false, errorText);
      });
  };

  const onDelete = (id) => {
    const options = {
      url: `${config.apis.deleteUsimOrder.replace('{id}', id)}`,
      method: 'DELETE',
      headers: getFetchHeaders(userdata),
    };

    fetchData(options)
      .then(() => {
        loadData();
        dispatch(showSuccessToast());
      })
      .catch(() => {
        dispatch(showErrorToast());
      });
  };

  const openDelete = ({ id, description }) => {
    confirmationDialogOpen({
      title: formatMessage({ id: 'usims.orders.deleteOrder.confirm.title' }),
      description: formatMessage({ id: 'usims.orders.deleteOrder.confirm' }),
      alertMessage: formatMessage({ id: 'usims.orders.itemsAffected' }, { element: description }),
      continueButtonText: formatMessage({ id: 'usims.orders.deleteOrder.confirm.continueButton' }, { elements: 1 }),
      onConfirm: () => onDelete(id),
      severity: 'danger',
      dataTestid: 'confirm-delete-usimOrder',
    });
  };

  const openExtra = (exData) => {
    setIsExtraOpen(true);
    setExtraData(exData);
  };

  const closeExtra = () => {
    setIsExtraOpen(false);
    setExtraData(undefined);
  };

  const openDownload = (id) => {
    setIsDownloadOpen(true);
    setIdDownload(id);
  };

  const closeDownload = () => {
    setIsDownloadOpen(false);
    setIdDownload(undefined);
  };

  const onDownload = (values, callback) => {
    if (idDownload) {
      let url = `${config.apis.downloadPlainUsimOrder.replace('{id}', idDownload).replace('{type}', values.file_type)}`;
      if (values.encrypted) {
        url = `${config.apis.downloadEncryptedUsimOrder
          .replace('{id}', idDownload)
          .replace('{type}', values.file_type)
          .replace('{key_id}', values.key_id)}`;
      }

      let filename;
      const options = {
        headers: getFetchHeaders(userdata),
        responseType: 'blob',
      };

      axios
        .get(url, options)
        .then((response) => {
          if (response.status !== 200) {
            return callback(false);
          }
          const contentDisposition = response.headers['content-disposition'];
          const startIndex = contentDisposition.indexOf('filename=') + 10;
          const endIndex = contentDisposition.length - 1;
          filename = contentDisposition.substring(startIndex, endIndex);
          callback(true);
          FileSaver.saveAs(response.data, filename);
        })
        .catch((e) => {
          let errorText = e.response ? (e.response.data.error ? e.response.data.error : undefined) : undefined;
          callback(false, errorText);
        });
    }
  };

  const onUpload = async (values, callback) => {
    const uploadData = new FormData();
    const uuid = await dispatch(setScheduledOperation());
    uploadData.append('uploadfile', values.upload[0]);
    uploadData.append('operation_id', uuid);
    if (values.encrypted) uploadData.append('key_id', values.key_id);

    const options = {
      url: `${config.apis.uploadUsimOrder}`,
      method: 'POST',
      headers: getFetchHeaders(userdata),
      data: uploadData,
    };

    fetchData(options)
      .then(() => {
        loadData();
        callback(null);
      })
      .catch((e) => {
        let errorText = e.response ? (e.response.data.error ? e.response.data.error : undefined) : undefined;
        dispatch(deleteScheduledOperation(uuid));
        callback(false, errorText);
      });
  };

  const getTools = (rowData) => {
    const options = [
      {
        icon: ico_download,
        label: formatMessage({ id: 'usims.orders.downloadOrder' }),
        action: () => openDownload(rowData.id),
        disabled: rowData.status !== ORDER_STATUS.READY && rowData.status !== ORDER_STATUS.COMPLETED,
      },
      {
        icon: code,
        label: formatMessage({ id: 'usims.orders.extraData' }),
        action: () => openExtra(rowData.vendor_info),
        disabled: !rowData.vendor_info,
      },
      {
        icon: ico_delete,
        label: formatMessage({ id: 'usims.orders.deleteOrder' }),
        action: () => openDelete(rowData),
        disabled: true,
        permissions: [U_PERMISSIONS.DELETE_USIM_ORDER],
      },
    ];

    return <DropdownTools options={checkPermissionsList(userdata.permissions, options, false)} />;
  };

  const columns = [
    {
      key: 'id',
      title: formatMessage({ id: 'usims.orders.table.id' }),
      dataKey: 'id',
      width: 100,
      maxWidth: 100,
      minWidth: 100,
      secret: true,
      hidden: true,
    },
    {
      key: 'order_id',
      title: formatMessage({ id: 'usims.orders.table.id' }),
      dataKey: 'order_id',
      width: 100,
      maxWidth: 100,
      minWidth: 100,
    },
    {
      key: 'description',
      title: formatMessage({ id: 'usims.orders.table.description' }),
      dataKey: 'description',
      width: 150,
      maxWidth: 200,
      minWidth: 100,
    },
    {
      key: 'status',
      title: formatMessage({ id: 'usims.orders.table.status' }),
      dataKey: 'status',
      sortable: true,
      width: 100,
      maxWidth: 100,
      minWidth: 100,
      cellRenderer: ({ cellData: status }) => {
        let statusType;
        switch (status) {
          case 'ready':
          case 'completed':
            statusType = STATUS.SUCCESS;
            break;
          case 'updating':
          case 'processing':
            statusType = STATUS.INFO;
            break;
          case 'pending':
            statusType = STATUS.WARNING;
            break;
          case 'error':
            statusType = STATUS.ERROR;
            break;
          default:
            statusType = STATUS.DEFAULT;
        }
        return <Status status={statusType} label={humanizeSnakeCase(status)} asChip />;
      },
    },
    {
      key: 'progress',
      title: formatMessage({ id: 'usims.orders.table.progress' }),
      dataKey: 'progress',
      sortable: true,
      width: 90,
      maxWidth: 100,
      minWidth: 60,
      cellRenderer: ({ cellData }) => cellData + '%',
    },
    {
      key: 'first_available_imsi',
      title: formatMessage({ id: 'usims.orders.table.imsi' }),
      dataKey: 'first_available_imsi',
      sortable: true,
      width: 150,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      minWidth: 100,
    },
    {
      key: 'quantity',
      title: formatMessage({ id: 'usims.orders.table.quantity' }),
      dataKey: 'quantity',
      sortable: true,
      width: 100,
      maxWidth: 100,
      minWidth: 50,
    },
    {
      key: 'batch_size',
      title: formatMessage({ id: 'usims.orders.table.batch_size' }),
      dataKey: 'batch_size',
      sortable: true,
      width: 90,
      maxWidth: 100,
      minWidth: 60,
    },
    {
      key: 'batches',
      title: formatMessage({ id: 'usims.orders.table.batches' }),
      dataKey: 'batches',
      sortable: true,
      width: 90,
      maxWidth: 100,
      minWidth: 60,
      visible: true,
      cellRenderer: ({ cellData }) => cellData.length,
    },
    {
      key: 'msisdn_pool.name',
      title: formatMessage({ id: 'usims.orders.table.msisdnPoolName' }),
      dataKey: 'msisdn_pool.name',
      sortable: false,
      width: 150,
      maxWidth: 200,
      minWidth: 90,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      hidden: true,
      secret: true,
    },
    {
      key: 'imsi_pool.name',
      title: formatMessage({ id: 'usims.orders.table.imsiPoolName' }),
      dataKey: 'imsi_pool.name',
      sortable: false,
      width: 150,
      maxWidth: 200,
      minWidth: 90,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      hidden: true,
      secret: true,
    },
    {
      key: 'tenant.name',
      title: formatMessage({ id: 'usims.orders.table.tenantName' }),
      dataKey: 'tenant.name',
      sortable: false,
      width: 150,
      maxWidth: 200,
      minWidth: 90,
      headerClassName: 'table-cell-resizable', // for columns auto-resizable
      className: 'table-cell-resizable', // for columns auto-resizable
      permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
      visible: false,
    },
    {
      key: 'created_at',
      title: formatMessage({ id: 'usims.orders.table.created' }),
      dataKey: 'created_at',
      sortable: true,
      width: 140,
      maxWidth: 140,
      minWidth: 50,
      visible: false,
      defaultSort: 'desc', // set the columns sorted as default
      cellRenderer: ({ cellData }) => {
        return moment(cellData).format(localizedConfig.fullDateFormat);
      },
    },
    {
      key: 'updated_at',
      title: formatMessage({ id: 'usims.orders.table.updated' }),
      dataKey: 'updated_at',
      sortable: true,
      width: 140,
      maxWidth: 140,
      minWidth: 50,
      visible: false,
      cellRenderer: ({ rowData }) => {
        return rowData.created_at !== rowData.updated_at
          ? moment(rowData.updated_at).format(localizedConfig.fullDateFormat)
          : '-';
      },
    },
    {
      key: 'tools',
      title: '',
      dataKey: 'tools',
      width: 60,
      maxWidth: 60,
      minWidth: 60,
      secret: true, // secret used to hide from columns management panel
      cellRenderer: ({ rowData }) => {
        return getTools(rowData);
      },
    },
  ];

  const onCheckPermissionsList = (list) => {
    return checkPermissionsList([userdata.tenant_type], list);
  };

  const columnsPermitted = checkPermissionsList([userdata.tenant_type], columns);

  return columnsPermitted ? (
    <>
      <List
        key="orders-list"
        columns={columnsPermitted}
        totalRows={datalist.total}
        data={datalist.data}
        page={datalist.page}
        filters={filters}
        onOrderChange={onOrderChange}
        loadingState={loadingState}
        createComponent={
          checkPermissionToUse(userdata, U_PERMISSIONS.CREATE_TENANT) && (
            <Create data={getFiltersData()} onSubmit={newOrder} checkPermissions={onCheckPermissionsList} />
          )
        }
        filtersComponent={<Filters values={filters} />}
        onPageChange={handlePageChange}
        extraComponents={<Upload data={getFiltersData()} onUpload={onUpload} disabled={datalist.total === 0} />}
        toolbar={{ actions: false }}
        rowsPerPage={bootstrap.pageLimit}
        onRefresh={loadData}
      />

      {isExtraOpen && (
        <JsonViewer
          title={formatMessage({ id: 'usims.orders.extra.title' })}
          data={extraData}
          isOpen={isExtraOpen}
          onClose={closeExtra}
        />
      )}

      {isDownloadOpen && (
        <Download isOpen={isDownloadOpen} onClose={closeDownload} data={getFiltersData()} onDownload={onDownload} />
      )}
    </>
  ) : (
    <Spinner className="spinner" size={40} />
  );
};

export default Orders;
