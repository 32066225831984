import { Autocomplete, AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getTenantsAutocompleteOptions } from 'store/actions/tenants';
import { object, string } from 'yup';
import { NewNodeStepProps } from './CreateNodeContent';

export function Step1({ onCompleteStep, initialValues }: NewNodeStepProps) {
  const [options, setOptions] = useState<AutocompleteItemProps[]>([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const validationSchema = useMemo(
    () =>
      object().shape({
        tenant: object().shape({ label: string().required(), value: string().required() }).required(),
      }),
    []
  );

  const handleOptionsChange = useCallback(
    async (query: string) => {
      setOptionsLoading(true);
      setOptions([]);

      const searchResults = await dispatch(getTenantsAutocompleteOptions(query));

      setOptions(searchResults);
      setOptionsLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    void handleOptionsChange('');
  }, [handleOptionsChange]);

  const handleSubmit = useCallback(
    (values) => {
      onCompleteStep(0, values);
    },
    [onCompleteStep]
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ errors, setFieldValue }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
              <Field name="tenant" key="tenant">
                {({ field }: FieldProps<AutocompleteItemProps>) => (
                  <Autocomplete
                    required
                    size="small"
                    {...field}
                    options={options}
                    onChange={(_, v) => setFieldValue('tenant', v)}
                    loading={optionsLoading}
                    label={formatMessage({ id: 'nodes.form.node.tenant_id' })}
                    placeholder={formatMessage({ id: 'nodes.form.node.tenant_id.placeholder' })}
                    error={Boolean(errors['tenant'])}
                    {...(Boolean(errors['tenant']) && {
                      helperText: formatMessage({ id: 'nodes.form.node.tenant_id.error' }),
                    })}
                  />
                )}
              </Field>

              <Button
                variant="outlined"
                text={formatMessage({ id: 'common.form.next' })}
                type="submit"
                disabled={Boolean(errors['tenant'])}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
