import { FilterValue, LegacyFilterObjValue, LegacyFiltersObj, MultiSelectValue } from 'store/models/filters';

export enum DATA_LIFECYCLE {
  IDLE = -1,
  LOADING,
  SUCCESS,
  FAILURE,
}

export type Sort = string;

// TODO:
export type Filters<F extends LegacyFilterObjValue = FilterValue | MultiSelectValue[]> = {
  state: DATA_LIFECYCLE;
  data: Record<string, unknown>;
  values: LegacyFiltersObj<F>;
};

export type List<T = unknown> = {
  state: DATA_LIFECYCLE;
  data: {
    data: T[]; // TODO: domenico, this is horrible, name it "items" or something (<RankingList /> depends on it)
    total: number;
    page?: number;
    type?: string;
  };
};

export function listIdle<T>(): List<T> {
  return {
    data: {
      data: [],
      total: 0,
      page: 0,
    },
    state: DATA_LIFECYCLE.IDLE,
  };
}

export function listLoading<T>(data: List<T>['data']): List<T> {
  return {
    data: data || {
      data: [],
      total: 0,
      page: 0,
    },
    state: DATA_LIFECYCLE.LOADING,
  };
}

export function listSuccess<T>(data: List<T>['data']): List<T> {
  return {
    data,
    state: DATA_LIFECYCLE.SUCCESS,
  };
}

export function listFailure<T>(): List<T> {
  return {
    data: {
      data: [],
      total: 0,
      page: 0,
    },
    state: DATA_LIFECYCLE.FAILURE,
  };
}

export function isListLoading<T>(list: List<T>): boolean {
  return list.state === DATA_LIFECYCLE.LOADING || list.state === DATA_LIFECYCLE.IDLE;
}

export type Entity<T = unknown> = {
  state: DATA_LIFECYCLE;
  data: T | null;
};

export function entityIdle<T>(data?: T): Entity<T> {
  return {
    state: DATA_LIFECYCLE.IDLE,
    data: data || null,
  };
}

export function entityLoading<T>(): Entity<T> {
  return {
    data: null,
    state: DATA_LIFECYCLE.LOADING,
  };
}

export function entitySuccess<T>(data: T): Entity<T> {
  return {
    data,
    state: DATA_LIFECYCLE.SUCCESS,
  };
}

export function entityFailure<T>(data?: T): Entity<T> {
  return {
    data: data || null,
    state: DATA_LIFECYCLE.FAILURE,
  };
}

export function isEntityFailure<T>(entity: Entity<T>): boolean {
  return entity.state === DATA_LIFECYCLE.FAILURE;
}

export function isEntityLoading<T>(entity: Entity<T>): boolean {
  return entity.state === DATA_LIFECYCLE.LOADING || entity.state === DATA_LIFECYCLE.IDLE;
}
