// React
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useSelector } from 'react-redux';

// External Components
import { object, string, array } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';
import { Multiselect } from 'components/Form/Multiselect';

// Style
import { FormStyled } from '../styled';

const Edit = ({ data, getOptions, isOpen, onClose, onEdit, options, checkPermissions }) => {
  const { formatMessage } = useIntl();
  // TODO domenico: remove this state here, the value is never read
  const [, setDataOptions] = useState(options);
  const filtersData = useSelector((state) => state.users.filters.data) || [];
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));

    if (getOptions !== undefined) {
      setDataOptions(getOptions(false, filtersData));
    }
  }, [options]);

  const schema = object().shape({
    role: string().required(),
    permissions: array().required(),
  });

  const initials = {
    role: data?.name,
    permissions: data?.permissions.map(({ code }) => ({
      label: formatMessage({ id: `permissions.${code}` }),
      value: code,
    })),
  };

  const fieldset = [
    {
      title: formatMessage({ id: 'security.form.data' }),
      data: [
        {
          name: 'role',
          label: formatMessage({ id: 'security.form.role' }),
          placeholder: formatMessage({ id: 'security.form.role.placeholder' }),
          error: formatMessage({ id: 'security.form.role.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'permissions',
          fieldComponent: Multiselect,
          label: formatMessage({ id: 'security.form.permissions' }),
          placeholder: formatMessage({ id: 'security.form.permissions.placeholder' }),
          componentProps: {
            options: options,
            multiple: true,
            disableCloseOnSelect: true,
            disableClearable: false,
            limitTags: -1,
            selectAll: true,
          },
          gridClassName: 'fullwidth',
        },
      ],
    },
  ];

  return (
    <FormStyled>
      <Base
        initials={initials}
        schema={schema}
        isOpen={isOpen}
        onClose={onClose}
        fieldset={fieldlist}
        onSubmit={onEdit}
        modalTitle={formatMessage({ id: 'security.editRole' })}
      />
    </FormStyled>
  );
};

Edit.propTypes = {
  getOptions: PropTypes.func,
  getData: PropTypes.func,
  options: PropTypes.any,
};

export default Edit;
