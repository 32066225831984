import { EnvironmentConfiguration, PAGE_LIMIT, PAGE_LIMIT_OPTIONS } from 'store/models/environmentConfiguration';
import { BootstrapAction, BOOTSTRAP_ACTION_TYPE } from 'store/actions/bootstrap';

export type BootstrapState =
  | (EnvironmentConfiguration & {
      pageLimit: number;
    })
  | null;

const initialState: BootstrapState = null;

export default function bootstrapReducer(state = initialState, action: BootstrapAction): BootstrapState {
  switch (action.type) {
    case BOOTSTRAP_ACTION_TYPE.GET_BOOTSTRAP:
      return {
        ...state,
        ...action.payload,
        ...{
          pageLimit: PAGE_LIMIT,
          pageLimitOptions: PAGE_LIMIT_OPTIONS,
        },
      };
    case BOOTSTRAP_ACTION_TYPE.SET_PAGE_LIMIT:
      return state
        ? {
            ...state,
            ...{
              pageLimit: action.payload,
            },
          }
        : state;
    default:
      return state;
  }
}
