import { LocaleAction, LOCALE_ACTION_TYPE } from 'store/actions/locale';
import { detectLocale, Locale } from 'utils/locale';

export type LocaleState = Locale;

const initialState: LocaleState = detectLocale();

export default function localeReducer(state = initialState, action: LocaleAction): LocaleState {
  switch (action.type) {
    case LOCALE_ACTION_TYPE.SET_LOCALE:
      return action.payload;
    default:
      return state;
  }
}
