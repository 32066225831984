import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { Usim5g, Usim5gFields } from './../models/usim5g';
import { AxiosError, AxiosRequestConfig } from 'axios';
import config from 'config';
import { GetResultsOptions, GetResultsRes } from 'store/models/filters';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { parseError } from 'utils/errorString';
import { sentryLogError } from 'sentry';
import { getBulkOperations, showOperationScheduledToast } from './bulkOperations';

export enum USIMS_5G_ACTION_TYPE {
  SORT_SET = 'SORT_SET',
  LIST_LOADING = 'USIMS_5G_LIST_LOADING',
  LIST_SUCCESS = 'USIMS_5G_LIST_SUCCESS',
  LIST_FAILURE = 'USIMS_5G_LIST_FAILURE',
  USIMS_DETAIL_LOADING = 'USIMS_DETAIL_LOADING',
  USIMS_DETAIL_SUCCESS = 'USIMS_DETAIL_SUCCESS',
  USIMS_DETAIL_FAILURE = 'USIMS_DETAIL_FAILURE',
  RESET = 'USIMS_5G_RESET',
  USIM_SHOULD_FLUSH_SELECTION = 'USIM_SHOULD_FLUSH_SELECTION',
}

export function listLoading() {
  return {
    type: USIMS_5G_ACTION_TYPE.LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: USIMS_5G_ACTION_TYPE.LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: USIMS_5G_ACTION_TYPE.LIST_FAILURE,
  };
}

export function reset() {
  return {
    type: USIMS_5G_ACTION_TYPE.RESET,
  };
}

export function getUsims5g({ page = 0, sortBy }: GetResultsOptions): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    if (sortBy) {
      dispatch({
        type: USIMS_5G_ACTION_TYPE.SORT_SET,
        payload: sortBy,
      });
    }

    dispatch({
      type: USIMS_5G_ACTION_TYPE.LIST_LOADING,
    });

    const {
      bootstrap,
      usims5g: { sort },
    } = getState();

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getUsims5g
          .replace('{sort}', sort)
          .replace('{limit}', bootstrap?.pageLimit)
          .replace('{page}', page),
        method: 'GET',
      };

      const result = { ...(await dispatch(fetchData<GetResultsRes<Usim5g>>(options))), page };

      dispatch({
        type: USIMS_5G_ACTION_TYPE.LIST_SUCCESS,
        payload: result,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast({ message: 'usims.table.error', intlMessage: true }));
      dispatch({
        type: USIMS_5G_ACTION_TYPE.LIST_FAILURE,
      });
    }
  };
}

export type CreateUsim5gPayload = Pick<
  Usim5gFields,
  'name' | 'tenant' | 'supi' | 'msisdn' | 'key_type' | 'k' | 'op' | 'opc'
  /* TODO: update when encryption is contemplated again */
  // | 'encrypt' | 'use_default_tk' | 'use_key'
>;
export function createUsim5g(data: CreateUsim5gPayload): Thunk<Promise<string | boolean>> {
  return async (dispatch) => {
    const newData: Partial<Usim5gFields> & { tenant_id: string } = {
      name: data.name.trim(),
      tenant_id: data.tenant?.id,
      supi: data.supi,
      msisdn: data.msisdn,
      // encrypt: data.encrypt,
      ...(Boolean(data.k) && { k: data.k }),
      // ...(!data.use_default_tk && Boolean(data.use_key) && { use_key: data.use_key }),
      ...(data.key_type === 1 && Boolean(data.op) && { op: data.op }),
      ...(data.key_type === 2 && Boolean(data.opc) && { opc: data.opc }),
    };

    const options: AxiosRequestConfig = {
      url: config.apis.createUsim5g,
      method: 'POST',
      data: newData,
    };

    try {
      await dispatch(fetchData(options));
      await dispatch(getUsims5g({}));

      return false;
    } catch (e) {
      sentryLogError(e);
      const error = e as AxiosError;
      return parseError(error);
    }
  };
}

export type EditUsim5gPayload = Pick<
  Usim5gFields,
  'name' | 'key_type' | 'k' | 'op' | 'opc'
  /* TODO: update when encryption is contemplated again */
  // | 'encrypt' | 'use_default_tk' | 'use_key'
>;
export function editSingleUsim5g({
  data,
  usim5g,
}: {
  data: EditUsim5gPayload;
  usim5g: Usim5g;
}): Thunk<Promise<string | void>> {
  return async (dispatch, getState) => {
    const {
      usims5g: { usim5gDetails },
    } = getState();

    const newData: Partial<EditUsim5gPayload> = {
      name: data.name?.trim(),
      /* TODO: update when encryption is contemplated again */
      // encrypt: data.encrypt,
      ...(data.k !== '' && { k: data.k }),
      // ...(!data.use_default_tk && data.use_key !== '' && { use_key: data.use_key }),
      // ...(data.use_default_tk && { use_key: '' }),
      // ...(data.encrypt === 0 && { use_key: '' }),
      ...(data.key_type === 1 && data.op !== '' && { op: data.op }),
      ...(data.key_type === 2 && data.opc !== '' && { opc: data.opc }),
    };

    /* TODO: update when encryption is contemplated again */
    // if (data.key_type === 0) {
    //   newData.op = null;
    //   newData.opc = null;
    // }

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.editUsim5g.replace('{id}', usim5g.supi),
        method: 'PUT',
        data: {
          ...newData,
        },
      };

      await dispatch(fetchData(options));
      await dispatch(getUsims5g({}));

      if (usim5gDetails.data) {
        await dispatch(getUsims5gSupi(usim5gDetails.data.supi));
      }
      dispatch(showSuccessToast());
      return;
    } catch (e: unknown) {
      sentryLogError(e);
      const error = e as AxiosError;
      return parseError(error);
    }
  };
}

export function prepareSingleUsim5gForProvisioning({
  node_id,
  profile_id,
  usim5g,
}: {
  node_id: string;
  profile_id: string;
  usim5g?: Usim5g;
}): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      if (!usim5g) {
        throw new Error('invalid usim');
      }

      const options: AxiosRequestConfig = {
        url: config.apis.editUsim5g.replace('{id}', usim5g.supi),
        method: 'PUT',
        data: { node_id, profile_id },
      };

      await dispatch(fetchData(options));
      await dispatch(getUsims5g({}));
      return;
    } catch (e: unknown) {
      sentryLogError(e);
      const error = e as AxiosError;
      return parseError(error);
    }
  };
}

export function bulkPrepareUsims5g({
  node_id,
  profile_id,
  usims5g,
}: {
  node_id: string;
  profile_id: string;
  usims5g: string[];
}): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.prepareUsim5g,
        method: 'POST',
        data: {
          node_id,
          profile_id,
          usims: usims5g,
        },
      };

      await dispatch(fetchData(options));
      await dispatch(getUsims5g({}));
      await dispatch(getBulkOperations());
      return;
    } catch (e: unknown) {
      sentryLogError(e);
      const error = e as AxiosError;
      return parseError(error);
    }
  };
}

export function provisionUsim5g({
  node_id,
  usims5g,
}: {
  node_id: string;
  usims5g: Usim5g['id'][];
}): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.provisionUsim5g,
        method: 'POST',
        data: { node_id: node_id, usims: usims5g },
      };

      await dispatch(fetchData(options));
      await dispatch(showOperationScheduledToast());
      await dispatch(getBulkOperations());
      await dispatch(getUsims5g({}));
    } catch (e: unknown) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function deprovisionUsim5g({
  node_id,
  usims5g,
}: {
  node_id: string;
  usims5g: Usim5g['id'][];
}): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.deprovisionUsim5g,
        method: 'POST',
        data: { node_id: node_id, usims: usims5g },
      };

      await dispatch(fetchData(options));
      await dispatch(showOperationScheduledToast());
      await dispatch(getBulkOperations());
      await dispatch(getUsims5g({}));
    } catch (e: unknown) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function setUsim5gShouldFlushSelection(shouldFlushSelection: boolean) {
  return {
    type: USIMS_5G_ACTION_TYPE.USIM_SHOULD_FLUSH_SELECTION,
    payload: shouldFlushSelection,
  };
}

export function deleteUsim5g(usimIds: Usim5g['id'][]): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.deleteUsims5g,
        method: 'DELETE',
        data: usimIds,
      };

      await dispatch(fetchData(options));
      await dispatch(showOperationScheduledToast());
      await dispatch(getBulkOperations());
      await dispatch(getUsims5g({}));
    } catch (e: unknown) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function getUsims5gSupi(supi: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    const url = `${config.apis.editUsim5g.replace('{id}', encodeURIComponent(supi))}`;
    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
    };
    try {
      dispatch({ type: USIMS_5G_ACTION_TYPE.USIMS_DETAIL_LOADING });

      const usimsDetail = await dispatch(fetchData(options));
      dispatch({ type: USIMS_5G_ACTION_TYPE.USIMS_DETAIL_SUCCESS, payload: usimsDetail });
    } catch (e: any) {
      dispatch({ type: USIMS_5G_ACTION_TYPE.USIMS_DETAIL_FAILURE });
      const message = e.response?.data?.error;
      dispatch(showErrorToast(message ? { message } : undefined));
    }
  };
}
