import { Autocomplete, AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getNodesOptions } from 'store/actions/nodes';
import { object, string } from 'yup';
import { CreateUsim5gStepProps } from '../CreateUsimCard5gContent';
import { getServiceProfilesOptions } from 'store/actions/serviceProfiles';

export function Step5({
  onCompleteStep,
  initialValues,
}: CreateUsim5gStepProps & {
  onCompleteStep: (values: { profile_id: AutocompleteItemProps; node_id: AutocompleteItemProps }) => void;
}) {
  const [nodeOptions, setNodeOptions] = useState<AutocompleteItemProps[]>([]);
  const [serviceProfileOptions, setserviceProfileOptions] = useState<AutocompleteItemProps[]>([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const validationSchema = useMemo(
    () =>
      object().shape({
        node_id: object().shape({ label: string().required(), value: string().required() }).required(),
        profile_id: object().shape({ label: string().required(), value: string().required() }).required(),
      }),
    []
  );

  useEffect(() => {
    async function getNodes() {
      setOptionsLoading(true);
      setNodeOptions([]);

      const searchResults = await dispatch(getNodesOptions('athonetos'));
      setNodeOptions(searchResults);
      setOptionsLoading(false);
    }
    void getNodes();
  }, [dispatch]);

  const getSPOptions = useCallback(
    async (value: NonNullable<string | AutocompleteItemProps> | null) => {
      const nodeId = value && typeof value === 'object' && 'value' in value ? String(value.value) : '';
      setOptionsLoading(true);
      setserviceProfileOptions([]);

      if (nodeId === '') {
        setOptionsLoading(false);
        return;
      }
      const searchResults = await dispatch(getServiceProfilesOptions(nodeId));
      setserviceProfileOptions(searchResults);
      setOptionsLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    void getSPOptions(initialValues['node_id'] as NonNullable<string | AutocompleteItemProps> | null);
  }, [getSPOptions, initialValues]);

  const handleSubmit = useCallback(
    (values) => {
      onCompleteStep(values);
    },
    [onCompleteStep]
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ values, errors, touched, setFieldValue }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
              <Field name="node_id" key="node_id">
                {({ field }: FieldProps<AutocompleteItemProps>) => (
                  <Autocomplete
                    required
                    size="small"
                    {...field}
                    options={nodeOptions}
                    multiple={false}
                    onChange={(_, v) => {
                      setFieldValue('node_id', v);
                      setFieldValue('profile_id', null);
                      void getSPOptions(v);
                    }}
                    loading={optionsLoading}
                    label={formatMessage({ id: 'usims.form.usim.node' })}
                    placeholder={formatMessage({ id: 'usims.form.usim.node.placeholder' })}
                    error={Boolean(errors['node_id'])}
                    {...(Boolean(errors['node_id']) && {
                      helperText: formatMessage({ id: 'usims.form.usim.node.error' }),
                    })}
                  />
                )}
              </Field>
              <Field name="profile_id" key="profile_id">
                {({ field }: FieldProps<AutocompleteItemProps>) => (
                  <Autocomplete
                    required
                    size="small"
                    {...field}
                    options={serviceProfileOptions}
                    onChange={(_, v) => setFieldValue('profile_id', v)}
                    loading={optionsLoading}
                    label={formatMessage({ id: 'usims.form.usim.profile_id' })}
                    placeholder={formatMessage({ id: 'usims.form.usim.profile_id.placeholder' })}
                    error={Boolean(errors['profile_id'] && touched['profile_id'])}
                    disabled={!values['node_id']}
                    {...(Boolean(errors['profile_id'] && touched['profile_id']) && {
                      helperText: formatMessage({ id: 'usims.form.usim.profile_id.error' }),
                    })}
                  />
                )}
              </Field>

              <Button
                variant="outlined"
                text={formatMessage({ id: 'common.form.next' })}
                type="submit"
                disabled={Boolean(errors['node_id'] || errors['profile_id'])}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
