import { useCallback, useMemo } from 'react';
import { string, object } from 'yup';
import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';
import { FormStyled } from '../styled';
import { useDispatch } from 'react-redux';
import { createHss } from 'store/actions/nodes';
import { parseError } from 'utils/errorString';
import { sentryLogError } from 'sentry';

export function CreateHss() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const schema = useMemo(
    () =>
      object().shape({
        name: string().trim().required(),
        agentID: string().trim().required(),
        defaultTK: string()
          .trim()
          .matches(/^[a-zA-Z0-9]+$/),
        defaultOP: string()
          .trim()
          .matches(/^[0-9a-fA-F]{32}?$/),
      }),
    []
  );

  const initials = {
    name: '',
    agentID: '',
    defaultTK: '',
    defaultOP: '',
  };

  const fieldset = useMemo(
    () => [
      {
        title: formatMessage({ id: 'hss.form.data' }),
        data: [
          {
            name: 'name',
            label: formatMessage({ id: 'hss.form.name' }),
            placeholder: formatMessage({ id: 'hss.form.name.placeholder' }),
            error: formatMessage({ id: 'hss.form.name.error' }),
          },
          {
            name: 'agentID',
            label: formatMessage({ id: 'hss.form.agentID' }),
            placeholder: formatMessage({ id: 'hss.form.agentID.placeholder' }),
            error: formatMessage({ id: 'hss.form.agentID.error' }),
          },
          {
            name: 'defaultTK',
            label: formatMessage({ id: 'hss.form.defaultTK' }),
            placeholder: formatMessage({ id: 'hss.form.defaultTK.placeholder' }),
            error: formatMessage({ id: 'hss.form.defaultTK.error' }),
            gridClassName: 'fullwidth',
          },
          {
            name: 'defaultOP',
            label: formatMessage({ id: 'hss.form.defaultOP' }),
            placeholder: formatMessage({ id: 'hss.form.defaultOP.placeholder' }),
            error: formatMessage({ id: 'hss.form.defaultOP.error' }),
            gridClassName: 'fullwidth',
          },
        ],
      },
    ],
    [formatMessage]
  );

  const handleSubmit = useCallback(
    async (values, callback) => {
      const hssData = {
        name: values.name,
        payload: {
          agent_id: values.agentID,
          default_tk: values.defaultTK !== '' ? values.defaultTK : undefined,
          default_op: values.defaultOP !== '' ? values.defaultOP : undefined,
        },
      };
      try {
        await dispatch(createHss(hssData));
        callback(true);
      } catch (e) {
        sentryLogError(e);
        callback(false, parseError(e));
      }
    },
    [dispatch]
  );

  return (
    <FormStyled>
      <Base
        schema={schema}
        elType="button"
        elColor="tool"
        elSize="small"
        initials={initials}
        fieldset={fieldset}
        onSubmit={handleSubmit}
        modalTitle={formatMessage({ id: 'hss.newHss' })}
        elLabel={formatMessage({ id: 'hss.newHss' })}
      />
    </FormStyled>
  );
}
