import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SelectOption } from 'utils/forms';
import { number, object, string } from 'yup';
import {
  assignTenantToQosProfile4g,
  deassignTenantToQosProfile4g,
  getTenantsByQosProfile4g,
} from 'store/actions/tenants';
import { useDispatch } from 'react-redux';

export default function useQosProfileHandlers(
  selectOptions: {
    permissions?: SelectOption[];
    tenants?: SelectOption[];
  },
  id: string
) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const qosProfileSchema = useMemo(
    () =>
      object().shape({
        tenant: string().required(),
        permissions: number().required(),
      }),
    []
  );

  const handlegetTenantByQosProfile = useCallback(async () => {
    let owners = await dispatch(getTenantsByQosProfile4g(id));
    return owners;
  }, [dispatch, id]);

  const qosProfileFormTitles = useMemo(
    () => ({
      modalTitle: formatMessage({
        id: 'segments.form.qosProfile.manageTenants.title',
      }),
      fieldsetTitle: formatMessage({
        id: 'segments.form.qosProfile.manageTenants',
      }),
    }),
    [formatMessage]
  );

  const handleAddTenantToQosProfile = useCallback(
    async (tenant_id: string, permissions: string) => {
      return dispatch(assignTenantToQosProfile4g(id, { tenant_id, permissions }));
    },
    [dispatch, id]
  );

  const handleDeleteTenantFromQosProfile = useCallback(
    async (tenantId: string) => {
      return dispatch(deassignTenantToQosProfile4g(id, tenantId));
    },
    [dispatch, id]
  );

  const qosProfilefields = useMemo(
    () => [
      {
        name: 'tenant',
        label: formatMessage({ id: 'segments.form.qosProfile.tenant_id' }),
        placeholder: formatMessage({
          id: 'segments.form.qosProfile.tenant_id.placeholder',
        }),
        error: formatMessage({
          id: 'segments.form.qosProfile.tenant_id.error',
        }),
        options: selectOptions.tenants,
        gridClassName: 'fullwidth',
      },
      {
        name: 'permissions',
        label: formatMessage({ id: 'segments.form.qosProfile.permissions' }),
        placeholder: formatMessage({
          id: 'segments.form.qosProfile.permissions.placeholder',
        }),
        error: formatMessage({
          id: 'segments.form.qosProfile.permissions.error',
        }),
        options: selectOptions.permissions,
        gridClassName: 'fullwidth',
      },
    ],
    [formatMessage, selectOptions]
  );

  return {
    handlegetTenantByQosProfile,
    handleAddTenantToQosProfile,
    handleDeleteTenantFromQosProfile,
    qosProfileSchema,
    qosProfileFormTitles,
    qosProfilefields,
  };
}
