import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { Stepper } from '@athonet/ui/components/Navigation/Stepper';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useStepper } from '@athonet/ui/hooks/useStepper';
import { useCallback, useMemo, useState } from 'react';
import { Button } from '@athonet/ui/components/Input/Button';
import { useIntl } from 'react-intl';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Box } from '@material-ui/core';
import { createNode } from 'store/actions/nodes';
import { useDispatch } from 'react-redux';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Step4 } from './Step4';

export type NewNodeStepProps = {
  onCompleteStep: (step: number, values: Record<string, unknown>) => void;
  initialValues: Record<string, string | boolean | number | AutocompleteItemProps | null>;
  active?: boolean;
};

const CREATE_NODE_STEPS = 5;
const MILESTONES_ARRAY = [3];

export function CreateNodeContent({ onSubmit }: { onSubmit: () => Promise<void> }) {
  const [loading, setLoading] = useState(false);
  const [newNodeId, setNewNodeId] = useState(null);
  const [error, setError] = useState<string | null>(null);

  const { dialogClose } = useOverlay();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    handleGoToStep,
    active,
    skipped,
    completed,
    handleGoToNextIncompleteStep,
    handleCompleteMilestone,
    milestonesCompleted,
  } = useStepper({
    steps: CREATE_NODE_STEPS,
    milestones: MILESTONES_ARRAY,
  });

  const [createValues, setCreateValues] = useState<{
    display_name: string;
    tenant: AutocompleteItemProps | null;
    product_version: string | null;
  }>({
    display_name: '',
    tenant: null,
    product_version: null,
  });

  const onCompleteStep = useCallback(
    (_step, values) => {
      setCreateValues((prevState) => ({ ...prevState, ...values }));
      handleGoToNextIncompleteStep();
    },
    [handleGoToNextIncompleteStep]
  );

  const handleCreateNode = useCallback(async () => {
    setLoading(true);

    const { id, error: errorRes } = await dispatch(createNode(createValues));
    setLoading(false);

    if (errorRes) {
      setError(errorRes);
    } else if (id) {
      setNewNodeId(id);
      setError(null);
      handleCompleteMilestone(3);
      void onSubmit();
    }
  }, [createValues, dispatch, handleCompleteMilestone, onSubmit]);

  const isLegacyNode = useMemo(() => Boolean(createValues.product_version?.match(/EPC/)), [createValues]);

  return (
    <>
      <IconButton
        name="Close"
        fontSize="small"
        onClick={dialogClose}
        sx={{ position: 'absolute', right: '16px', top: '8px', zIndex: 100 }}
      />
      <DialogContent>
        <Stepper
          orientation="vertical"
          completed={completed}
          nonLinear
          activeStep={active}
          onStepClick={handleGoToStep}
          skipped={skipped}
          milestonesCompleted={milestonesCompleted}
        >
          <Step label={formatMessage({ id: 'nodes.form.node.ownership' })}>
            <Step1 onCompleteStep={onCompleteStep} initialValues={{ tenant: createValues['tenant'] }} />
          </Step>

          <Step label={formatMessage({ id: 'nodes.form.node.name' })}>
            <Step2 onCompleteStep={onCompleteStep} initialValues={{ display_name: createValues['display_name'] }} />
          </Step>

          <Step label={formatMessage({ id: 'nodes.form.node.product_version' })}>
            <Step3
              onCompleteStep={onCompleteStep}
              initialValues={{ product_version: createValues['product_version'] }}
            />
          </Step>

          <Step label={formatMessage({ id: 'nodes.form.node.creation' })} checkpoint>
            <Box sx={{ my: 2 }}>
              {error && (
                <Box sx={{ width: '100%' }}>
                  <Alert
                    severity="error"
                    title="Error"
                    message={formatMessage({ id: 'nodes.create.error.title' }, { error: error })}
                  />
                </Box>
              )}
            </Box>

            {milestonesCompleted.has(3) ? (
              <Step4 isLegacyNode={isLegacyNode} newNodeId={newNodeId} />
            ) : (
              <Stack spacing={4} direction="row" justify="flex-end">
                {!error && <Text>{formatMessage({ id: 'nodes.form.node.check.message' })}</Text>}
                <Button
                  text={
                    error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })
                  }
                  loading={loading}
                  onClick={() => void handleCreateNode()}
                />
              </Stack>
            )}
          </Step>
        </Stepper>
      </DialogContent>
    </>
  );
}
