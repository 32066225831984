import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { Role } from 'store/models/role';
import { Thunk } from '.';
import { fetchData } from './fetchData';

export enum ROLES_ACTION_TYPE {
  LIST_LOADING = 'ROLES_LIST_LOADING',
  LIST_SUCCESS = 'ROLES_LIST_SUCCESS',
  LIST_FAILURE = 'ROLES_LIST_FAILURE',
}

export type RolesAction = {
  type: ROLES_ACTION_TYPE;
  payload: Role[];
};

export function getRoles(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: ROLES_ACTION_TYPE.LIST_LOADING,
    });

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getRoles,
        method: 'GET',
      };

      const result = await dispatch(fetchData(options));
      dispatch({
        type: ROLES_ACTION_TYPE.LIST_SUCCESS,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: ROLES_ACTION_TYPE.LIST_FAILURE,
      });
    }
  };
}
