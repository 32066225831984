import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Avatar } from '@athonet/ui/components/Persona/Avatar';
import { Panel } from '@athonet/ui/components/Surfaces/Panel';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import KeyValue from 'components/Details/KeyValue';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useCanUserUpdateUserSelector, useUserSelector } from 'store/selectors/user';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import EditUser from 'components/Edit/UserProfile/edit';

export default function AccountData() {
  const { formatMessage } = useIntl();
  const user = useUserSelector();
  const canUserUpdateUser = useCanUserUpdateUserSelector();
  const { dialogOpen } = useOverlay();

  const openEdit = useCallback(
    () =>
      dialogOpen({
        title: formatMessage({ id: 'profile.editPersonalData' }),
        content: () => <EditUser />,
      }),
    [dialogOpen, formatMessage]
  );

  if (!user) {
    return null;
  }

  return (
    <Panel
      title={formatMessage({ id: 'profile.account' })}
      headerActionsComponent={
        <IconButton
          name="Pencil"
          onClick={openEdit}
          fontSize="small"
          disabled={!canUserUpdateUser}
          data-testid="profile-edit"
        />
      }
    >
      <PanelContent>
        <Stack direction="row" align="center" nowrap>
          <Avatar src={user.picture} size={80} />
          <GridContainer sx={{ overflowX: 'auto' }}>
            <KeyValue
              key="fullname"
              label={formatMessage({ id: 'profile.fullname' })}
              value={user.fullname}
              fullwidth
            />
            {user.phone && (
              <KeyValue key="phone" label={formatMessage({ id: 'profile.phone' })} value={user.phone} fullwidth />
            )}
            {user.realm !== AUTH_PROVIDER.LDAP && (
              <KeyValue key="email" label={formatMessage({ id: 'profile.email' })} value={user.email} fullwidth />
            )}
          </GridContainer>
        </Stack>
      </PanelContent>
    </Panel>
  );
}
