import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { Divider } from '@athonet/ui/components/Layout/Divider';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import KeyValue from 'components/Details/KeyValue';
import ManageSites from 'components/Edit/Nodes/ManageSites';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Node } from 'store/models/node';
import { useCanUserAssignSiteToNodeSelector } from 'store/selectors/user';

export function SitesSection({ sites, id }: Pick<Node, 'sites' | 'id'>) {
  const { formatMessage } = useIntl();
  const [isManageSitesOpen, setIsManageSitesOpen] = useState(false);
  const canUserAssignSiteToNode = useCanUserAssignSiteToNodeSelector();
  const handleOpenManageSites = useCallback(() => {
    setIsManageSitesOpen(true);
  }, []);

  const handleCloseManageSites = useCallback(() => {
    setIsManageSitesOpen(false);
  }, []);

  return (
    <>
      <Panel
        title={formatMessage({ id: 'nodes.table.sites' })}
        headerActionsComponent={
          <IconButton
            name="Pencil"
            fontSize="small"
            onClick={handleOpenManageSites}
            disabled={!canUserAssignSiteToNode}
          />
        }
      >
        <PanelContent>
          {sites?.length
            ? sites.map((site, i) => (
                <GridContainer key={site.id}>
                  <KeyValue label="Tenant" value={site.name} />
                  <KeyValue label="Site" value={site.sites[0].name} />
                  {i + 1 < Number(sites?.length) && (
                    <GridItem size={{ xs: 12 }} sx={{ mb: 1 }}>
                      <Divider />
                    </GridItem>
                  )}
                </GridContainer>
              ))
            : '-'}
        </PanelContent>
      </Panel>
      {isManageSitesOpen && <ManageSites isOpen={isManageSitesOpen} onClose={handleCloseManageSites} nodeId={id} />}
    </>
  );
}
