import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { CreateUsimCard5gContent } from './CreateUsimCard5gContent';

export function CreateUsimCard5g() {
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();

  const handleOpenCreate = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'usims.newUsim5g' }),
      content: () => <CreateUsimCard5gContent />,
    });
  }, [dialogOpen, formatMessage]);

  return (
    <Button
      text={formatMessage({ id: 'common.button.new' })}
      data-testid="toolbar-new-button"
      startIcon="Add"
      variant="outlined"
      onClick={handleOpenCreate}
    />
  );
}
