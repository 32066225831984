import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import KeyValue from 'components/Details/KeyValue';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { checkPermissionsList, checkPermissionToUse, U_PERMISSIONS } from 'utils/permissionCodes';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Chart } from '@athonet/ui/components/Data/Chart';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import UsimStatus from 'components/UsimStatus';
import {
  useCanUserUpdateUsimSelector,
  useCanUserViewUsimOrdersSelector,
  useHasUserCPMasterTenantSelector,
  useUserSelector,
} from 'store/selectors/user';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import {
  activateUsim,
  barUsim,
  changeUsimNode,
  changeUsimProfile,
  changeUsimSite,
  changeUsimTenant,
  deactivateUsim,
  deprovisionUsim,
  editSingleUsim,
  getUsim,
  unbarUsim,
} from 'store/actions/usims';
import { getKText } from 'store/models/usim';
import EditUsim from 'components/Edit/Usims/EditUsim';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { usePollOperation } from 'hooks/usePollOperation';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailPageParams } from 'Router';
import { TAB } from '.';
import moment from 'moment';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { MapBox } from '@athonet/ui/components/Data/MapBox';
import { Link } from '@athonet/ui/components/Navigation/Link';
import theme from '@athonet/ui/theme';
import {
  useLast24hsTrafficKPISelector,
  useLast7DaysTrafficKPISelector,
  useUsimEntitySelector,
  useUsimTrafficVolumeSeriesSelector,
} from 'store/selectors/usims/cards';
import MetadataEditor from 'components/Modals/MetadataEditor';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { isEntityLoading } from 'store/reducers';
import { Alert } from '@athonet/ui/components/Feedback/Alert';

export function UsimCardsIdDetails() {
  const bootstrap = useBootstrapSelector();
  const { id } = useParams<DetailPageParams<TAB>>();
  const usimDetail = useUsimEntitySelector(id as string);
  const [isUpdating, setIsUpdating] = useState(false);
  const user = useUserSelector();
  const canUserUpdateUsim = useCanUserUpdateUsimSelector();
  const hasUserCPMasterTenant = useHasUserCPMasterTenantSelector();
  const canUserViewUsimOrders = useCanUserViewUsimOrdersSelector();
  const usimTrafficVolumeSeries = useUsimTrafficVolumeSeriesSelector(usimDetail?.data?.id ?? '');
  const last24hsTraffic = useLast24hsTrafficKPISelector(usimDetail?.data?.id ?? '');
  const last7DaysTraffic = useLast7DaysTrafficKPISelector(usimDetail?.data?.id ?? '');
  const { pollOperation, clearPollOperation } = usePollOperation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dialogClose, dialogOpen, menuOpen, confirmationSimpleDialogOpen, menuClose } = useOverlay();
  const { formatMessage } = useIntl();

  useEffect(() => {
    return () => {
      clearPollOperation();
    };
  }, [clearPollOperation]);

  const openUsimActionconfirm = useCallback(
    ({ operation, action }: { operation: string; action: () => Promise<{ operation_id: string }> }) => {
      if (!id) {
        return;
      }
      confirmationSimpleDialogOpen({
        title: formatMessage({ id: `usims.actions.${operation}.title` }),
        description: formatMessage({ id: 'common.confirmOperation' }),
        onConfirm: async () => {
          const op = await action();
          pollOperation(op.operation_id, async () => {
            await dispatch(getUsim(id, true));
            setIsUpdating(true);
            setIsUpdating(false);
          });
        },
      });
    },
    [confirmationSimpleDialogOpen, dispatch, formatMessage, id, pollOperation]
  );

  const openChangeSite = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'usims.actions.changeSite.site_id.title' }),
      content: () => {
        if (!usimDetail?.data) {
          return <></>;
        }
        return (
          <EditUsim
            onSubmit={(values) =>
              openUsimActionconfirm({
                operation: 'changeSite',
                action: async () => {
                  if (!usimDetail?.data) {
                    return;
                  }
                  dialogClose();
                  menuClose();
                  return dispatch(changeUsimSite(values, [usimDetail.data]));
                },
              })
            }
            show={[{ fields: ['site'] }]}
            initials={{
              site: usimDetail.data.site,
            }}
          />
        );
      },
    });
  }, [dialogClose, dialogOpen, dispatch, formatMessage, menuClose, openUsimActionconfirm, usimDetail]);

  const openChangeProfile = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'usims.actions.changeProfile.profile_id.title' }),
      content: () => {
        if (!usimDetail?.data) {
          return <></>;
        }
        return (
          <EditUsim
            onSubmit={(values) =>
              openUsimActionconfirm({
                operation: 'changeProfile',
                action: async () => {
                  if (!usimDetail?.data) {
                    return;
                  }
                  dialogClose();
                  return dispatch(changeUsimProfile(values, [usimDetail.data]));
                },
              })
            }
            show={[{ fields: ['profile'] }]}
            initials={{
              profile: usimDetail.data.profile,
            }}
          />
        );
      },
    });
  }, [dialogClose, dialogOpen, dispatch, formatMessage, openUsimActionconfirm, usimDetail]);

  const openChangeNode = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'usims.actions.changeNode.node_id.title' }),
      content: () => {
        if (!usimDetail?.data) {
          return <></>;
        }
        return (
          <EditUsim
            onSubmit={(values) =>
              openUsimActionconfirm({
                operation: 'changeNode',
                action: async () => {
                  if (!usimDetail?.data) {
                    return;
                  }
                  dialogClose();
                  menuClose();
                  return dispatch(changeUsimNode(values, [usimDetail.data]));
                },
              })
            }
            show={[{ fields: ['node'] }]}
            initials={{
              node: usimDetail.data.node,
            }}
          />
        );
      },
    });
  }, [dialogClose, dialogOpen, dispatch, formatMessage, menuClose, openUsimActionconfirm, usimDetail]);

  const locationActions = useMemo(() => {
    if (!usimDetail?.data) {
      return;
    }
    const items = checkPermissionsList(
      user?.permissions || [],
      [
        {
          label: formatMessage({ id: 'usims.actions.changeSite.title' }),
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          value: 'change-usim-site',
          onClick: () => openChangeSite(),
        },
        {
          label: formatMessage({ id: 'usims.actions.changeNode.title' }),
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          value: 'change-usim-node',
          onClick: () => openChangeNode(),
        },
        {
          label: formatMessage({ id: 'usims.actions.deprovision.title' }),
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          value: 'deprovision-usim',
          onClick: () => {
            if (!usimDetail?.data) {
              return;
            }
            openUsimActionconfirm({
              operation: 'deprovision',
              action: dispatch(deprovisionUsim([usimDetail.data.id])),
            });
          },
          show: Boolean(usimDetail.data.node),
        },
      ],
      false
    );

    return items.filter((item) => item.show !== false);
  }, [dispatch, formatMessage, openChangeNode, openChangeSite, openUsimActionconfirm, user?.permissions, usimDetail]);

  const openLocationActionsMenu = useCallback(
    (e) => {
      if (!locationActions) {
        return;
      }
      menuOpen({ anchorEl: e.currentTarget, items: locationActions });
    },
    [locationActions, menuOpen]
  );

  const openChangeTenant = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'usims.actions.changeTenant.tenant_id.title' }),
      content: () => {
        if (!usimDetail?.data) {
          return <></>;
        }
        return (
          <EditUsim
            onSubmit={(values) =>
              openUsimActionconfirm({
                operation: 'changeTenant',
                action: async () => {
                  if (!usimDetail?.data) {
                    return;
                  }
                  dialogClose();
                  return dispatch(changeUsimTenant(values, [usimDetail.data]));
                },
              })
            }
            show={[{ fields: ['tenant'] }]}
            initials={{
              tenant: usimDetail.data.tenant,
            }}
          />
        );
      },
    });
  }, [dialogClose, dialogOpen, dispatch, formatMessage, openUsimActionconfirm, usimDetail]);

  const openEdit = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'usims.actions.edit.title' }),
      content: () => {
        if (!usimDetail?.data) {
          return <></>;
        }
        return (
          <EditUsim
            usim={usimDetail.data}
            onSubmit={(data) => {
              if (!usimDetail?.data) {
                return;
              }
              void dispatch(editSingleUsim({ data, usim: usimDetail.data }));
              dialogClose();
            }}
            show={[
              {
                label: formatMessage({ id: 'usims.form.usim.data' }),
                fields: ['name', 'imsi', 'iccid', 'ims_profile', 'msisdn'],
              },
              {
                label: formatMessage({ id: 'usims.form.usim.kData' }),
                fields: ['k', 'encrypt', 'use_default_tk', 'use_key'],
                showIf: [
                  {
                    field: 'use_key',
                    condition: ({ encrypt, use_default_tk }) => Boolean(encrypt && !use_default_tk),
                  },
                  {
                    field: 'use_default_tk',
                    condition: ({ encrypt }) => Boolean(encrypt),
                  },
                ],
              },
              {
                label: formatMessage({ id: 'usims.form.usim.opOpcData' }),
                fields: ['key_type', 'key_override', 'op', 'opc'],
                showIf: [
                  {
                    field: 'key_override',
                    condition: ({ key_type }) =>
                      Boolean((key_type === 1 && usimDetail.data?.op) || (key_type === 2 && usimDetail.data?.opc)),
                  },
                  {
                    field: 'op',
                    condition: ({ key_type, key_override }) =>
                      Boolean(key_type === 1 && (!usimDetail.data?.op || key_override)),
                  },
                  {
                    field: 'opc',
                    condition: ({ key_type, key_override }) =>
                      Boolean(key_type === 2 && (!usimDetail.data?.opc || key_override)),
                  },
                ],
              },
            ]}
            initials={{
              ...usimDetail.data,
              op: '',
              opc: '',
              k: '',
              key_type:
                !usimDetail.data.op && !usimDetail.data.opc ? 0 : usimDetail.data.op && !usimDetail.data.opc ? 1 : 2,
              key_override: false,
              use_default_tk: Boolean(!usimDetail.data['use_key']),
            }}
          />
        );
      },
    });
  }, [dialogClose, dialogOpen, dispatch, formatMessage, usimDetail]);

  const usimStatusActions = useMemo(() => {
    if (!usimDetail?.data) {
      return [];
    }
    const usimBarredOrUnavailable = usimDetail.data.status === 'barred' || usimDetail.data.status === 'unavailable';
    const forceDisable = usimDetail.data.updating || usimBarredOrUnavailable;
    const items = checkPermissionsList(
      user?.permissions || [],
      [
        {
          label: formatMessage({ id: 'usims.actions.deprovision.title' }),
          disabled: !usimDetail.data.node || forceDisable,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          show: !!usimDetail.data.node,
          value: 'deprovision-usim',
          onClick: () =>
            openUsimActionconfirm({
              operation: 'deprovision',
              action: async () => {
                if (!usimDetail?.data) {
                  return;
                }
                return dispatch(deprovisionUsim([usimDetail.data.id]));
              },
            }),
        },
        {
          label: formatMessage({ id: 'usims.actions.bar.title' }),
          disabled:
            (usimDetail.data.status !== 'inactive' &&
              usimDetail.data.status !== 'active' &&
              usimDetail.data.status !== 'barring') ||
            forceDisable,
          permissions: [U_PERMISSIONS.BAR_USIM],
          show:
            usimDetail.data.status === 'inactive' ||
            usimDetail.data.status === 'active' ||
            (usimDetail.data.error && usimDetail.data.status === 'barring'),
          value: 'bar-usim',
          onClick: () =>
            openUsimActionconfirm({
              operation: 'bar',
              action: async () => {
                if (!usimDetail?.data) {
                  return;
                }
                return dispatch(barUsim([usimDetail.data.id]));
              },
            }),
        },
        {
          label: formatMessage({ id: 'usims.actions.unbar.title' }),
          disabled: usimDetail.data.updating || usimDetail.data.status !== 'barred',
          permissions: [U_PERMISSIONS.UNBAR_USIM],
          show: usimDetail.data.status === 'barred',
          value: 'unbar-usim',
          onClick: () =>
            openUsimActionconfirm({
              operation: 'unbar',
              action: async () => {
                if (!usimDetail?.data) {
                  return;
                }
                return dispatch(unbarUsim([usimDetail.data.id]));
              },
            }),
        },
        {
          label: formatMessage({ id: 'usims.actions.activate.title' }),
          disabled: usimDetail.data.status === 'in_stock' || forceDisable,
          permissions: [U_PERMISSIONS.ACTIVATE_USIM],
          show:
            usimDetail.data.status === 'in_stock' ||
            usimDetail.data.status === 'inactive' ||
            (usimDetail.data.error && usimDetail.data.status === 'activating') ||
            (usimDetail.data.error && usimDetail.data.status === 'deactivating'),
          value: 'activate-usim',
          onClick: () =>
            openUsimActionconfirm({
              operation: 'activate',
              action: async () => {
                if (!usimDetail?.data) {
                  return;
                }
                return dispatch(activateUsim([usimDetail.data.id]));
              },
            }),
        },
        {
          label: formatMessage({ id: 'usims.actions.deactivate.title' }),
          disabled: usimDetail.data.status === 'in_stock' || forceDisable,
          permissions: [U_PERMISSIONS.DEACTIVATE_USIM],
          show:
            usimDetail.data.status === 'active' ||
            (usimDetail.data.error && usimDetail.data.status === 'activating') ||
            (usimDetail.data.error && usimDetail.data.status === 'deactivating'),
          value: 'deactivate-usim',
          onClick: () =>
            openUsimActionconfirm({
              operation: 'deactivate',
              action: async () => {
                if (!usimDetail?.data) {
                  return;
                }
                return dispatch(deactivateUsim([usimDetail.data.id]));
              },
            }),
        },
      ],
      false
    );

    return items.filter((item) => item.show !== false);
  }, [dispatch, formatMessage, openUsimActionconfirm, user, usimDetail]);

  const openUsimStatusActionsMenu = useCallback(
    (e) => {
      menuOpen({ anchorEl: e.currentTarget, items: usimStatusActions });
    },
    [menuOpen, usimStatusActions]
  );

  const openMetadataEditor = useCallback(() => {
    if (!usimDetail?.data) {
      return;
    }
    dialogOpen({
      title: `${formatMessage({ id: 'usims.metadata.title' })} - USIM ${usimDetail.data.imsi}`,
      content: () => (usimDetail.data ? <MetadataEditor usim={usimDetail.data} /> : <></>),
    });
  }, [dialogOpen, formatMessage, usimDetail]);

  const siteMarker = useMemo(() => {
    if (!usimDetail?.data || !usimDetail.data.site || !usimDetail.data.site.coords || isUpdating) {
      return;
    }

    return [
      {
        address: `${usimDetail.data.site.address}, ${usimDetail.data.site.country}`,
        text: usimDetail.data.site.name,
        center: usimDetail.data.site.coords.split(','),
      },
    ];
  }, [usimDetail, isUpdating]);

  if (!usimDetail) {
    return null;
  }

  return isEntityLoading(usimDetail) ? (
    <GridContainer sx={{ mt: 1 }}>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={2}>
          <Skeleton height={420} variant="rectangular" />
          <Skeleton height={120} variant="rectangular" />
        </Stack>
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={2}>
          <Skeleton height={120} variant="rectangular" />
          <Skeleton height={420} variant="rectangular" />
        </Stack>
      </GridItem>
    </GridContainer>
  ) : usimDetail.data ? (
    <GridContainer sx={{ mt: 1 }}>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={2}>
          <Panel
            title={formatMessage({ id: 'usims.id.details.generalData' })}
            headerActionsComponent={
              <IconButton name="Pencil" onClick={openEdit} fontSize="small" disabled={!canUserUpdateUsim} />
            }
          >
            <PanelContent>
              <GridContainer>
                <KeyValue
                  label={formatMessage({ id: 'usims.table.name' })}
                  value={usimDetail.data.name.toUpperCase()}
                  fullwidth
                />
                <KeyValue label={formatMessage({ id: 'usims.table.imsi' })} value={usimDetail.data.imsi} />
                <KeyValue label={formatMessage({ id: 'usims.table.imsi2' })} value={usimDetail.data.imsi2} />
                <KeyValue label={formatMessage({ id: 'usims.table.iccid' })} value={usimDetail.data.iccid} />
                <KeyValue
                  label={formatMessage({ id: 'usims.table.ims_profile' })}
                  value={usimDetail.data.ims_profile ? 'Enabled' : 'Disabled'}
                />
                <KeyValue label={formatMessage({ id: 'usims.table.msisdn' })} value={usimDetail.data.msisdn} />
              </GridContainer>
            </PanelContent>
            <PanelContent title="AKA Authentication" titleColor="text.secondary">
              <GridContainer>
                <KeyValue
                  label={formatMessage({ id: 'usims.table.k' })}
                  value={getKText({
                    k: usimDetail.data.k,
                    encrypt: usimDetail.data.encrypt,
                    use_key: usimDetail.data.use_key,
                  })}
                />
                <KeyValue
                  label={formatMessage({ id: 'usims.table.op' })}
                  value={usimDetail.data.op ? 'Set' : 'Not Set'}
                />
                <KeyValue
                  label={formatMessage({ id: 'usims.table.opc' })}
                  value={usimDetail.data.opc ? 'Set' : 'Not Set'}
                />
              </GridContainer>
            </PanelContent>
          </Panel>
          <Panel
            title={formatMessage({ id: 'usims.id.details.location' })}
            headerActionsComponent={
              <IconButton
                name="Other"
                onClick={(e) => openLocationActionsMenu(e)}
                fontSize="small"
                sx={{ transform: 'rotate(90deg)' }}
                disabled={!canUserUpdateUsim}
              />
            }
          >
            <PanelContent>
              <GridContainer sx={{ mb: 2 }}>
                <KeyValue
                  label={formatMessage({ id: 'usims.table.nodeName' })}
                  value={usimDetail.data.node ? usimDetail.data.node.name : null}
                  fullwidth
                />
                <KeyValue
                  label={formatMessage({ id: 'usims.table.siteName' })}
                  value={usimDetail.data.site ? usimDetail.data.site.name : null}
                />
                {/* TODO: add mapbox site */}
                {usimDetail.data.site?.address && (
                  <KeyValue
                    label={formatMessage({ id: 'usims.site.address' })}
                    value={
                      usimDetail.data.site ? `${usimDetail.data.site.address}, ${usimDetail.data.site.country}` : null
                    }
                  />
                )}
              </GridContainer>
              {siteMarker && bootstrap && bootstrap.mapbox_token && bootstrap.mapbox_styles && (
                <MapBox
                  id="usim-detail-map"
                  centerAddress={siteMarker[0].center}
                  height={260}
                  locations={siteMarker}
                  markers
                  zoom={5}
                  data-testid="usim-detail-map"
                  accessToken={bootstrap.mapbox_token}
                  stylesUrl={bootstrap.mapbox_styles}
                />
              )}
            </PanelContent>
          </Panel>
          {canUserViewUsimOrders && usimDetail.data.order && bootstrap?.usim_orders && (
            <Panel title="Order Details">
              <PanelContent>
                <GridContainer>
                  <KeyValue
                    label={formatMessage({ id: 'usims.table.order_id' })}
                    value={usimDetail.data.order.order_id}
                  />
                  <KeyValue
                    label={formatMessage({ id: 'usims.table.batch_id' })}
                    value={usimDetail.data.order.batch_id}
                  />
                  <KeyValue
                    label={formatMessage({ id: 'usims.order.description' })}
                    value={usimDetail.data.order.description}
                    fullwidth
                  />
                  <KeyValue
                    label={formatMessage({ id: 'usims.table.created' })}
                    value={moment(usimDetail.data.order.created_at).format('MMMM DD, YYYY')}
                  />
                  <KeyValue
                    label={formatMessage({ id: 'usims.table.updated' })}
                    value={moment(usimDetail.data.order.updated_at).format('MMMM DD, YYYY')}
                  />
                </GridContainer>
              </PanelContent>
            </Panel>
          )}
          <Panel title="Creation Details">
            <PanelContent>
              <GridContainer>
                <KeyValue
                  label={formatMessage({ id: 'usims.table.created' })}
                  value={moment(usimDetail.data.updated_at).format('MMMM DD, YYYY HH:mm:ss')}
                />
                <KeyValue
                  label={formatMessage({ id: 'usims.table.updated' })}
                  value={moment(usimDetail.data.updated_at).format('MMMM DD, YYYY HH:mm:ss')}
                />
              </GridContainer>
            </PanelContent>
          </Panel>
        </Stack>
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={2}>
          <Panel
            title={formatMessage({ id: 'usims.table.status' })}
            headerActionsComponent={
              <IconButton
                name="Other"
                onClick={(e) => openUsimStatusActionsMenu(e)}
                fontSize="small"
                sx={{ transform: 'rotate(90deg)' }}
                disabled={!usimStatusActions.length}
              />
            }
          >
            <PanelContent>
              <UsimStatus
                status={usimDetail.data.status}
                error={usimDetail.data.error}
                updating={usimDetail.data.updating}
              />
            </PanelContent>
          </Panel>
          <Box>
            <GridContainer>
              {usimDetail.data.profile && (
                <GridItem size={{ xs: 12, sm: 6 }}>
                  <Panel
                    title={formatMessage({ id: 'usims.table.profileName' })}
                    headerActionsComponent={
                      <IconButton
                        name="Pencil"
                        onClick={() => openChangeProfile()}
                        fontSize="small"
                        disabled={!canUserUpdateUsim}
                      />
                    }
                  >
                    <PanelContent>
                      <Link
                        onClick={() => {
                          if (!usimDetail?.data) {
                            return;
                          }
                          navigate(`/4g-provisioning/usim-profiles/${usimDetail.data.profile.id}`);
                        }}
                      >
                        <Text>{usimDetail.data.profile.name || '-'}</Text>
                      </Link>
                    </PanelContent>
                  </Panel>
                </GridItem>
              )}
              {hasUserCPMasterTenant && (
                <GridItem size={{ xs: 12, sm: 6 }}>
                  <Panel
                    title={formatMessage({ id: 'usims.table.tenantName' })}
                    headerActionsComponent={
                      <IconButton
                        name="Pencil"
                        onClick={() => openChangeTenant()}
                        fontSize="small"
                        disabled={!canUserUpdateUsim}
                      />
                    }
                  >
                    <PanelContent>
                      <Text>{usimDetail.data.tenant.name || '-'}</Text>
                    </PanelContent>
                  </Panel>
                </GridItem>
              )}
            </GridContainer>
          </Box>
          <Panel
            title={formatMessage({ id: 'usims.metadata' })}
            headerActionsComponent={
              <IconButton
                name="Pencil"
                onClick={() => openMetadataEditor()}
                fontSize="small"
                disabled={!canUserUpdateUsim}
              />
            }
          >
            <PanelContent>
              <GridContainer>
                {Object.entries(usimDetail.data.metadata).length === 0 ? (
                  <GridItem size={{ xs: 12, sm: 6 }}>{formatMessage({ id: 'usims.id.details.noMetadata' })}</GridItem>
                ) : (
                  Object.entries(usimDetail.data.metadata).map(([key, value]) => (
                    <Fragment key={key}>
                      <KeyValue label="Key" value={key} />
                      <KeyValue label="Value" value={value} />
                    </Fragment>
                  ))
                )}
              </GridContainer>
            </PanelContent>
          </Panel>
          {checkPermissionToUse(user, U_PERMISSIONS.VIEW_REPORTS_TRAFFIC_MODULE) && bootstrap?.traffic_service && (
            <Panel title="Performance">
              <PanelContent>
                <GridContainer>
                  {!last24hsTraffic && !last7DaysTraffic && (
                    <GridItem size={{ xs: 12 }}>
                      <Alert severity="error" message={formatMessage({ id: 'usims.id.details.trafficError' })} />
                    </GridItem>
                  )}
                  {last24hsTraffic && (
                    <KeyValue
                      label={formatMessage({ id: 'usims.id.details.lastDayTraffic' })}
                      value={last24hsTraffic}
                    />
                  )}
                  {last7DaysTraffic && (
                    <KeyValue
                      label={formatMessage({ id: 'usims.id.details.last7DaysTraffic' })}
                      value={last7DaysTraffic}
                    />
                  )}
                </GridContainer>
                {usimTrafficVolumeSeries && usimTrafficVolumeSeries.some((point) => point[1] !== '-') && (
                  <Chart
                    height={32}
                    options={{
                      grid: {
                        left: -24,
                        right: -24,
                        top: 24,
                        bottom: 0,
                        show: false,
                      },
                      series: {
                        type: 'line',
                        name: 'name',
                        data: usimTrafficVolumeSeries,
                        color: theme.palette.primary.main,
                        symbol: 'none',
                      },

                      tooltip: {
                        show: false,
                      },
                      yAxis: {
                        splitLine: {
                          show: false,
                        },
                        min: Math.min(
                          ...(usimTrafficVolumeSeries?.map(([, value]) =>
                            typeof value === 'string' ? parseInt(value) : value
                          ) || [])
                        ),
                        type: 'value',
                        axisLabel: {
                          show: false,
                        },
                      },
                      xAxis: {
                        type: 'category',
                        show: false,
                      },
                      legend: {
                        show: false,
                      },
                    }}
                    data-testid="usim-info-drawer-sparkline"
                  />
                )}
              </PanelContent>
            </Panel>
          )}
        </Stack>
      </GridItem>
    </GridContainer>
  ) : null;
}
