// React
import { useEffect, useState } from 'react';

// External Components
import { object, string } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';

// Style
import { FormStyled, AddListStyled } from '../styled';

import NodesList from './NodesList';

const ManageNodes = ({ dataOptions, data, isOpen, onClose, onAssignNode, onDeassignNode }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    setFieldlist(fieldset);
  }, [dataOptions]);

  const schema = object().shape({
    node_id: string().required(),
  });

  const fieldset = [
    {
      title: formatMessage({ id: 'usimProfiles.form.usimProfile.manageNodes' }),
      data: [
        {
          name: 'node_id',
          label: formatMessage({ id: 'usimProfiles.form.usimProfile.node_id' }),
          placeholder: formatMessage({ id: 'usimProfiles.form.usimProfile.node_id.placeholder' }),
          error: formatMessage({ id: 'usimProfiles.form.usimProfile.node_id.error' }),
          options: dataOptions.nodes,
          gridClassName: 'fullwidth',
        },
      ],
    },
  ];

  const initials = {
    node_id: '',
  };

  return (
    <FormStyled>
      <Base
        schema={schema}
        initials={initials}
        isOpen={isOpen}
        onClose={onClose}
        fieldset={fieldlist}
        onSubmit={onAssignNode}
        modalTitle={formatMessage({ id: 'usimProfiles.form.usimProfile.manageNodes.title' })}
        modalDescription={<p>QoS Profile: {data?.name}</p>}
        ComponentBottom={<NodesList data={data?.nodes} onDeassignNode={onDeassignNode} />}
        labelContinue={formatMessage({ id: 'common.button.add' })}
        labelCancel={formatMessage({ id: 'common.form.close' })}
        StyleFormWrapper={AddListStyled}
        keepOpen
      />
    </FormStyled>
  );
};

export default ManageNodes;
