// React
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// External Components
import { object, string, array } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';
import { Multiselect } from 'components/Form/Multiselect';

// Style
import { FormStyled } from '../styled';

const Create = ({ data, onSubmit, checkPermissions }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));
  }, [data]);

  const schema = object().shape({
    role: string().required(),
    permissions: array().required(),
  });

  const initials = {
    role: '',
    permissions: [],
  };

  const fieldset = [
    {
      title: formatMessage({ id: 'security.form.data' }),
      data: [
        {
          name: 'role',
          label: formatMessage({ id: 'security.form.role' }),
          placeholder: formatMessage({ id: 'security.form.role.placeholder' }),
          error: formatMessage({ id: 'security.form.role.error' }),
        },
        {
          name: 'permissions',
          fieldComponent: Multiselect,
          label: formatMessage({ id: 'security.form.permissions' }),
          placeholder: formatMessage({ id: 'security.form.permissions.placeholder' }),
          componentProps: {
            options: data,
            multiple: true,
            disableCloseOnSelect: true,
            disableClearable: false,
            limitTags: -1,
            selectAll: true,
            selectAllLabel: formatMessage({ id: 'common.button.selectAll' }),
          },
          gridClassName: 'fullwidth',
        },
      ],
    },
  ];

  return (
    <FormStyled>
      <Base
        schema={schema}
        elType="button"
        elColor="tool"
        elSize="small"
        initials={initials}
        fieldset={fieldlist}
        onSubmit={onSubmit}
        modalTitle={formatMessage({ id: 'security.newRole' })}
        buttonLabel={formatMessage({ id: 'security.newRole' })}
      />
    </FormStyled>
  );
};

Create.propTypes = {
  data: PropTypes.any,
};

export default Create;
