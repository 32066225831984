import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { boolean, object, string } from 'yup';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { SERVICE_TYPE, Service } from 'store/models/serviceProfile';
import { Select } from '@athonet/ui/components/Input/Select';
import { Switch } from '@athonet/ui/components/Input/Switch';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { useDispatch } from 'react-redux';
import { createService, editService } from 'store/actions/serviceProfiles';
import { useNodeEntitySelector } from 'store/selectors/nodes';
import { Text } from '@athonet/ui/components/Guidelines/Text';

export function EditService({
  serviceProfileId,
  sliceId,
  service,
}: {
  service?: Service;
  sliceId: string;
  serviceProfileId: string;
}) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const nodeEntity = useNodeEntitySelector();

  const validationSchema = useMemo(() => {
    return object().shape({
      name: string().required(),
      type: string().required().oneOf(Object.values(SERVICE_TYPE)),
      default: boolean().required(),
    });
  }, []);

  const initials = useMemo(
    () => ({
      name: service?.name || '',
      type: service?.type || '',
      default: service?.default || false,
    }),
    [service]
  );

  const handleCreateService = useCallback(
    async (values) => {
      if (!nodeEntity.data) {
        return;
      }

      setLoading(true);
      const errorRes = service
        ? await dispatch(
            editService({
              nodeId: nodeEntity.data.id,
              serviceProfileId,
              sliceId,
              serviceId: service.id,
              data: values,
            })
          )
        : await dispatch(
            createService({
              nodeId: nodeEntity.data.id,
              serviceProfileId,
              sliceId,
              data: values,
            })
          );
      setLoading(false);

      if (!errorRes) {
        dialogClose();
      } else {
        setError(errorRes);
      }
    },
    [dialogClose, dispatch, nodeEntity.data, service, serviceProfileId, sliceId]
  );

  return (
    <Formik
      initialValues={initials}
      enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
      onSubmit={handleCreateService}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ errors, values, setFieldValue, touched }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <DialogContent>
              <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
                <Field name="name" key="name">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      required
                      fullWidth
                      {...field}
                      size="small"
                      label={formatMessage({ id: 'serviceProfiles.services.form.name' })}
                      placeholder={formatMessage({ id: 'serviceProfiles.services.form.name.placeholder' })}
                      error={Boolean(errors['name'] && touched['name'])}
                      {...(Boolean(errors['name'] && touched['name']) && {
                        helperText: formatMessage({ id: 'serviceProfiles.services.form.name.error' }),
                      })}
                    />
                  )}
                </Field>
                <Field name="type" key="type">
                  {({ field }: FieldProps<string>) => (
                    <Select
                      required
                      fullWidth
                      {...field}
                      size="small"
                      label={formatMessage({ id: 'serviceProfiles.services.form.type' })}
                      error={Boolean(errors['type'] && touched['type'])}
                      {...(Boolean(errors['type'] && touched['type']) && {
                        helperText: formatMessage({ id: 'serviceProfiles.services.form.type.error' }),
                      })}
                    >
                      <MenuItem value={SERVICE_TYPE.DATA_TRAFFIC}>
                        {formatMessage({ id: `serviceProfiles.services.form.type.${SERVICE_TYPE.DATA_TRAFFIC}` })}
                      </MenuItem>
                      <MenuItem value={SERVICE_TYPE.VOICE_SERVICES}>
                        {formatMessage({ id: `serviceProfiles.services.form.type.${SERVICE_TYPE.VOICE_SERVICES}` })}
                      </MenuItem>
                      <MenuItem value={SERVICE_TYPE.MC_PTT}>
                        {formatMessage({ id: `serviceProfiles.services.form.type.${SERVICE_TYPE.MC_PTT}` })}
                      </MenuItem>
                    </Select>
                  )}
                </Field>
                <Field name="default" key="default">
                  {({ field }: FieldProps<boolean>) => (
                    <Stack fullWidth direction="row" align="center" justify="flex-start">
                      <Text>{formatMessage({ id: 'serviceProfiles.services.form.default' })}</Text>
                      <Switch
                        {...field}
                        defaultChecked={field.value}
                        onChange={(e) => setFieldValue('default', e.target.checked)}
                      />
                    </Stack>
                  )}
                </Field>
              </Stack>
            </DialogContent>
            {error && (
              <Box sx={{ mx: 2 }}>
                <Alert
                  severity="error"
                  title={formatMessage({ id: 'common.error' })}
                  message={
                    service
                      ? formatMessage({ id: 'serviceProfiles.services.edit.error.title' }, { error })
                      : formatMessage({ id: 'serviceProfiles.services.create.error.title' }, { error })
                  }
                />
              </Box>
            )}
            <DialogActions>
              <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                <Button
                  variant="outlined"
                  data-testid="footer-cancel"
                  onClick={dialogClose}
                  text={formatMessage({ id: 'common.form.cancel' })}
                  loading={loading}
                />
                <Button
                  data-testid="footer-continue"
                  type="submit"
                  text={
                    error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })
                  }
                  loading={loading}
                />
              </Stack>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
