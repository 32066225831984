// React
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// External Components
import { object, string, number } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';

// Utils
import { T_PERMISSIONS } from 'utils/permissionCodes';

// Style
import { FormStyled } from '../styled';

const Edit = ({ data, dataOptions, isOpen, onClose, onEdit, checkPermissions }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));
  }, [data]);

  const schema = object().shape({
    name: string().trim().required(),
    ue_ambr_ul: number().min(1),
    ue_ambr_dl: number().min(1),
    //charging_characteristics: number().min(1),
    roaming_allowed: number().min(0).max(1),
    //subscription_data_flags: number().min(1),
    s6a_nam: number().min(2).max(3),
    s6dgr_nam: number().min(1).max(3),
    regional_subscriptions_profile_id: string(),
  });
  const initials = {
    name: data?.name,
    //plmns_id:  data?.plmns_id,
    //csgs_lists_id:  data?.csgs_lists_id,
    //teleservices: data?.teleservices,
    ue_ambr_ul: data?.ue_ambr_ul,
    ue_ambr_dl: data?.ue_ambr_dl,
    //charging_characteristics:  data?.charging_characteristics,
    roaming_allowed: data?.roaming_allowed,
    //subscription_data_flags:  data?.subscription_data_flags,
    s6a_nam: data?.s6a_nam,
    s6dgr_nam: data?.s6dgr_nam,
    regional_subscriptions_profile_id: data?.regional_subscriptions_profile
      ? data?.regional_subscriptions_profile.id
      : '',
  };
  const RoamingAllowedOptions = [
    { value: 1, label: formatMessage({ id: 'common.form.allowed' }) },
    { value: 0, label: formatMessage({ id: 'common.form.notAllowed' }) },
  ];
  const s6aNamOptions = [
    { value: 2, label: formatMessage({ id: 'usimProfiles.form.usimProfile.s6a_nam.OnlyPacket' }) },
    { value: 3, label: formatMessage({ id: 'usimProfiles.form.usimProfile.s6a_nam.PacketAndCircuit' }) },
  ];
  const s6dgrNamOptions = [
    { value: 1, label: formatMessage({ id: 'usimProfiles.form.usimProfile.s6dgr_nam.OnlyCircuit' }) },
    { value: 2, label: formatMessage({ id: 'usimProfiles.form.usimProfile.s6dgr_nam.OnlyPacket' }) },
    { value: 3, label: formatMessage({ id: 'usimProfiles.form.usimProfile.s6dgr_nam.PacketAndCircuit' }) },
  ];

  const fieldset = [
    {
      title: formatMessage({ id: 'usimProfiles.form.usimProfile.data' }),
      data: [
        {
          name: 'name',
          label: formatMessage({ id: 'usimProfiles.form.usimProfile.name' }),
          placeholder: formatMessage({ id: 'usimProfiles.form.usimProfile.name.placeholder' }),
          error: formatMessage({ id: 'usimProfiles.form.usimProfile.name.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'ue_ambr_ul',
          label: formatMessage({ id: 'usimProfiles.form.usimProfile.ue_ambr_ul' }),
          placeholder: formatMessage({ id: 'usimProfiles.form.usimProfile.ue_ambr_ul.placeholder' }),
          error: formatMessage({ id: 'usimProfiles.form.usimProfile.ue_ambr_ul.error' }),
          type: 'number',
        },
        {
          name: 'ue_ambr_dl',
          label: formatMessage({ id: 'usimProfiles.form.usimProfile.ue_ambr_dl' }),
          placeholder: formatMessage({ id: 'usimProfiles.form.usimProfile.ue_ambr_dl.placeholder' }),
          error: formatMessage({ id: 'usimProfiles.form.usimProfile.ue_ambr_dl.error' }),
          type: 'number',
        },
        /*{
          name: "charging_characteristics",
          label: formatMessage({id:"usimProfiles.form.usimProfile.charging_characteristics"}),
          placeholder: formatMessage({id:"usimProfiles.form.usimProfile.charging_characteristics.placeholder"}),
          error: formatMessage({id:"usimProfiles.form.usimProfile.charging_characteristics.error"}),
          type: "number",
          disabled: true
        },
        {
          name: "subscription_data_flags",
          label: formatMessage({id:"usimProfiles.form.usimProfile.subscription_data_flags"}),
          placeholder: formatMessage({id:"usimProfiles.form.usimProfile.subscription_data_flags.placeholder"}),
          error: formatMessage({id:"usimProfiles.form.usimProfile.subscription_data_flags.error"}),
          type: "number",
          disabled: true
        },*/
        {
          name: 's6a_nam',
          label: formatMessage({ id: 'usimProfiles.form.usimProfile.s6a_nam' }),
          options: s6aNamOptions,
        },
        {
          name: 's6dgr_nam',
          label: formatMessage({ id: 'usimProfiles.form.usimProfile.s6dgr_nam' }),
          options: s6dgrNamOptions,
        },
        {
          name: 'roaming_allowed',
          label: formatMessage({ id: 'usimProfiles.form.usimProfile.roaming_allowed' }),
          options: RoamingAllowedOptions,
          gridClassName: 'fullwidth',
        },
        {
          name: 'regional_subscriptions_profile_id',
          label: formatMessage({ id: 'usimProfiles.form.usimProfile.regional_subscriptions_profile_id' }),
          placeholder: formatMessage({
            id: 'usimProfiles.form.usimProfile.regional_subscriptions_profile_id.placeholder',
          }),
          error: formatMessage({ id: 'usimProfiles.form.usimProfile.regional_subscriptions_profile_id.error' }),
          options: [
            {
              value: '',
              label: formatMessage({
                id: 'usimProfiles.form.usimProfile.regional_subscriptions_profile_id.not_set',
              }),
            },
          ].concat(dataOptions.regional_subscriptions_profiles),
          gridClassName: 'fullwidth',
          permissions: [T_PERMISSIONS.MASTER],
        },
      ],
    },
    /*{
      title: formatMessage({id:"usimProfiles.form.usimProfile.segments"}),
      data: [
        {
          name: "default_apn_profile_id",
          label: formatMessage({id:"usimProfiles.form.usimProfile.default_apn_profile_id"}),
          placeholder: formatMessage({id:"usimProfiles.form.usimProfile.default_apn_profile_id.placeholder"}),
          error: formatMessage({id:"usimProfiles.form.usimProfile.default_apn_profile_id.error"}),
          // NOTE: CC-1285
          options: dataOptions.segments,
          disabled: true,
          gridClassName: "fullwidth",
        }
      ]
    }*/
  ];

  return (
    <FormStyled>
      <Base
        schema={schema}
        isOpen={isOpen}
        onClose={onClose}
        initials={initials}
        fieldset={fieldlist}
        onSubmit={onEdit}
        modalTitle={formatMessage({ id: 'usimProfiles.actions.edit.title' })}
      />
    </FormStyled>
  );
};

Edit.propTypes = {
  data: PropTypes.any,
  options: PropTypes.any,
};

export default Edit;
