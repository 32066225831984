import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { InputAdornment } from '@material-ui/core';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { imsiExactRegExp, msisdnRegExp, MSISDN_PADDING, SUPI_PADDING } from 'store/models/usim';
import { object, string } from 'yup';
import { CreateUsim5gStepProps } from '../CreateUsimCard5gContent';

export function Step2({ onCompleteStep, initialValues }: CreateUsim5gStepProps) {
  const { formatMessage } = useIntl();

  const validationSchema = useMemo(() => {
    return object().shape({
      name: string().required(),
      supi: string().matches(imsiExactRegExp).required(),
      msisdn: string().matches(msisdnRegExp).required(),
    });
  }, []);

  const getInputTextsError = useCallback(
    (inputname, errors, touched) => ({
      label: formatMessage({ id: `usims.form.usim.${inputname}` }),
      placeholder: formatMessage({ id: `usims.form.usim.${inputname}.placeholder` }),
      error: Boolean(errors[inputname] && touched[inputname]),
      ...(Boolean(errors[inputname] && touched[inputname]) && {
        helperText: formatMessage({ id: `usims.form.usim.${inputname}.error` }),
      }),
    }),

    [formatMessage]
  );

  const handleSubmit = useCallback(
    (values) => {
      onCompleteStep(1, values);
    },
    [onCompleteStep]
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ errors, touched }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Stack spacing={2} align="flex-end">
              <Field name="name" key="name">
                {({ field }: FieldProps<string>) => (
                  <TextField
                    required
                    fullWidth
                    {...field}
                    size="small"
                    {...getInputTextsError('name', errors, touched)}
                  />
                )}
              </Field>
              <Field name="supi" key="supi">
                {({ field }: FieldProps<string>) => (
                  <TextField
                    required
                    fullWidth
                    {...field}
                    size="small"
                    {...getInputTextsError('supi', errors, touched)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{SUPI_PADDING}</InputAdornment>,
                    }}
                  />
                )}
              </Field>
              <Field name="msisdn" key="msisdn">
                {({ field }: FieldProps<string>) => (
                  <TextField
                    required
                    fullWidth
                    {...field}
                    size="small"
                    {...getInputTextsError('msisdn', errors, touched)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{MSISDN_PADDING}</InputAdornment>,
                    }}
                  />
                )}
              </Field>
              <Button type="submit" variant="outlined" text={formatMessage({ id: 'common.form.next' })} />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
