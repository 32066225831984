// React
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// External Components
import { object, string, number } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';

// Style
import { FormStyled } from '../styled';

const Edit = ({ data, isOpen, onClose, onEdit, checkPermissions }) => {
  const { formatMessage } = useIntl();
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    setFieldlist(checkPermissions(fieldset));
  }, [data]);

  const schema = object().shape({
    name: string().trim().required(),
    qci: number().required(),
    alert_reason: number().required(),
    priority: number().min(1).max(15).required(),
    preemption_capability: number().min(0).max(1).required(),
    preemption_vulnerability: number().min(0).max(1).required(),
    ambr_ul: number().min(1).required(),
    ambr_dl: number().min(1).required(),
    vplmn_dynamic_address_allowed: number().min(0).max(1).required(),
  });
  const qciOptions = [
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 65, label: '65' },
    { value: 66, label: '66' },
    { value: 69, label: '69' },
    { value: 70, label: '70' },
  ];
  const preemptionCapabilityOptions = [
    { value: 0, label: formatMessage({ id: 'common.form.enabled' }) },
    { value: 1, label: formatMessage({ id: 'common.form.disabled' }) },
  ];
  const preemptionVulnerabilityOptions = [
    { value: 0, label: formatMessage({ id: 'common.form.enabled' }) },
    { value: 1, label: formatMessage({ id: 'common.form.disabled' }) },
  ];
  const VPLMNDynamicAddressOptions = [
    { value: 1, label: formatMessage({ id: 'common.form.allowed' }) },
    { value: 0, label: formatMessage({ id: 'common.form.notAllowed' }) },
  ];

  const fieldset = [
    {
      title: formatMessage({ id: 'segments.form.qosProfile.data' }),
      data: [
        {
          name: 'name',
          label: formatMessage({ id: 'segments.form.qosProfile.name' }),
          placeholder: formatMessage({ id: 'segments.form.qosProfile.name.placeholder' }),
          error: formatMessage({ id: 'segments.form.qosProfile.name.error' }),
          gridClassName: 'fullwidth',
        },
        {
          name: 'qci',
          label: formatMessage({ id: 'segments.form.qosProfile.qci' }),
          options: qciOptions,
        },
        {
          name: 'vplmn_dynamic_address_allowed',
          label: formatMessage({ id: 'segments.form.qosProfile.vplmn_dynamic_address_allowed' }),
          options: VPLMNDynamicAddressOptions,
        },
        /*{
          name: "alert_reason",
          label: formatMessage({id:"segments.form.qosProfile.alert_reason"}),
          placeholder: formatMessage({id:"segments.form.qosProfile.alert_reason.placeholder"}),
          error: formatMessage({id:"segments.form.qosProfile.alert_reason.error"}),
        },*/
        {
          name: 'ambr_ul',
          label: formatMessage({ id: 'segments.form.qosProfile.ambr_ul' }),
          placeholder: formatMessage({ id: 'segments.form.qosProfile.ambr_ul.placeholder' }),
          error: formatMessage({ id: 'segments.form.qosProfile.ambr_ul.error' }),
          type: 'number',
        },
        {
          name: 'ambr_dl',
          label: formatMessage({ id: 'segments.form.qosProfile.ambr_dl' }),
          placeholder: formatMessage({ id: 'segments.form.qosProfile.ambr_dl.placeholder' }),
          error: formatMessage({ id: 'segments.form.qosProfile.ambr_dl.error' }),
          type: 'number',
        },
      ],
    },
    {
      title: formatMessage({ id: 'segments.form.qosProfile.arp' }),
      data: [
        {
          name: 'priority',
          label: formatMessage({ id: 'segments.form.qosProfile.priority' }),
          placeholder: formatMessage({ id: 'segments.form.qosProfile.priority.placeholder' }),
          error: formatMessage({ id: 'segments.form.qosProfile.priority.error' }),
          gridClassName: 'fullwidth',
          type: 'number',
        },
        {
          name: 'preemption_capability',
          label: formatMessage({ id: 'segments.form.qosProfile.preemption_capability' }),
          options: preemptionCapabilityOptions,
        },
        {
          name: 'preemption_vulnerability',
          label: formatMessage({ id: 'segments.form.qosProfile.preemption_vulnerability' }),
          options: preemptionVulnerabilityOptions,
        },
      ],
    },
  ];

  return (
    <FormStyled>
      <Base
        schema={schema}
        isOpen={isOpen}
        onClose={onClose}
        initials={data}
        fieldset={fieldlist}
        onSubmit={onEdit}
        modalTitle={formatMessage({ id: 'qosProfiles.actions.edit.title' })}
      />
    </FormStyled>
  );
};

Edit.propTypes = {
  data: PropTypes.any,
  options: PropTypes.any,
};

export default Edit;
