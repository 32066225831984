// React
import { useEffect, useState } from 'react';

// External Components
import { object, string, boolean } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';
import { Upload, UploadSchema, UploadErrorMessage } from 'components/Form/Upload';

// Style
import { FormStyled } from '../styled';

const UploadOrder = ({ isOpen, onClose, data, disabled, onUpload }) => {
  const { formatMessage } = useIntl();
  const [gpgDisabled, setGpgDisabled] = useState(true);
  const [fieldlist, setFieldlist] = useState();

  useEffect(() => {
    setFieldlist(fieldset);
  }, [, gpgDisabled]);

  const toggleGpg = (setFieldValue, val) => {
    setGpgDisabled(val.target.value === false);
  };

  const schema = object().shape({
    upload: UploadSchema(),
    encrypted: boolean().required(),
    key_id: string().when('encrypted', {
      is: true,
      then: (fieldSchema) => fieldSchema.required(),
    }),
  });

  const initials = {
    upload: [],
    encrypted: false,
    key_id: '',
  };

  const fieldset = [
    {
      title: formatMessage({ id: 'usims.orders.form.order.upload.settings' }),
      data: [
        {
          name: 'encrypted',
          label: formatMessage({ id: 'usims.orders.form.order.upload.encryptionLevel' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.upload.encryptionLevel.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.upload.encryptionLevel.error' }),
          gridClassName: 'fullwidth',
          options: [
            { value: false, label: formatMessage({ id: 'usims.orders.form.order.upload.plainText' }) },
            { value: true, label: formatMessage({ id: 'usims.orders.form.order.upload.encrypted' }) },
          ],
          onChange: toggleGpg,
        },
        {
          name: 'key_id',
          label: formatMessage({ id: 'usims.orders.form.order.upload.gpgkey' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.upload.gpgkey.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.upload.gpgkey.error' }),
          gridClassName: 'fullwidth',
          options: data.private_keys,
          disabled: gpgDisabled,
        },
      ],
    },
    {
      title: formatMessage({ id: 'usims.orders.form.order.upload.subtitle' }),
      data: [
        {
          name: 'upload',
          fieldComponent: Upload,
          label: formatMessage({ id: 'usims.orders.form.order.upload.title' }),
          error: UploadErrorMessage,
          gridClassName: 'fullwidth',
        },
      ],
    },
  ];

  return (
    <FormStyled>
      <Base
        elType="button"
        elSize="small"
        elIcon={'Cloud-Upload'}
        elLabel={formatMessage({ id: 'usims.orders.form.order.upload' })}
        isOpen={isOpen}
        onClose={onClose}
        schema={schema}
        initials={initials}
        fieldset={fieldlist}
        onSubmit={onUpload}
        modalTitle={formatMessage({ id: 'usims.orders.form.order.upload.title' })}
        elDisabled={disabled}
      />
    </FormStyled>
  );
};

export default UploadOrder;
