import { GetResultsRes } from 'store/models/filters';
import { UDRNetworkElement } from './../models/node';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { HSSNetworkElement, NodeTenantSite, TimHSSNetworkElement } from '../models/node';
import { AxiosRequestConfig, AxiosError } from 'axios';
import config from 'config';
import { Node } from 'store/models/node';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { getTenantsByNode } from './tenants';
import { parseError } from 'utils/errorString';
import { NavigateFunction } from 'react-router-dom';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { forwardGeolocate } from '@athonet/ui/utils/mapbox';
import { SERVICE_TYPE } from 'store/models/serviceProfile';
import { createServiceProfile } from './serviceProfiles';
import { createPlmn } from './plmns';
import { sentryLogError } from 'sentry';
import { getBulkOperations, showOperationScheduledToast } from './bulkOperations';

export enum NODES_ACTION_TYPE {
  FILTERS_SET = 'NODES_FILTERS_SET',
  SORT_SET = 'NODES_SORT_SET',
  LIST_LOADING = 'NODES_LIST_LOADING',
  LIST_SUCCESS = 'NODES_LIST_SUCCESS',
  LIST_FAILURE = 'NODES_LIST_FAILURE',
  LIST_CLEAR = 'NODES_LIST_CLEAR',
  ENTITY_LOADING = 'NODE_ENTITY_LOADING',
  ENTITY_SUCCESS = 'NODE_ENTITY_SUCCESS',
  ENTITY_FAILURE = 'NODE_ENTITY_FAILURE',
  ENTITY_RESET = 'NODE_ENTITY_RESET',
  RESET = 'NODES_RESET',
}

export type EditNodeValues<F extends keyof Node> = Partial<Pick<Node, F>>;

export function filtersSet(payload: unknown) {
  return {
    type: NODES_ACTION_TYPE.FILTERS_SET,
    payload,
  };
}

export function sortSet(payload: unknown) {
  return {
    type: NODES_ACTION_TYPE.SORT_SET,
    payload,
  };
}

export function listLoading() {
  return {
    type: NODES_ACTION_TYPE.LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: NODES_ACTION_TYPE.LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: NODES_ACTION_TYPE.LIST_FAILURE,
  };
}

export function listClear() {
  return {
    type: NODES_ACTION_TYPE.LIST_CLEAR,
  };
}

export function getNode(id: Node['id'], refresh: boolean = false): Thunk<Promise<void>> {
  return async (dispatch) => {
    if (!refresh) {
      dispatch({
        type: NODES_ACTION_TYPE.ENTITY_LOADING,
        payload: {
          id,
        },
      });
    }

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getNode.replace('{id}', id),
      };

      const tenantsSitesOptions = {
        url: config.apis.getNodeTenants.replace('{node_id}', id),
      };

      const [node, owners, sites] = await Promise.all([
        dispatch(fetchData<Node>(options)),
        dispatch(getTenantsByNode(id)),
        dispatch(fetchData<NodeTenantSite[]>(tenantsSitesOptions)),
      ]);

      dispatch({
        type: NODES_ACTION_TYPE.ENTITY_SUCCESS,
        payload: { ...node, owners, sites },
      });
    } catch (e) {
      dispatch({
        type: NODES_ACTION_TYPE.ENTITY_FAILURE,
        payload: {
          id,
        },
      });
      dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    }
  };
}

type CreateNodePayload = { display_name: string; tenant: AutocompleteItemProps | null; product_version: string | null };
export function createNode(values: CreateNodePayload): Thunk<Promise<{ id: string | null; error: string | null }>> {
  return async (dispatch) => {
    if (!values.tenant?.value || !values.product_version) {
      throw new Error('invalid node');
    }
    const options: AxiosRequestConfig = {
      url: config.apis.createNode,
      method: 'POST',

      data: {
        name: values.display_name,
        display_name: values.display_name,
        tenant_id: values.tenant.value,
        product_version: values.product_version,
      },
    };
    try {
      const { id } = await dispatch(fetchData<{ id: string }>(options));

      return { id, error: null };
    } catch (e) {
      sentryLogError(e);
      const error = e as AxiosError;

      return { error: parseError(error), id: null };
    }
  };
}

export function createSampleServiceProfile(nodeId: string): Thunk<Promise<string | null>> {
  return async (dispatch) => {
    const serviceProfileError = await dispatch(
      createServiceProfile({
        nodeId: nodeId,
        data: {
          name: 'Sample Service Profile',
          slices: [
            {
              name: 'Sample Slice',
              differentiator: '111111',
              services: [
                {
                  name: 'Sample Service',
                  type: SERVICE_TYPE.DATA_TRAFFIC,
                  default: true,
                },
              ],
            },
          ],
        },
      })
    );

    return serviceProfileError || null;
  };
}

export function createSamplePlmn(nodeId: string): Thunk<Promise<string | null>> {
  return async (dispatch) => {
    const plmnError = await dispatch(
      createPlmn({
        nodeId: nodeId,
        plmn: {
          name: 'Sample PLMN',
          mcc: '001',
          mnc: '01',
        },
      })
    );
    return plmnError || null;
  };
}

export function editNode(
  data: Pick<Node, 'name' | 'display_name' | 'product_version'>,
  node: Node
): Thunk<Promise<string | boolean>> {
  return async (dispatch, getState) => {
    const editData = {
      name: data.display_name,
      display_name: data.display_name,
      product_version: node.product_version,
    };

    const { nodes } = getState();

    const options: AxiosRequestConfig = {
      url: config.apis.updateNode.replace('{id}', node.id),
      method: 'PUT',
      data: editData,
    };

    try {
      await dispatch(fetchData(options));
      if (nodes.entity.data?.id) {
        await dispatch(getNode(node.id));
      }
      return false;
    } catch (e) {
      sentryLogError(e);
      const error = e as AxiosError;

      return parseError(error) || true;
    }
  };
}

async function getGeoLocation({
  address,
  country,
  accessToken,
}: {
  address: string;
  country: string;
  accessToken: string;
}) {
  try {
    const forwardedLocation = await forwardGeolocate({
      accessToken,
      query: `${address}, ${country}`,
    });

    if (!forwardedLocation) {
      return [null, null];
    }

    return forwardedLocation.center;
  } catch (e) {
    sentryLogError(e);
    return [null, null];
  }
}

type EditNode5gPayload = Pick<
  Node,
  | 'name'
  | 'display_name'
  | 'product_version'
  | 'lat'
  | 'long'
  | 'address'
  | 'country'
  | 'endpoint'
  | 'username'
  | 'password'
>;
export function editNode5g(data: EditNode5gPayload, node: Node): Thunk<Promise<string | boolean>> {
  return async (dispatch, getState) => {
    const {
      nodes: { entity },
      bootstrap,
    } = getState();

    let editData = { ...data };

    try {
      if (data.address && data.country) {
        if (!bootstrap?.mapbox_token) {
          throw new Error('Invalid Mapbox Token');
        }
        const [long, lat] = await getGeoLocation({
          address: data.address,
          country: data.country,
          accessToken: bootstrap.mapbox_token,
        });
        if (lat && long) {
          editData = { ...editData, lat, long };
        }
      } else if (data.address === null || data.country === null) {
        editData = { ...editData, lat: null, long: null };
      }

      const options: AxiosRequestConfig = {
        url: config.apis.updateNode.replace('{id}', node.id),
        method: 'PUT',
        data: editData,
      };

      await dispatch(fetchData(options));
      if (entity.data?.id) {
        await dispatch(getNode(node.id));
      }
      return false;
    } catch (e) {
      sentryLogError(e);
      const error = e as AxiosError;

      return parseError(error) || true;
    }
  };
}

export function deleteNode(nodeId: Node['id'], navigate?: NavigateFunction): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deleteNode.replace('{id}', nodeId),
      method: 'DELETE',
    };

    try {
      await dispatch(fetchData(options));
      if (navigate)
        navigate(`/networks/nodes`, {
          replace: true,
        });
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
      const error = parseError(e as AxiosError);

      dispatch(showErrorToast(error ? { message: error } : undefined));
    }
  };
}

export function createHss(values: HSSNetworkElement): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      nodes: { entity },
    } = getState();

    if (!entity.data?.id) {
      throw new Error('invalid node');
    }

    const options: AxiosRequestConfig = {
      url: config.apis.createHss,
      method: 'POST',
      data: { ...values, nodes: [entity.data.id] },
    };

    await dispatch(fetchData(options));
    await dispatch(getNode(entity.data.id, true));
  };
}

export function editHss(hssId: string, values: HSSNetworkElement): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      nodes: { entity },
    } = getState();

    if (!entity.data?.id) {
      throw new Error('invalid node');
    }

    const options: AxiosRequestConfig = {
      url: config.apis.updateHss.replace('{id}', hssId),
      method: 'PUT',
      data: { ...values, nodes: [entity.data.id] },
    };

    await dispatch(fetchData(options));
    await dispatch(getNode(entity.data.id, true));
  };
}

export function deleteHss(hssId: string): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      nodes: { entity },
    } = getState();

    try {
      if (!entity.data?.id) {
        throw new Error('invalid node');
      }

      const options: AxiosRequestConfig = {
        url: config.apis.deleteHss.replace('{id}', hssId),
        method: 'DELETE',
      };

      await dispatch(fetchData(options));
      await dispatch(getNode(entity.data.id, true));
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
    }
  };
}

export function createTimHss(values: TimHSSNetworkElement): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      nodes: { entity },
    } = getState();

    if (!entity.data?.id) {
      throw new Error('invalid node');
    }

    const options: AxiosRequestConfig = {
      url: config.apis.createTimHss,
      method: 'POST',
      data: { ...values, nodes: [entity.data.id] },
    };

    await dispatch(fetchData(options));
    await dispatch(getNode(entity.data.id, true));
  };
}

export function editTimHss(timHssId: string, values: TimHSSNetworkElement): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      nodes: { entity },
    } = getState();

    if (!entity.data?.id) {
      throw new Error('invalid node');
    }

    const options: AxiosRequestConfig = {
      url: config.apis.updateTimHss.replace('{id}', timHssId),
      method: 'PUT',
      data: { ...values, nodes: [entity.data.id] },
    };

    await dispatch(fetchData(options));
    await dispatch(getNode(entity.data.id, true));
  };
}

export function deleteTimHss(hssId: string): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      nodes: { entity },
    } = getState();

    try {
      if (!entity.data?.id) {
        throw new Error('invalid node');
      }

      const options: AxiosRequestConfig = {
        url: config.apis.deleteHss.replace('{id}', hssId),
        method: 'DELETE',
      };
      await dispatch(fetchData(options));
      await dispatch(getNode(entity.data.id));
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
    }
  };
}

export function sendDataToRemoteUDR(nodeId: Node['id']): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.sendDataToRemoteUDR.replace('{id}', nodeId),
        method: 'POST',
      };
      await dispatch(fetchData(options));
      await dispatch(showOperationScheduledToast());
      await dispatch(getBulkOperations());
      await dispatch(getNode(nodeId));
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function createNetworkElement(udrData: UDRNetworkElement): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      nodes: { entity },
    } = getState();

    try {
      if (!entity.data?.id) {
        throw new Error('invalid node');
      }
      const options: AxiosRequestConfig = {
        url: config.apis.createUdr,
        method: 'POST',
        data: { ...udrData, nodes: [entity.data.id] },
      };
      await dispatch(fetchData(options));
      await dispatch(getNode(entity.data.id));
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function getAvailableNodes(): Thunk<Promise<unknown>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getAvailableNodes,
        method: 'get',
      };
      const availableNodes = await dispatch(fetchData(options));
      return availableNodes;
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      return [];
    }
  };
}

export function getNodesOptions(platform?: string): Thunk<Promise<AutocompleteItemProps[]>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getNodes
          .replace('{sort}', 'id')
          .replace('{limit}', '1000')
          .replace('{page}', '0')
          .replace('{filters}', '')
          .replace('{platform}', platform),
      };

      const { items } = await dispatch(fetchData<GetResultsRes<Node>>(options));

      return items
        .filter(({ product_version }) => !product_version.match(/EPC/))
        .map(({ display_name, id }) => ({ label: display_name, value: id }));
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      return [];
    }
  };
}
