import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { Stepper } from '@athonet/ui/components/Navigation/Stepper';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useStepper } from '@athonet/ui/hooks/useStepper';
import { useCallback, useState } from 'react';
import { Step1 } from './Steps/Step1';
import { Step4 } from './Steps/Step4';
import { Button } from '@athonet/ui/components/Input/Button';
import { useIntl } from 'react-intl';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';
import { useDispatch } from 'react-redux';
import { createUsim5g, prepareSingleUsim5gForProvisioning } from 'store/actions/usims5g';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { MSISDN_PADDING, SUPI_PADDING } from 'store/models/usim';
import { Step5 } from './Steps/Step5';
import { useSingleUsim5gSelector } from 'store/selectors/5gProvisioning/usimCards';
import { Step6 } from './Steps/Step6';

export type CreateUsim5gStepProps = {
  onCompleteStep: (step: number, values: Record<string, unknown>) => void;
  initialValues: Record<string, string | boolean | number | AutocompleteItemProps | null>;
  active?: boolean;
};

const CREATE_USIM_STEPS = 6;
const MILESTONES_ARRAY = [3, 5];

export function CreateUsimCard5gContent() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { dialogClose } = useOverlay();
  const { formatMessage } = useIntl();

  const {
    handleGoToStep,
    active,
    skipped,
    completed,
    handleGoToNextIncompleteStep,
    handleCompleteMilestone,
    milestonesCompleted,
  } = useStepper({
    steps: CREATE_USIM_STEPS,
    milestones: MILESTONES_ARRAY,
  });
  const dispatch = useDispatch();

  const [createValues, setCreateValues] = useState<{
    name: string;
    tenant: AutocompleteItemProps | null;
    supi: string;
    msisdn: string;
    k: string;
    /* TODO: update when encryption is contemplated again */
    // encrypt: number;
    // use_default_tk: false;
    // use_key: string;
    key_type: number;
    op: string;
    opc: string;
  }>({
    name: '',
    tenant: null,
    supi: '',
    msisdn: '',
    k: '',
    /* TODO: update when encryption is contemplated again */
    // encrypt: 0,
    // use_default_tk: false,
    // use_key: '',
    key_type: 1,
    op: '',
    opc: '',
  });

  const newUsim5g = useSingleUsim5gSelector(`${SUPI_PADDING}${createValues.supi}`);

  const [prepareForProvisionValues, setPrepareForProvisionValues] = useState<{
    node_id: AutocompleteItemProps | null;
    profile_id: AutocompleteItemProps | null;
  }>({
    node_id: null,
    profile_id: null,
  });

  const onCompleteCreateStep = useCallback(
    (step, values) => {
      setCreateValues((prevState) => ({ ...prevState, ...values }));
      handleGoToNextIncompleteStep();
    },
    [handleGoToNextIncompleteStep]
  );

  const handleCreateUsim = useCallback(async () => {
    const newTenant =
      createValues['tenant'] && 'value' in createValues['tenant'] && 'label' in createValues['tenant']
        ? {
            id: String(createValues['tenant'].value),
            name: String(createValues['tenant'].label),
          }
        : null;

    if (newTenant) {
      setLoading(true);
      const errorRes = await dispatch(
        createUsim5g({
          ...createValues,
          tenant: newTenant,
          msisdn: `${MSISDN_PADDING}${createValues.msisdn}`,
          supi: `${SUPI_PADDING}${createValues.supi}`,
        })
      );
      setLoading(false);

      if (errorRes) {
        setError(errorRes);
      } else {
        setError(null);
        handleCompleteMilestone(3);
      }
    }
  }, [createValues, dispatch, handleCompleteMilestone]);

  const onCompleteStep5 = useCallback(
    (values) => {
      setPrepareForProvisionValues(values);
      handleGoToNextIncompleteStep();
    },
    [handleGoToNextIncompleteStep]
  );

  const handlePrepareForProvisioning = useCallback(async () => {
    const newNode =
      prepareForProvisionValues['node_id'] &&
      'value' in prepareForProvisionValues['node_id'] &&
      'label' in prepareForProvisionValues['node_id']
        ? String(prepareForProvisionValues['node_id'].value)
        : null;

    const newServiceProfile =
      prepareForProvisionValues['profile_id'] &&
      'value' in prepareForProvisionValues['profile_id'] &&
      'label' in prepareForProvisionValues['profile_id']
        ? String(prepareForProvisionValues['profile_id'].value)
        : null;

    if (newNode && newServiceProfile) {
      setLoading(true);
      const errorRes = await dispatch(
        prepareSingleUsim5gForProvisioning({
          node_id: newNode,
          profile_id: newServiceProfile,
          usim5g: newUsim5g,
        })
      );
      setLoading(false);
      if (!errorRes) {
        setError(null);
        handleCompleteMilestone(MILESTONES_ARRAY[1]);
      } else {
        setError(errorRes);
      }
    }
  }, [dispatch, handleCompleteMilestone, newUsim5g, prepareForProvisionValues]);

  return (
    <>
      <IconButton
        name="Close"
        fontSize="small"
        onClick={dialogClose}
        sx={{ position: 'absolute', right: '16px', top: '8px', zIndex: 100 }}
      />
      <DialogContent>
        <Stepper
          orientation="vertical"
          completed={completed}
          nonLinear
          activeStep={active}
          onStepClick={handleGoToStep}
          skipped={skipped}
          milestonesCompleted={milestonesCompleted}
        >
          <Step label={formatMessage({ id: 'usims.form.usim.ownership' })}>
            <Step1 onCompleteStep={onCompleteCreateStep} initialValues={{ tenant: createValues['tenant'] }} />
          </Step>
          <Step label={formatMessage({ id: 'usims.form.usim.idData' })}>
            <Step2
              onCompleteStep={onCompleteCreateStep}
              initialValues={{
                name: createValues['name'],
                supi: createValues['supi'],
                msisdn: createValues['msisdn'],
              }}
            />
          </Step>
          <Step label={formatMessage({ id: 'usims.form.usim.keys' })}>
            <Step3
              onCompleteStep={onCompleteCreateStep}
              initialValues={{
                k: createValues['k'],
                /* TODO: update when encryption is contemplated again */
                // encrypt: createValues['encrypt'],
                // use_default_tk: createValues['use_default_tk'],
                // use_key: createValues['use_key'],
                key_type: createValues['key_type'],
                op: createValues['op'],
                opc: createValues['opc'],
              }}
            />
          </Step>
          <Step label={formatMessage({ id: 'usims.form.usim.creation' })} checkpoint>
            {milestonesCompleted.has(3) ? (
              <>
                <Alert
                  severity="success"
                  title={formatMessage({ id: 'usims.create.success.title' })}
                  message={formatMessage({ id: 'usims.create.success.message' })}
                />
                <Stack spacing={2} direction="row" justify="flex-end" sx={{ pt: 2 }}>
                  <Button variant="text" text="Skip preparation" onClick={dialogClose} />
                  <Button variant="outlined" text="Prepare for provisioning" onClick={handleGoToNextIncompleteStep} />
                </Stack>
              </>
            ) : (
              <Step4 values={createValues} errors={{}}>
                {error && (
                  <Box sx={{ width: '100%' }}>
                    <Alert
                      severity="error"
                      title={formatMessage({ id: 'common.error' })}
                      message={formatMessage({ id: 'usims.create.error.title' }, { error })}
                    />
                  </Box>
                )}
                <Button
                  text={
                    error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })
                  }
                  loading={loading}
                  onClick={() => void handleCreateUsim()}
                />
              </Step4>
            )}
          </Step>

          <Step label={formatMessage({ id: 'usims.form.usim.prepare.title' })}>
            <Step5
              onCompleteStep={onCompleteStep5}
              initialValues={{
                node: prepareForProvisionValues['node_id'],
              }}
            />
          </Step>
          <Step label={formatMessage({ id: 'usims.form.usim.confirmPrepare' })} checkpoint>
            <Step6
              milestoneCompleted={milestonesCompleted.has(MILESTONES_ARRAY[1])}
              values={{
                node: prepareForProvisionValues['node_id']?.label,
                profile: prepareForProvisionValues['profile_id']?.label,
              }}
              error={error}
              onCompleteStep={() => void handlePrepareForProvisioning()}
              loading={loading}
            />
          </Step>
        </Stepper>
      </DialogContent>
    </>
  );
}
