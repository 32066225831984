export enum USIM5G_STATUS {
  DEPROVISIONED = 'deprovisioned',
  PROVISIONED = 'provisioned',
}

export type Usim5g = {
  id: string;
  created_at: string;
  encrypt: number;
  supi: string; // imsi regexp
  msisdn: string; // 1-15 digits regexp
  k: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  name: string;
  op: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  opc: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  tenant: {
    id: string;
    name: string;
  };
  updated_at: string;
  use_key: string | null;
  node_id: string | null;
  status: USIM5G_STATUS | null;
  node_name: string | null;
  profile_id: string | null;
  profile_name: string | null;
};

export type Usim5gFields = Usim5g & {
  single: boolean;
  upload: File;
  metadata_delete: string | null;
  key_type: number;
  key_override: boolean;
  use_default_tk: boolean;
};

export type Usim5gDetails = Usim5g & {
  batch_id: string | null;
  imsi: string;
  imsi2: string | null;
  error: boolean;
  is_dual_imsi: boolean;
  iccid: string | null;
  msisdn_status: string | null;
  node_name: string | null;
  order_id: string | null;
  private_identity: string | null;
  regional_subscriptions_profile_id: string | null;
  site_id: string | null;
  tenant_id: string | null;
  type: string;
  updating: boolean;
  metadata: {};
};

// NOTE: it only works on partially selected data
// export function isDeleteActionDisabledUsim5g>['dabled'] useCallback(rowData: Usim5g[] | Record<string, unknown>) {
//   if (!Array.isArray(rowData)) {
//     return true;
//   }
//   return rowData.some((item) => item.status !== USIM5G_STATUS.DEPROVISIONED);
// }

// NOTE: it only works on partially selected data
export function isPrepareForProvisioningActionDisabled(rowData: Usim5g[] | Record<string, unknown>) {
  if (!Array.isArray(rowData)) {
    return false;
  }
  return rowData.some((item) => item.status !== USIM5G_STATUS.DEPROVISIONED);
}

export function isProvisionActionDisabled(rowData: Usim5g[] | Record<string, unknown>) {
  if (!Array.isArray(rowData)) {
    return false;
  }
  const uniqueNodeIdsSize = new Set(rowData.map((item) => item.node_id)).size;
  return rowData.some((item) => !item.node_id || !item.profile_id || uniqueNodeIdsSize > 1);
}

export function isDeprovisionActionDisabled(rowData: Usim5g[] | Record<string, unknown>) {
  if (!Array.isArray(rowData)) {
    return false;
  }
  const uniqueNodeIdsSize = new Set(rowData.map((item) => item.node_id)).size;
  return rowData.some((item) => item.status !== USIM5G_STATUS.PROVISIONED || uniqueNodeIdsSize > 1);
}
