import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { useCanUserViewCDRSelector } from 'store/selectors/user';
import { useBootstrapSelector, useIsCDREnabledSelector } from 'store/selectors/bootstrap';
import TrafficWidgets from './TrafficWidgets';
import { Provisioning4g } from './Provisioning4g';
import { Provisioning5g } from './Provisioning5g';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
// import { DeploymentOverview } from './DeploymentOverview';

export default function Landing() {
  const bootstrap = useBootstrapSelector();
  // const hasUserCPMasterTenant = useHasUserCPMasterTenantSelector();
  const canUserViewCDR = useCanUserViewCDRSelector();
  const isCDREnabled = useIsCDREnabledSelector();

  return (
    <GridContainer>
      <GridItem
        size={{
          xs: 12,
          // md: hasUserCPMasterTenant ? 8 : 12,
        }}
      >
        <GridContainer>
          {/* Network KPI */}
          {canUserViewCDR && isCDREnabled && <TrafficWidgets />}
          {/* 4G provisioning */}
          {bootstrap?.athux_support_enabled && <Provisioning4g />}
          {/* 5G provisioning */}
          {bootstrap?.athonetos_support_enabled && <Provisioning5g />}
        </GridContainer>
      </GridItem>
      {/* {hasUserCPMasterTenant && (
        <GridItem
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <DeploymentOverview />
        </GridItem>
      )} */}
    </GridContainer>
  );
}
