import { lsGet, lsSet, lsRemove } from 'utils/localStorage';
import { Thunk } from '.';
import { User } from 'store/models/user';
import { checkPermissionToUse, checkTenantType } from 'utils/permissionCodes';
import { NavigateFunction } from 'react-router-dom';

export enum LOGIN_ACTION_TYPE {
  LOGIN_USER = 'LOGIN_USER',
  LOGOUT_USER = 'LOGOUT_USER',
  UPDATE_TOKEN = 'UPDATE_TOKEN',
  AUTH_USER = 'AUTH_USER',
  SET_USER = 'AUTH_SET_USER',
}

export interface LoginAction {
  type: LOGIN_ACTION_TYPE;
  payload: User;
}

// TODO: type action payloads
export function loginUser(payload: unknown) {
  return {
    type: LOGIN_ACTION_TYPE.LOGIN_USER,
    payload,
  };
}

export function authUser(payload: unknown) {
  return {
    type: LOGIN_ACTION_TYPE.AUTH_USER,
    payload,
  };
}

export function updateToken(payload: { accessToken: User['accessToken'] }) {
  return {
    type: LOGIN_ACTION_TYPE.UPDATE_TOKEN,
    payload,
  };
}

export function logoutUser() {
  lsRemove();
  window.location.href = '/login';
  return {
    type: LOGIN_ACTION_TYPE.LOGOUT_USER,
  };
}

export function setUser(payload: User) {
  const ls = lsGet<User>();
  const { fullname, email, phone, name } = payload;
  lsSet({
    ...ls,
    fullname,
    email,
    phone,
    name,
  });
  return {
    type: LOGIN_ACTION_TYPE.SET_USER,
    payload,
  };
}

export function hydrateHuser(): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.user;
    const ls = lsGet<User>();
    if (ls) {
      if (!user?.accessToken && ls.accessToken) {
        dispatch(authUser(ls));
        dispatch(loginUser(ls));
      }
    }
  };
}

// TOOD: read from local storage instead of state.user
export function checkUserPermissions(
  navigate: NavigateFunction,
  permissions?: string[],
  tenantType?: string[]
): Thunk<
  Promise<{
    expiration_lapse: number;
  } | void>
> {
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.user;

    if (!user?.accessToken) {
      dispatch(logoutUser());
      return;
    }

    const noTenantPermissions = Boolean(
      tenantType && tenantType.length > 0 && !checkTenantType(user?.tenant_type, tenantType)
    );
    const noRolePermissions = Boolean(
      !permissions || (permissions.length > 0 && !checkPermissionToUse(user, permissions))
    );

    if (noTenantPermissions && noRolePermissions) {
      return navigate('/unauthorized');
    }
  };
}
