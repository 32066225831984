import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { editNode } from 'store/actions/nodes';
import { showSuccessToast } from 'store/actions/toast';
import { Node } from 'store/models/node';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { object, string } from 'yup';

export function EditNode({ onSubmit, node }: { onSubmit?: () => Promise<void>; node: Node }) {
  const { dialogClose } = useOverlay();
  const bootstrap = useBootstrapSelector();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const dispatch = useDispatch();

  const initials = useMemo(
    () => ({
      name: node.name,
      display_name: node.display_name,
    }),
    [node]
  );

  const schema = useMemo(
    () =>
      object().shape({
        name: string().required(),
        display_name: string().required(),
      }),
    []
  );

  const handleEdit = useCallback(
    async (values) => {
      setLoading(true);
      const resultError = await dispatch(editNode(values, node));
      if (onSubmit) {
        await onSubmit();
      }
      setLoading(false);
      dispatch(showSuccessToast());
      if (resultError) {
        setError(typeof resultError === 'string' ? resultError : formatMessage({ id: 'common.unknownError' }));
      } else {
        setError(null);
        dispatch(showSuccessToast());
        dialogClose();
      }
    },
    [dialogClose, dispatch, formatMessage, node, onSubmit]
  );

  if (!bootstrap) return null;

  return (
    <Formik initialValues={initials} onSubmit={handleEdit} validationSchema={schema}>
      {({ isSubmitting, errors, touched }) => {
        return (
          <Form noValidate autoComplete="off">
            <Stack fullWidth sx={{ p: 2 }} spacing={2}>
              <Field name="display_name">
                {({ field }: FieldProps<string>) => (
                  <TextField
                    size="small"
                    {...field}
                    label={formatMessage({ id: 'nodes.form.name' })}
                    placeholder={formatMessage({ id: 'nodes.form.name.placeholder' })}
                    error={Boolean(errors['name'] && touched['name'])}
                    {...(Boolean(errors['name'] && touched['name']) && {
                      helperText: formatMessage({ id: 'nodes.form.name.error' }),
                    })}
                    disabled={isSubmitting}
                  />
                )}
              </Field>
              {error && (
                <Box sx={{ width: '100%' }}>
                  <Alert
                    severity="error"
                    title="Error"
                    message={formatMessage({ id: 'common.edit.error' }, { message: error })}
                  />
                </Box>
              )}
            </Stack>
            <DialogActions>
              <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                <Button
                  variant="outlined"
                  data-testid="footer-cancel"
                  onClick={dialogClose}
                  text={formatMessage({ id: 'common.form.cancel' })}
                  disabled={isSubmitting || loading}
                  loading={isSubmitting || loading}
                />
                <Button
                  data-testid="footer-continue"
                  type="submit"
                  text={formatMessage({ id: 'common.form.continue' })}
                  disabled={isSubmitting || loading}
                  loading={isSubmitting || loading}
                />
              </Stack>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
