import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import KeyValue from 'components/Details/KeyValue';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { MSISDN_PADDING, SUPI_PADDING } from 'store/models/usim';

const DefaultNullableValue = ({ field, value }: { field: string; value: unknown }) =>
  value ? <Text>{value}</Text> : <Chip id={field} size="small" label="Empty" />;

export function Step4({
  values,
  errors,
  children,
}: {
  values: Record<string, unknown>;
  errors: Record<string, unknown>;
  children: ReactNode;
}) {
  const { formatMessage } = useIntl();

  const tenantComputedValue = useMemo(
    () =>
      typeof values['tenant'] === 'object' && values['tenant'] !== null && 'label' in values['tenant']
        ? values['tenant'].label
        : null,
    [values]
  );

  const errorsString = useMemo(() => Object.keys(errors).join(' - '), [errors]);

  return (
    <Stack spacing={2} align="flex-end">
      <GridContainer>
        {errorsString && (
          <GridItem size={{ xs: 12 }}>
            <Alert severity="error" title="There seem to be errors in the following fields:" message={errorsString} />
          </GridItem>
        )}

        <GridItem size={{ xs: 12 }}>
          <Text type="h5">Please confirm the following values</Text>
        </GridItem>

        <KeyValue
          label={formatMessage({ id: 'usims.form.usim.tenant' })}
          valueComponent={<DefaultNullableValue field="name" value={tenantComputedValue} />}
        />

        <KeyValue
          label={formatMessage({ id: 'usims.form.usim.name' })}
          valueComponent={<DefaultNullableValue field="name" value={values['name']} />}
        />

        <KeyValue
          label={formatMessage({ id: 'usims.form.usim.supi' })}
          valueComponent={<DefaultNullableValue field="supi" value={`${SUPI_PADDING}${values['supi']}`} />}
        />

        <KeyValue
          label={formatMessage({ id: 'usims.form.usim.msisdn' })}
          valueComponent={<DefaultNullableValue field="msisdn" value={`${MSISDN_PADDING}${values['msisdn']}`} />}
        />

        {/* /* TODO: update when encryption is contemplated again  */}
        {/* <KeyValue
          label={formatMessage({ id: 'usims.form.usim.encrypt' })}
          value={formatMessage({
            id: `usims.form.usim.encrypt.${values['encrypt']}`,
          })}
        /> */}

        <KeyValue
          fullwidth
          label={formatMessage({ id: 'usims.form.usim.k' })}
          valueComponent={<DefaultNullableValue field="k" value={values['k']} />}
        />

        {values['encrypt'] !== 0 && (
          <KeyValue
            fullwidth
            label={formatMessage({ id: 'usims.form.usim.use_key' })}
            valueComponent={
              <DefaultNullableValue
                field="use_key"
                value={
                  values['use_default_tk']
                    ? formatMessage({ id: 'usims.form.usim.use_default_tk.true' })
                    : values['use_key']
                }
              />
            }
          />
        )}

        <KeyValue
          label={formatMessage({ id: 'usims.form.usim.key_type' })}
          value={formatMessage({
            id: `usims.form.usim.key_type.${
              values['key_type'] === 1 ? 'customOP' : values['key_type'] === 2 ? 'customOPC' : 'defaultHSSOP'
            }`,
          })}
        />

        {values['key_type'] === 1 && (
          <KeyValue
            fullwidth
            label={formatMessage({ id: 'usims.form.usim.op' })}
            valueComponent={<DefaultNullableValue field="op" value={values['op']} />}
          />
        )}

        {values['key_type'] === 2 && (
          <KeyValue
            fullwidth
            label={formatMessage({ id: 'usims.form.usim.opc' })}
            valueComponent={<DefaultNullableValue field="opc" value={values['opc']} />}
          />
        )}
      </GridContainer>
      {children}
    </Stack>
  );
}
