import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DetailPageParams } from 'Router';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { Button } from '@athonet/ui/components/Input/Button';
import { useIntl } from 'react-intl';
import { useCanUserDeleteNodeSelector, useCanUserUpdateNodeSelector } from 'store/selectors/user';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteNode, getNode, sendDataToRemoteUDR } from 'store/actions/nodes';
import { useIsNodeLegacySelector, useNodeEntitySelector } from 'store/selectors/nodes';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { DetailPageSkeleton } from 'components/Skeletons/DetailPageSkeleton';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Tabs } from '@athonet/ui/components/Navigation/Tabs';
import { Box } from '@material-ui/core';
import { reset as resetPlmns } from 'store/actions/plmns';
import { reset as resetServiceProfiles } from 'store/actions/serviceProfiles';

export enum TAB {
  DETAILS = 'details',
  SERVICE_PROFILES = 'service-profiles',
  PLMNS = 'plmns',
}

export function NodeDetailLayout() {
  const { id } = useParams<DetailPageParams>();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const nodeEntity = useNodeEntitySelector();
  const canUserDeleteNode = useCanUserDeleteNodeSelector();
  const canUserUpdateNode = useCanUserUpdateNodeSelector();
  const { confirmationSimpleDialogOpen } = useOverlay();
  const isNodeLegacy = useIsNodeLegacySelector();
  const dispatch = useDispatch();

  const location = useLocation();
  const locationPaths = location.pathname.split('/');
  const tab = locationPaths[locationPaths.length - 1];

  const [canRenderTabs, setCanRenderTabs] = useState<Boolean | null>(null);

  const entity = useMemo(() => {
    return nodeEntity.data;
  }, [nodeEntity]);

  useEffect(() => {
    void dispatch(getNode(id as string));

    return () => {
      dispatch(resetPlmns());
      dispatch(resetServiceProfiles());
    };
  }, [dispatch, id]);

  const handleOpenDelete = useCallback(() => {
    if (!entity) {
      return;
    }
    confirmationSimpleDialogOpen({
      title: formatMessage({ id: 'nodes.deleteNode' }),
      description: formatMessage({ id: 'nodes.deleteNode.confirm' }),
      onConfirm: async () => dispatch(deleteNode(entity.id, navigate)),
    });
  }, [entity, confirmationSimpleDialogOpen, formatMessage, dispatch, navigate]);

  const handleSend = useCallback(() => {
    if (!entity) {
      return;
    }
    void dispatch(sendDataToRemoteUDR(entity.id));
  }, [dispatch, entity]);

  const handleTabChange = useCallback(
    (newTab: TAB) => {
      navigate(`/networks/nodes/${id}/${newTab}`, {
        replace: true,
      });
    },
    [id, navigate]
  );

  const nodeDetailTabs = useMemo(
    () => [
      {
        value: TAB.DETAILS,
        label: formatMessage({ id: 'nodes.tabs.details' }),
      },
      {
        value: TAB.SERVICE_PROFILES,
        label: formatMessage({ id: 'nodes.tabs.serviceProfiles' }),
      },
      {
        value: TAB.PLMNS,
        label: formatMessage({ id: 'nodes.tabs.plmns' }),
      },
    ],
    [formatMessage]
  );

  useEffect(() => {
    if (isNodeLegacy) {
      setCanRenderTabs(false);

      if (tab !== TAB.DETAILS) {
        navigate(`/networks/nodes/${id}/${TAB.DETAILS}`, {
          replace: true,
        });
      }
    } else {
      setCanRenderTabs(true);
    }
  }, [id, isNodeLegacy, navigate, tab]);

  if (canRenderTabs === null) {
    return null;
  }

  return (
    <>
      <Toolbar>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          text="Back"
          variant="text"
          color="secondary"
          startIcon="Left-7"
        />
        {entity && (
          <Stack direction="row" sx={{ marginLeft: 'auto' }}>
            <Button
              text={formatMessage({ id: 'common.button.delete' })}
              color="error"
              onClick={handleOpenDelete}
              disabled={!canUserDeleteNode}
              variant="outlined"
            />
            {!isNodeLegacy && (
              <Button
                text={formatMessage({ id: 'nodes.detail.sync' })}
                onClick={handleSend}
                disabled={!canUserUpdateNode}
              />
            )}
          </Stack>
        )}
      </Toolbar>
      {!entity ? (
        <DetailPageSkeleton />
      ) : (
        <>
          {canRenderTabs && (
            <Box sx={{ mb: 2 }}>
              <Tabs
                value={tab}
                tabs={nodeDetailTabs}
                onChange={(value) => {
                  handleTabChange(value as TAB);
                }}
              />
            </Box>
          )}
          <Outlet />
        </>
      )}
    </>
  );
}
