import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { object, string, boolean, array } from 'yup';
import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';
import { Upload, UploadErrorMessage } from 'components/Form/Upload';
import { FormStyled } from '../styled';
import { createGpgKey } from 'store/actions/gpgKeys';
import { useDispatch } from 'react-redux';
import { sentryLogError } from 'sentry';

const Create = () => {
  const { formatMessage } = useIntl();
  const [passDisabled, setPassDisabled] = useState(true);
  const dispatch = useDispatch();

  const initials = useMemo(() => {
    return {
      is_private: false,
      name: '',
      passphrase: '',
      upload: [],
    };
  }, []);

  const types = useMemo(
    () => [
      { label: formatMessage({ id: 'usims.form.gpgkeys.public' }), value: false },
      { label: formatMessage({ id: 'usims.form.gpgkeys.private' }), value: true },
    ],
    []
  );

  const togglePass = (setFieldValue, val) => {
    setPassDisabled(val.target.value === false);
  };

  const fieldlist = useMemo(
    () => [
      {
        data: [
          {
            name: 'name',
            label: formatMessage({ id: 'usims.form.gpgkeys.name' }),
            placeholder: formatMessage({ id: 'usims.form.gpgkeys.name.placeholder' }),
            error: formatMessage({ id: 'usims.form.gpgkeys.name.error' }),
            gridClassName: 'fullwidth',
          },
          {
            name: 'is_private',
            label: formatMessage({ id: 'usims.form.gpgkeys.type' }),
            placeholder: formatMessage({ id: 'usims.form.gpgkeys.type.placeholder' }),
            error: formatMessage({ id: 'usims.form.gpgkeys.type.error' }),
            options: types,
            //permissions: [T_PERMISSIONS.MASTER],
            onChange: togglePass,
          },
          {
            name: 'passphrase',
            label: formatMessage({ id: 'usims.form.gpgkeys.passphrase' }),
            placeholder: formatMessage({ id: 'usims.form.gpgkeys.passphrase.placeholder' }),
            error: formatMessage({ id: 'usims.form.gpgkeys.passphrase.error' }),
            disabled: passDisabled,
          },
          {
            name: 'upload',
            fieldComponent: Upload,
            label: formatMessage({ id: 'usims.form.gpgkeys.file' }),
            error: UploadErrorMessage,
            gridClassName: 'fullwidth',
          },
        ],
      },
    ],
    [passDisabled, types, formatMessage]
  );

  const handleOnSubmit = useCallback(
    async (values, callback) => {
      try {
        await dispatch(createGpgKey(values));
        callback(true);
      } catch (e) {
        sentryLogError(e);
        callback(false);
      }
    },
    [dispatch]
  );

  const schema = useMemo(
    () =>
      object().shape({
        is_private: boolean().required(),
        name: string().required(),
        passphrase: string().when('private', {
          is: true,
          then: (fieldSchema) => fieldSchema.required(),
        }),
        upload: array().required(),
      }),
    []
  );

  return (
    <FormStyled>
      <Base
        schema={schema}
        elType="button"
        elColor="tool"
        elSize="small"
        initials={initials}
        fieldset={fieldlist}
        onSubmit={handleOnSubmit}
        modalTitle={formatMessage({ id: 'usims.form.gpgkeys.title' })}
        buttonLabel={formatMessage({ id: 'usims.form.gpgkeys.title' })}
      />
    </FormStyled>
  );
};

Create.propTypes = {
  data: PropTypes.any,
};

export default Create;
