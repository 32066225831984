import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useCdrTrafficVolumeRankingMetricOptions,
  useCdrTrafficVolumeSchema,
} from 'store/selectors/cdrReports/trafficVolume';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { Chart } from '@athonet/ui/components/Data/Chart';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import { NoData } from '@athonet/ui/components/Data/NoData';
import filesize from 'filesize';
import moment from 'moment';
import { DATA_LIFECYCLE, isEntityLoading } from 'store/reducers';
import { getTooltipDateTitle, getTotalFileSize, metricTooltipFormatter } from '../utils/utils';
import theme from '@athonet/ui/theme';
import {
  getCdrTrafficDifferenceTotals,
  getCdrTrafficDifferenceTrendSeries,
} from 'store/actions/cdrReports/trafficDifference';
import {
  CdrTrafficDifferenceSeries,
  CdrTrafficVolumeMetrics,
  CdrTrafficVolumeMetricsField,
  CDR_SOURCE_NETWORKS_AGGREGATION_TYPE,
} from 'store/models/cdr';
import {
  useCdrTrafficDifferenceDateRange,
  useCdrTrafficDifferenceFilter,
  useCdrTrafficDifferencePeriod,
  useCdrTrafficDifferenceSeries,
  useCdrTrafficDifferenceSourceNetworksAggregations,
  useCdrTrafficDifferenceTotals,
  useTrafficDifferenceSeriesByType,
  useTrafficDifferenceTotalByType,
} from 'store/selectors/cdrReports/trafficDifference';
import { Moment } from 'moment';
import { Select, SelectChangeEvent } from '@athonet/ui/components/Input/Select';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { ResponsiveControls } from '@athonet/ui/components/Navigation/ResponsiveControls';
import TrendChart from '../TrendChart';
import DifferenceTrendChart from './DifferenceTrendChart';
import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';

const differencePaletteColors = {
  difference: theme.palette.series6.main,
  mvno: theme.palette.series2.main,
  mno: theme.palette.series3.main,
};

export function TrafficDifferenceTrend() {
  const dispatch = useDispatch();
  const trafficVolumeSchema = useCdrTrafficVolumeSchema();
  const trafficDifferenceDateRange = useCdrTrafficDifferenceDateRange();
  const trafficDifferencePeriod = useCdrTrafficDifferencePeriod();
  const trafficDifferenceFilter = useCdrTrafficDifferenceFilter();
  const trafficDifferenceTotals = useCdrTrafficDifferenceTotals();
  const metricOptions = useCdrTrafficVolumeRankingMetricOptions();
  const trafficDifferenceTotalsDIFFERENCE = useTrafficDifferenceTotalByType(
    CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.DIFFERENCE
  );
  const trafficDifferenceTotalsMVNO = useTrafficDifferenceTotalByType(CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.MVNO);
  const trafficDifferenceTotalsMNO = useTrafficDifferenceTotalByType(CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.MNO);
  const trafficDifferenceSeries = useCdrTrafficDifferenceSeries();
  const trafficDifferenceSeriesDIFFERENCE = useTrafficDifferenceSeriesByType(
    CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.DIFFERENCE
  );
  const trafficDifferenceSeriesMVNO = useTrafficDifferenceSeriesByType(CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.MVNO);
  const trafficDifferenceSeriesMNO = useTrafficDifferenceSeriesByType(CDR_SOURCE_NETWORKS_AGGREGATION_TYPE.MNO);
  const cdrTrafficDifferenceSourceNetworksAggregations = useCdrTrafficDifferenceSourceNetworksAggregations();

  const [metric, setMetric] = useState<CdrTrafficVolumeMetricsField>('totallink');

  const tooltipDateTitle = useMemo(() => {
    return getTooltipDateTitle(trafficDifferencePeriod);
  }, [trafficDifferencePeriod]);

  const tooltipFormatter = useCallback(
    (params: any, conditionalParam?: (param: any) => boolean) => {
      return metricTooltipFormatter(params, tooltipDateTitle, 'fileSize', conditionalParam);
    },

    [tooltipDateTitle]
  );

  useEffect(() => {
    if (
      !trafficDifferenceDateRange ||
      !trafficDifferenceDateRange.start ||
      !trafficDifferenceDateRange.end ||
      !cdrTrafficDifferenceSourceNetworksAggregations
    ) {
      return;
    }

    dispatch(
      getCdrTrafficDifferenceTotals({
        start_date: trafficDifferenceDateRange.start,
        end_date: trafficDifferenceDateRange.end,
        period: trafficDifferencePeriod,
        group_by: trafficDifferenceFilter.group_by || undefined,
        group_filter: trafficDifferenceFilter.group_filter || undefined,
        sourceNetworkAggregations: cdrTrafficDifferenceSourceNetworksAggregations,
      })
    );
    dispatch(
      getCdrTrafficDifferenceTrendSeries({
        start_date: trafficDifferenceDateRange.start,
        end_date: trafficDifferenceDateRange.end,
        period: trafficDifferencePeriod,
        group_by: trafficDifferenceFilter.group_by || undefined,
        group_filter: trafficDifferenceFilter.group_filter || undefined,
        sourceNetworkAggregations: cdrTrafficDifferenceSourceNetworksAggregations,
      })
    );
  }, [
    cdrTrafficDifferenceSourceNetworksAggregations,
    dispatch,
    trafficDifferenceDateRange,
    trafficDifferenceFilter.group_by,
    trafficDifferenceFilter.group_filter,
    trafficDifferencePeriod,
  ]);

  const totalFileSize = useMemo(() => {
    if (!trafficDifferenceTotalsDIFFERENCE || !trafficDifferenceTotalsMVNO || !trafficDifferenceTotalsMNO) {
      return;
    }
    const fileSizes = {
      difference: getTotalFileSize(trafficDifferenceTotalsDIFFERENCE.total),
      mvno: getTotalFileSize(trafficDifferenceTotalsMVNO.total),
      mno: getTotalFileSize(trafficDifferenceTotalsMNO.total),
    };
    return fileSizes;
  }, [trafficDifferenceTotalsDIFFERENCE, trafficDifferenceTotalsMNO, trafficDifferenceTotalsMVNO]);

  const getDifferenceTrendSeries = useCallback(
    (trendSeries: CdrTrafficDifferenceSeries<number | null, Moment>['series']) => {
      const series: CdrTrafficVolumeMetrics<[string, number | string][]> = {
        totallink: [],
        uplink: [],
        downlink: [],
      };
      trendSeries.forEach((item) => {
        const datetime = item.datetime.toString();
        series.totallink.push([datetime, item.totallink !== null ? item.totallink : '-']);
        series.downlink.push([datetime, item.downlink !== null ? item.downlink : '-']);
        series.uplink.push([datetime, item.uplink !== null ? item.uplink : '-']);
      }, []);
      return series;
    },
    []
  );

  const seriesData = useMemo(() => {
    if (!trafficDifferenceSeriesDIFFERENCE || !trafficDifferenceSeriesMVNO || !trafficDifferenceSeriesMNO) {
      return;
    }

    const series = {
      difference: getDifferenceTrendSeries(trafficDifferenceSeriesDIFFERENCE.series),
      mvno: getDifferenceTrendSeries(trafficDifferenceSeriesMVNO.series),
      mno: getDifferenceTrendSeries(trafficDifferenceSeriesMNO.series),
    };
    return series;
  }, [
    getDifferenceTrendSeries,
    trafficDifferenceSeriesDIFFERENCE,
    trafficDifferenceSeriesMNO,
    trafficDifferenceSeriesMVNO,
  ]);

  const handleMetricChange = useCallback(
    (e: SelectChangeEvent) => {
      setMetric(e.target.value as CdrTrafficVolumeMetricsField);
    },
    [setMetric]
  );

  const negativeDifference = useMemo(
    () =>
      trafficDifferenceTotalsDIFFERENCE?.total?.totallink && trafficDifferenceTotalsDIFFERENCE?.total?.totallink < 0,
    [trafficDifferenceTotalsDIFFERENCE?.total?.totallink]
  );

  // TODO: handle data error here
  if (trafficDifferenceSeries.state === DATA_LIFECYCLE.FAILURE) {
    return <p>Error</p>;
  }

  if (trafficVolumeSchema.state === DATA_LIFECYCLE.SUCCESS && trafficVolumeSchema.data === null) {
    return <NoData />;
  }

  return (
    <GridContainer>
      <GridItem
        size={{
          xs: 12,
          md: 6,
          lg: 3,
        }}
      >
        {/* TODO: intl */}
        <Panel highlightedColor="series6" fullHeight>
          <PanelContent>
            {!isEntityLoading(trafficDifferenceTotals) ? (
              <SquaredTile data-testid="cdr-traffic-difference-trend-kpi-total">
                <SquaredChildTile
                  mainValue={(totalFileSize?.difference && totalFileSize.difference.totallink[0].toString()) ?? ''}
                  mainValueUnit={totalFileSize?.difference && totalFileSize.difference.totallink[1]}
                  variant="large"
                  // TODO intl
                  title={'Traffic Difference'}
                  subtitle={[
                    {
                      label: 'downlink',
                      value: totalFileSize
                        ? `${totalFileSize.difference.downlink && totalFileSize.difference.downlink[0].toString()}${
                            totalFileSize?.difference.downlink && totalFileSize?.difference.downlink[1]
                          }`
                        : 'N/A',
                    },
                    {
                      label: 'uplink',
                      value: totalFileSize
                        ? `${totalFileSize.difference.uplink && totalFileSize.difference.uplink[0].toString()}${
                            totalFileSize?.difference.uplink && totalFileSize?.difference.uplink[1]
                          }`
                        : 'N/A',
                    },
                  ]}
                  titleColor="series6"
                />
              </SquaredTile>
            ) : (
              <Skeleton height={137} variant="rectangular" />
            )}
          </PanelContent>
        </Panel>
      </GridItem>

      <GridItem
        size={{
          xs: 12,
          md: 6,
          lg: 3,
        }}
      >
        {/* TODO: intl */}
        <Panel highlightedColor="series2" fullHeight>
          <PanelContent>
            {!isEntityLoading(trafficDifferenceTotals) ? (
              <SquaredTile data-testid="cdr-traffic-difference-trend-kpi-downlink">
                <SquaredChildTile
                  mainValue={(totalFileSize?.mvno.totallink && totalFileSize.mvno.totallink[0].toString()) ?? ''}
                  mainValueUnit={totalFileSize?.mvno.totallink && totalFileSize?.mvno.totallink[1]}
                  variant="large"
                  title="MVNO TRAFFIC"
                  // TODO hardcoded
                  subtitle={[
                    {
                      label: 'downlink',
                      value: totalFileSize
                        ? `${totalFileSize.mvno.downlink && totalFileSize.mvno.downlink[0].toString()}${
                            totalFileSize?.mvno.downlink && totalFileSize?.mvno.downlink[1]
                          }`
                        : 'N/A',
                    },
                    {
                      label: 'uplink',
                      value: totalFileSize
                        ? `${totalFileSize.mvno.uplink && totalFileSize.mvno.uplink[0].toString()}${
                            totalFileSize?.mvno.uplink && totalFileSize?.mvno.uplink[1]
                          }`
                        : 'N/A',
                    },
                  ]}
                  titleColor="series2"
                />
              </SquaredTile>
            ) : (
              <Skeleton height={137} variant="rectangular" />
            )}
          </PanelContent>
        </Panel>
      </GridItem>
      <GridItem
        size={{
          xs: 12,
          md: 6,
          lg: 3,
        }}
      >
        {/* TODO: intl */}
        <Panel highlightedColor="series3" fullHeight>
          <PanelContent>
            {!isEntityLoading(trafficDifferenceTotals) ? (
              <SquaredTile data-testid="cdr-traffic-difference-trend-kpi-uplink">
                <SquaredChildTile
                  mainValue={(totalFileSize?.mno.totallink && totalFileSize.mno.totallink[0].toString()) ?? ''}
                  mainValueUnit={totalFileSize?.mno.totallink && totalFileSize?.mno.totallink[1]}
                  variant="large"
                  title="MNOs TRAFFIC"
                  titleColor="series3"
                  // TODO hardcoded
                  subtitle={[
                    {
                      label: 'downlink',
                      value: totalFileSize
                        ? `${totalFileSize.mno.downlink && totalFileSize.mno.downlink[0].toString()}${
                            totalFileSize?.mno.downlink && totalFileSize?.mno.downlink[1]
                          }`
                        : 'N/A',
                    },
                    {
                      label: 'uplink',
                      value: totalFileSize
                        ? `${totalFileSize.mno.uplink && totalFileSize.mno.uplink[0].toString()}${
                            totalFileSize?.mno.uplink && totalFileSize?.mno.uplink[1]
                          }`
                        : 'N/A',
                    },
                  ]}
                />
              </SquaredTile>
            ) : (
              <Skeleton height={137} variant="rectangular" />
            )}
          </PanelContent>
        </Panel>
      </GridItem>
      <GridItem
        size={{
          xs: 12,
          md: 6,
          lg: 3,
        }}
      >
        {/* TODO: intl */}
        <Panel>
          <PanelContent>
            {!isEntityLoading(trafficDifferenceTotals) ? (
              <Chart
                height={137}
                options={{
                  animation: false,
                  legend: {
                    show: false,
                  },
                  series: [
                    {
                      type: 'pie',
                      emphasis: {
                        scale: false,
                      },
                      radius: ['65%', '95%'],
                      name: 'MVNO',
                      data: [
                        {
                          name: 'MVNO',
                          value: trafficDifferenceTotalsMVNO?.total?.totallink
                            ? [trafficDifferenceTotalsMVNO?.total?.totallink]
                            : ['-'],
                          itemStyle: {
                            decal: {
                              color: 'transparent',
                            },
                          },
                          label: {
                            formatter: ({ name, percent }) => {
                              return `${name}\n${percent}%`;
                            },
                          },
                        },
                        {
                          name: 'Difference',
                          value: negativeDifference
                            ? [Math.abs(trafficDifferenceTotalsDIFFERENCE?.total?.totallink || 0)]
                            : ['-'],
                          itemStyle: {
                            decal: {
                              color: differencePaletteColors.difference,
                              backgroundColor: 'white',
                              dashArrayX: [1, 0],
                              dashArrayY: [1, 4],
                              rotation: -Math.PI / 4,
                            },
                          },
                          label: {
                            formatter: ({ name, percent }) => {
                              return `${name}\n${percent}%`;
                            },
                          },
                        },
                      ],
                      color: [theme.palette.series2.main, differencePaletteColors.difference],
                      label: {
                        formatter: ({ name, percent }) => {
                          return `${name}\n${percent}%`;
                        },
                        position: 'inside',
                        show: true,
                        fontSize: '12',
                        fontWeight: 'bold',
                        textBorderColor: '#fff',
                        textBorderWidth: 2,
                      },
                    },
                    {
                      type: 'pie',
                      emphasis: {
                        scale: false,
                      },

                      radius: ['30%', '60%'],
                      name: 'MNOs',
                      data: [
                        {
                          name: 'MNOs',
                          value: trafficDifferenceTotalsMNO?.total?.totallink
                            ? [trafficDifferenceTotalsMNO?.total?.totallink]
                            : ['-'],
                          itemStyle: {
                            decal: {
                              color: 'transparent',
                            },
                          },
                          label: {
                            formatter: ({ name, percent }) => {
                              return `${name}\n${percent}%`;
                            },
                          },
                        },
                        {
                          name: 'Difference',
                          value: !negativeDifference
                            ? [Math.abs(trafficDifferenceTotalsDIFFERENCE?.total?.totallink || 0)]
                            : ['-'],
                          itemStyle: {
                            decal: {
                              color: differencePaletteColors.difference,
                              backgroundColor: 'white',
                              dashArrayX: [1, 0],
                              dashArrayY: [1, 4],
                              rotation: -Math.PI / 4,
                            },
                          },
                          label: {
                            formatter: ({ name, percent }) => {
                              return `${name}\n${percent}%`;
                            },
                          },
                        },
                      ],
                      color: [theme.palette.series3.main, differencePaletteColors.difference],
                      label: {
                        position: 'inside',
                        show: true,
                        fontSize: '12',
                        fontWeight: 'bold',
                        textBorderColor: '#fff',
                        textBorderWidth: 2,
                      },
                    },
                  ],
                  grid: {
                    show: false,
                    left: 0,
                    right: 0,
                    top: 0,
                  },
                  tooltip: {
                    show: false,
                  },
                  aria: {
                    enabled: true,
                    decal: {
                      show: true,
                    },
                  },
                }}
                data-testid="cdr-traffic-difference-trend-chart"
              />
            ) : (
              <Skeleton height={137} variant="rectangular" />
            )}
          </PanelContent>
        </Panel>
      </GridItem>

      <GridItem
        size={{
          xs: 12,
        }}
      >
        <TrendChart
          isLoading={isEntityLoading(trafficDifferenceSeries)}
          title="Difference trend"
          name="Total"
          color={theme.palette.series6.main}
          seriesData={seriesData?.difference[metric]}
          xAxisFormat={(value: string) => moment(value).format(tooltipDateTitle)}
          yAxisFormat={(value: number) => filesize(value, { base: 2, standard: 'jedec' })}
          legendFormat={`Difference ${metric}`}
          tooltipFormat={(params) => tooltipFormatter(params)}
          data-testid="cdr-traffic-difference-totallink-trend-chart"
          headerActionsComponent={
            <Box sx={{ width: { xs: '100%', md: '150px' } }}>
              <Select
                label="Traffic"
                onChange={handleMetricChange}
                size={'small'}
                value={metricOptions.length ? metric : ''}
                data-testid="cdr-toolbar-metric-select"
              >
                {metricOptions?.map((option) => (
                  <MenuItem value={option.value} key={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          }
        />
      </GridItem>

      <GridItem
        size={{
          xs: 12,
        }}
      >
        <DifferenceTrendChart
          seriesData={seriesData}
          colors={differencePaletteColors}
          isLoading={isEntityLoading(trafficDifferenceSeries)}
          metric={metric}
          xAxisFormat={(value: string) => moment(value).format(tooltipDateTitle)}
          yAxisFormat={(value: number) => filesize(value, { base: 2, standard: 'jedec' })}
          tooltipFormat={(params) =>
            tooltipFormatter(params, (param) => {
              return param.seriesName !== 'Difference' || (param.seriesName === 'Difference' && param.data[1] !== 0);
            })
          }
          headerActionsComponent={
            <ResponsiveControls>
              <Stack direction="row" justify="flex-end" sx={{ pr: { xs: 0, md: 1 } }}>
                <Box sx={{ width: { xs: '100%', md: '150px' } }}>
                  <Select
                    label="Traffic"
                    onChange={handleMetricChange}
                    size={'small'}
                    value={metricOptions.length ? metric : ''}
                    data-testid="cdr-toolbar-metric-select"
                  >
                    {metricOptions?.map((option) => (
                      <MenuItem value={option.value} key={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Stack>
            </ResponsiveControls>
          }
        />
      </GridItem>
    </GridContainer>
  );
}
