import { AnyAction } from 'redux';
import {
  FILTERS_LOADING,
  FILTERS_SUCCESS,
  FILTERS_FAILURE,
  FILTERS_SET,
  FILTERS_INJECT,
  SORT_SET,
  LIST_LOADING,
  LIST_SUCCESS,
  LIST_FAILURE,
  LIST_CLEAR,
} from '../actions/usimOrders';
import { Filters, List, listFailure, listIdle, listLoading, listSuccess } from '.';

export type UsimOrdersState = {
  filters: Filters;
  sort: string;
  list: List;
};

const initialState: UsimOrdersState = {
  filters: { state: -1, data: {}, values: {} },
  sort: '',
  list: listIdle(),
};

export default function usimOrdersReducer(state = initialState, action: AnyAction): UsimOrdersState {
  switch (action.type) {
    case FILTERS_LOADING:
      return Object.assign({}, state, {
        filters: { state: 0, data: {}, values: {} },
      });

    case FILTERS_SUCCESS:
      return Object.assign({}, state, {
        filters: { state: 1, data: action.payload, values: {} },
      });

    case FILTERS_FAILURE:
      return Object.assign({}, state, {
        filters: { state: 2, data: {}, values: {} },
      });

    case FILTERS_SET:
      return Object.assign({}, state, {
        filters: { ...state.filters, values: action.payload },
      });

    case FILTERS_INJECT:
      return Object.assign({}, state, {
        filters: { state: action.payload.state, data: action.payload.data, values: action.payload.values },
      });

    case SORT_SET:
      return Object.assign({}, state, {
        sort: action.payload,
      });

    case LIST_LOADING:
      return Object.assign({}, state, {
        list: listLoading(state.list.data),
      });

    case LIST_SUCCESS:
      return Object.assign({}, state, {
        list: listSuccess(action.payload),
      });

    case LIST_FAILURE:
      return Object.assign({}, state, {
        list: listFailure(),
      });

    case LIST_CLEAR:
      return initialState;

    default:
      return state;
  }
}
