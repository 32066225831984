import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLandingPageNodes5g, getLandingPageUsimsInfo5g } from 'store/actions/landing';
import { useLandingSelector } from 'store/selectors/landing';
import { isEntityLoading } from 'store/reducers';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { MapBox, Location } from '@athonet/ui/components/Data/MapBox';
import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';
import { useCanUserReadUsimSelector, useCanUserViewSimsBySiteMapSelector } from 'store/selectors/user';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Divider } from '@athonet/ui/components/Layout/Divider';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Table, TableColumn } from '@athonet/ui/components/Data/Table';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Node } from 'store/models/node';
import { tenantsCellRenderer } from 'utils/tenantsCellRenderer';

export function Provisioning5g() {
  const dispatch = useDispatch();
  const { usimsInfo5g, nodes5gInfo } = useLandingSelector();
  const bootstrap = useBootstrapSelector();
  const [locations, setLocations] = useState<Location[]>([]);
  const canUserViewSimsBySite = useCanUserViewSimsBySiteMapSelector();
  const canUserReadUsim = useCanUserReadUsimSelector();

  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(getLandingPageUsimsInfo5g());
    dispatch(getLandingPageNodes5g());
  }, [dispatch]);

  useEffect(() => {
    if (nodes5gInfo.data) {
      const newLocations = nodes5gInfo.data.data
        .filter((node) => node.lat && node.long)
        .map(
          ({ address, country, name, lat, long, id }) =>
            ({
              address,
              center: [long, lat],
              id,
              text: `
              <strong>${name}</strong>
              <br>
              ${address} - ${country}
              `,
            } as Location)
        );
      setLocations(newLocations);
    }
  }, [nodes5gInfo.data]);

  const shouldMapRender = useMemo(
    () =>
      Boolean(locations.length > 0 && bootstrap?.mapbox_center && bootstrap?.mapbox_token && bootstrap?.mapbox_styles),
    [bootstrap, locations]
  );

  const nodeColumns = useMemo(() => {
    const cols: TableColumn<Node>[] = [
      {
        key: 'display_name',
        label: formatMessage({ id: 'nodes.table.name' }),
        sortable: true,
        cellRender: ({ display_name, id }) => {
          return (
            <Link
              onClick={() => {
                navigate(`/networks/nodes/${id}`);
              }}
            >
              <Text type={'body2'}>{display_name}</Text>
            </Link>
          );
        },
      },
      {
        key: 'product_version',
        label: formatMessage({ id: 'nodes.table.nodeType' }),
        sortable: true,
      },
    ];

    if (!shouldMapRender) {
      cols.push({
        // backend still returning "owners"
        key: 'owners',
        label: formatMessage({ id: 'nodes.table.tenants' }),
        sortable: false,
        cellRender: ({ owners }) => <>{tenantsCellRenderer(owners)}</>,
      });
    }
    return cols;
  }, [formatMessage, navigate, shouldMapRender]);

  return (
    <>
      <GridItem
        size={{
          xs: 12,
        }}
      >
        <Box>
          <Text type="h5" fontWeight={600} sx={{ mb: 1 }}>
            {formatMessage({ id: 'landing.panel.title.5GProvisioning' })}
          </Text>
          <Divider color="secondary" />
        </Box>
      </GridItem>

      {canUserReadUsim && (
        <>
          <GridItem
            size={{
              xs: 12,
              sm: 6,
            }}
            zeroMinWidth
            sx={{ display: 'flex', alignContent: 'stretch' }}
          >
            <Panel>
              <PanelContent>
                {!isEntityLoading(usimsInfo5g) ? (
                  <SquaredTile
                    onClick={() => navigate('/subscribers')}
                    data-testid="landing-usims-5g-total-kpi"
                    icon="Sim-Card1"
                  >
                    <SquaredChildTile
                      mainValue={usimsInfo5g.data?.total ?? ''}
                      variant={'medium'}
                      title="total sims"
                      titleColor="primary"
                    />
                  </SquaredTile>
                ) : (
                  <Skeleton height={97} variant="rectangular" />
                )}
              </PanelContent>
            </Panel>
          </GridItem>
          <GridItem
            size={{
              xs: 12,
              sm: 6,
            }}
            zeroMinWidth
            sx={{ display: 'flex', alignContent: 'stretch' }}
          >
            <Panel>
              <PanelContent>
                {!isEntityLoading(usimsInfo5g) ? (
                  <SquaredTile data-testid="landing-usims-5g-active-kpi" icon="Sim-Card1">
                    <SquaredChildTile
                      mainValue={usimsInfo5g.data?.provisioned ?? ''}
                      variant={'medium'}
                      title="provisioned and active sims"
                      titleColor="success"
                    />
                  </SquaredTile>
                ) : (
                  <Skeleton height={97} variant="rectangular" />
                )}
              </PanelContent>
            </Panel>
          </GridItem>
        </>
      )}
      {/* Map */}
      {canUserViewSimsBySite && (
        <>
          {locations.length > 0 && bootstrap?.mapbox_center && bootstrap?.mapbox_token && bootstrap?.mapbox_styles && (
            <GridItem
              size={{
                xs: 12,
                md: 7,
              }}
              zeroMinWidth
            >
              <Panel fullHeight title={'Nodes By Location'}>
                <PanelContent>
                  <MapBox
                    centerAddress={[Number(bootstrap.mapbox_center[1]), Number(bootstrap.mapbox_center[0])]}
                    id="landing-sites-5g-map"
                    height={620}
                    locations={locations}
                    markers
                    centerFromMarkers
                    zoom={5}
                    data-testid="landing-sites-map"
                    accessToken={bootstrap.mapbox_token}
                    stylesUrl={bootstrap.mapbox_styles}
                  />
                </PanelContent>
              </Panel>
            </GridItem>
          )}
          <GridItem
            size={{
              xs: 12,
              md: shouldMapRender ? 5 : 12,
            }}
            zeroMinWidth
          >
            <Panel fullHeight title="Total Nodes">
              <PanelContent>
                <Box
                  sx={{
                    height: '620px',
                  }}
                >
                  <Table
                    orderBy="name"
                    columns={nodeColumns}
                    data={nodes5gInfo.data.data}
                    rowsCount={nodes5gInfo.data.total}
                    page={0}
                    rowsPerPage={nodes5gInfo.data.total}
                    rowKey="name"
                    autoScale
                  />
                </Box>
              </PanelContent>
            </Panel>
          </GridItem>
        </>
      )}
    </>
  );
}
