export const FILTERS_LOADING = 'USIM_ORDERS_FILTERS_LOADING';
export const FILTERS_SUCCESS = 'USIM_ORDERS_FILTERS_SUCCESS';
export const FILTERS_FAILURE = 'USIM_ORDERS_FILTERS_FAILURE';
export const FILTERS_SET = 'USIM_ORDERS_FILTERS_SET';
export const FILTERS_INJECT = 'USIM_ORDERS_FILTERS_INJECT';

export const SORT_SET = 'USIM_ORDERS_SORT_SET';

export const LIST_LOADING = 'USIM_ORDERS_LIST_LOADING';
export const LIST_SUCCESS = 'USIM_ORDERS_LIST_SUCCESS';
export const LIST_FAILURE = 'USIM_ORDERS_LIST_FAILURE';
export const LIST_CLEAR = 'USIM_ORDERS_LIST_CLEAR';

export function filtersLoading() {
  return {
    type: FILTERS_LOADING,
  };
}

export function filtersSuccess(payload: unknown) {
  return {
    type: FILTERS_SUCCESS,
    payload,
  };
}

export function filtersFailure() {
  return {
    type: FILTERS_FAILURE,
  };
}

export function filtersSet(payload: unknown) {
  return {
    type: FILTERS_SET,
    payload,
  };
}

export function sortSet(payload: unknown) {
  return {
    type: SORT_SET,
    payload,
  };
}

export function filtersInject(payload: unknown) {
  return {
    type: FILTERS_INJECT,
    payload,
  };
}

export function listLoading() {
  return {
    type: LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: LIST_FAILURE,
  };
}

export function listClear() {
  return {
    type: LIST_CLEAR,
  };
}
