import { Route, createRoutesFromElements, Navigate, RouterProvider, redirect } from 'react-router-dom';
import Layout, { FullHeightLayout } from 'containers/Layout';
import Login from 'containers/Login';
import ResetPassword from 'containers/ResetPassword';
import ConfirmUser from 'containers/ConfirmUser';
import ConfirmUserEmail from 'containers/ConfirmUserEmail';
import Landing from 'containers/Landing';
import Sites from 'containers/Sites';
import Users from 'containers/Users';
import Tenants from 'containers/Tenants';
import ErrorPage from 'containers/Errors/PageError';
import Profile from 'containers/Profile';
import Security from 'containers/Security';
import Unauthorized from 'containers/Unauthorized';
import OptionalAuthLayout from 'containers/OptionalAuthLayout';
import { CdrTrafficVolumeLayout } from 'containers/Reports/Cdr/TrafficVolume';
import Nodes from 'containers/Networks/Nodes';
import Segments from 'containers/Segments/Segments';
import QoSProfiles from 'containers/Segments/QoSProfiles';
import Cards from 'containers/4gProvisioning/UsimCards';
import Profiles from 'containers/4gProvisioning/UsimProfiles';
import Orders from 'containers/UsimOrders/Cards';
import GpgKeys from 'containers/UsimOrders/GpgKeys';
import BillingConfiguration from 'containers/BillingConfiguration';
import CalculatedBills from 'containers/CalculatedBills';
import { CdrHoc } from 'containers/Reports/Cdr/CdrHoc';
import UsimCard from 'containers/4gProvisioning/UsimCards/id';
import { AuthLayout } from 'containers/Layout/AuthLayout';
import AdminConfigLogin from 'containers/AdminConfig/Login';
import AdminConfigLayout from 'containers/AdminConfig/AdminConfigLayout';
import { T_PERMISSIONS, U_PERMISSIONS } from 'utils/permissionCodes';
import PrivateHOC from 'containers/PrivateHOC';
import { TrafficVolumeTrend } from 'containers/Reports/Cdr/TrafficVolume/TrafficVolumeTrend';
import { TrafficVolumeRanking } from 'containers/Reports/Cdr/TrafficVolume/TrafficVolumeRanking';
import { TrafficVolumeAnalysis } from 'containers/Reports/Cdr/TrafficVolume/TrafficVolumeAnalysis';
import { CdrImeiChangesLayout } from 'containers/Reports/Cdr/ImeiChanges';
import { ImeiChangesTrend } from 'containers/Reports/Cdr/ImeiChanges/ImeiChangesTrend';
import { ImeiChangesRanking } from 'containers/Reports/Cdr/ImeiChanges/ImeiChangesRanking';
import { ImeiChangesAnalysis } from 'containers/Reports/Cdr/ImeiChanges/ImeiChangesAnalysis';
import { CdrTrafficDifferenceLayout } from 'containers/Reports/Cdr/TrafficDifference';
import { TrafficDifferenceTrend } from 'containers/Reports/Cdr/TrafficDifference/Trend';
import { TrafficDifferenceRanking } from 'containers/Reports/Cdr/TrafficDifference/Ranking';
import AdminConfigView from 'containers/AdminConfig/AdminConfigView';
import UsimCards5g from 'containers/5gProvisioning/UsimCards';
import { NodeDetailLayout } from 'containers/Networks/Nodes/NodeDetailsLayout';
import { PlmnsList } from 'components/NodeId/NewPlatform/PlmnDetails';
import { NodeDetails } from 'components/NodeId/NewPlatform/NodeDetails';
import { ServiceProfiles } from 'components/NodeId/NewPlatform/ServiceProfiles/ServiceProfiles';
import { UsimDetailsLayout } from 'containers/5gProvisioning/UsimCards/UsimDetailsLayout';
import { UsimDetails } from 'containers/5gProvisioning/UsimCards/UsimDetails';
import { sentryCreateBrowserRouter } from 'sentry';

export type DetailPageParams<Tab extends string = ''> = {
  id: string;
  tab: Tab;
};

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorPage />}>
      <Route index element={<Navigate to="home" replace />} />
      {/* Authentication */}
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} /> {/* TODO: */}
        <Route path="resetpassword/:resetToken" element={<ResetPassword />} />
        <Route path="confirm/:confirmToken" element={<ConfirmUser />} />
        <Route path="email-confirm/:confirmToken" element={<ConfirmUserEmail />} />
      </Route>
      {/* Admin Config */}
      <Route path="adminconfig">
        <Route element={<AdminConfigLayout />}>
          <Route index element={<AdminConfigView />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="login" element={<AdminConfigLogin />} />
        </Route>
      </Route>
      {/* Home / Landing */}
      <Route path="home" element={<PrivateHOC permissions={[U_PERMISSIONS.VIEW_DASHBOARD_MODULE]} />}>
        <Route element={<Layout />}>
          <Route
            index
            element={
              <CdrHoc>
                <Landing />
              </CdrHoc>
            }
          />
        </Route>
      </Route>
      {/* Nodes */}
      <Route path="networks">
        <Route index element={<Navigate to="nodes" replace />} />
        <Route
          element={
            <PrivateHOC
              tenantType={[T_PERMISSIONS.MASTER]}
              permissions={[U_PERMISSIONS.VIEW_NODE_MODULE]}
              featureFlags={['athux_support_enabled', 'athonetos_support_enabled']}
              useNonStrictFlagCheck={true}
            />
          }
        >
          <Route path="nodes">
            <Route element={<FullHeightLayout />}>
              <Route index element={<Nodes />} />
            </Route>
            <Route path=":id">
              <Route index element={<Navigate to="details" replace />} />
              <Route element={<Layout />}>
                <Route element={<NodeDetailLayout />}>
                  <Route path="details" element={<NodeDetails />} />
                </Route>
              </Route>
              <Route element={<FullHeightLayout />}>
                <Route element={<NodeDetailLayout />}>
                  <Route path="service-profiles" element={<ServiceProfiles />} />
                  <Route path="plmns" element={<PlmnsList />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      {/* Sites */}
      <Route path="sites" element={<FullHeightLayout />}>
        <Route
          element={
            <PrivateHOC permissions={[U_PERMISSIONS.VIEW_SITE_MODULE]} featureFlags={['athux_support_enabled']} />
          }
        >
          <Route index element={<Sites />} />
        </Route>
      </Route>
      {/* 4G Provisioning */}
      <Route path="4g-provisioning">
        <Route index element={<Navigate to="usim-cards" replace />} />
        <Route
          element={
            <PrivateHOC permissions={[U_PERMISSIONS.VIEW_USIM_MODULE]} featureFlags={['athux_support_enabled']} />
          }
        >
          <Route element={<FullHeightLayout />}>
            <Route path="usim-cards" element={<Cards />} />
            <Route path="usim-profiles" element={<Profiles />}>
              <Route path=":id">
                <Route index element={<Navigate to="details" replace />} />
                <Route path="details" element={<Profiles />} />
              </Route>
            </Route>
          </Route>
          <Route element={<Layout />}>
            <Route path="usim-cards">
              <Route path=":id">
                <Route index element={<Navigate to="details" replace />} />
                <Route
                  element={<UsimCard />}
                  path=":tab"
                  loader={({ params }) => {
                    if (!['details', 'events', 'info'].includes(String(params.tab))) {
                      return redirect(`/4g-provisioning/usim-cards/${params.id}`); // NOTE: can be moved inside rendered element
                    }
                    return null;
                  }}
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          element={
            <PrivateHOC permissions={[U_PERMISSIONS.VIEW_SEGMENT_MODULE]} featureFlags={['athux_support_enabled']} />
          }
        >
          <Route element={<FullHeightLayout />}>
            <Route path="apn-profiles" element={<Segments />}>
              <Route path=":id" element={<Segments />} />
            </Route>
            <Route path="qos-profiles" element={<QoSProfiles />}>
              <Route path=":id" element={<QoSProfiles />} />
            </Route>
          </Route>
        </Route>
      </Route>

      {/* Subscribers */}
      <Route path="subscribers" element={<FullHeightLayout />}>
        <Route
          element={
            <PrivateHOC permissions={[U_PERMISSIONS.VIEW_USIM_MODULE]} featureFlags={['athonetos_support_enabled']} />
          }
        >
          <Route index element={<UsimCards5g />} />
          <Route path=":id">
            <Route index element={<Navigate to="details" replace />} />
            <Route>
              <Route element={<UsimDetailsLayout />}>
                <Route path="details" element={<UsimDetails />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* Usim Orders */}
      <Route
        path="usim-orders"
        element={
          <PrivateHOC
            tenantType={[T_PERMISSIONS.MASTER]}
            permissions={[U_PERMISSIONS.VIEW_USIM_MODULE]}
            featureFlags={['usim_orders', 'athux_support_enabled']}
          />
        }
      >
        <Route index element={<Navigate to="usim-cards" replace />} />
        <Route element={<FullHeightLayout />}>
          <Route path="usim-cards" element={<Orders />} />
          <Route path="gpg-keys" element={<GpgKeys />} />
        </Route>
      </Route>
      {/* Billing */}
      <Route
        path="billing"
        element={
          <PrivateHOC
            tenantType={[T_PERMISSIONS.MASTER]}
            permissions={[U_PERMISSIONS.VIEW_BILLING_MODULE]}
            featureFlags={['billing_service', 'athux_support_enabled']}
          />
        }
      >
        <Route element={<FullHeightLayout />}>
          <Route path="configuration" element={<BillingConfiguration />} />
          <Route path="calculatedbills" element={<CalculatedBills />} />
        </Route>
      </Route>
      {/* Reports */}
      <Route path="reports">
        {/* Reports - Traffic Volume */}
        <Route
          path="traffic-volume"
          element={
            <PrivateHOC
              permissions={[U_PERMISSIONS.VIEW_REPORTS_TRAFFIC_MODULE]}
              featureFlags={['traffic_service', 'athux_support_enabled']}
            />
          }
        >
          <Route index element={<Navigate to="trend" replace />} />
          <Route element={<Layout />}>
            <Route path="trend" element={<CdrTrafficVolumeLayout />}>
              <Route
                index
                element={
                  <CdrHoc>
                    <TrafficVolumeTrend />
                  </CdrHoc>
                }
              />
            </Route>
            <Route path="ranking" element={<CdrTrafficVolumeLayout />}>
              <Route
                index
                element={
                  <CdrHoc>
                    <TrafficVolumeRanking />
                  </CdrHoc>
                }
              />
            </Route>
          </Route>
          <Route element={<FullHeightLayout />}>
            <Route path="analysis" element={<CdrTrafficVolumeLayout />}>
              <Route
                index
                element={
                  <CdrHoc>
                    <TrafficVolumeAnalysis />
                  </CdrHoc>
                }
              />
            </Route>
          </Route>
        </Route>
        {/* Reports - Imei Changes */}
        <Route
          path="imei-changes"
          element={
            <PrivateHOC
              permissions={[U_PERMISSIONS.VIEW_REPORTS_IMEI_CHANGES_MODULE]}
              featureFlags={['traffic_service', 'athux_support_enabled']}
            />
          }
        >
          <Route index element={<Navigate to="trend" replace />} />
          <Route element={<Layout />}>
            <Route path="trend" element={<CdrImeiChangesLayout />}>
              <Route
                index
                element={
                  <CdrHoc>
                    <ImeiChangesTrend />
                  </CdrHoc>
                }
              />
            </Route>
            <Route path="ranking" element={<CdrImeiChangesLayout />}>
              <Route
                index
                element={
                  <CdrHoc>
                    <ImeiChangesRanking />
                  </CdrHoc>
                }
              />
            </Route>
          </Route>
          <Route element={<FullHeightLayout />}>
            <Route path="analysis" element={<CdrImeiChangesLayout />}>
              <Route
                index
                element={
                  <CdrHoc>
                    <ImeiChangesAnalysis />
                  </CdrHoc>
                }
              />
            </Route>
          </Route>
        </Route>
        {/* Reports - Traffic Difference */}
        <Route
          path="traffic-difference"
          element={
            <PrivateHOC
              permissions={[U_PERMISSIONS.VIEW_REPORTS_TRAFFIC_DIFFERENCE_MODULE]}
              featureFlags={['traffic_difference_service', 'athux_support_enabled']}
            />
          }
        >
          <Route index element={<Navigate to="trend" replace />} />
          <Route element={<Layout />}>
            <Route path="trend" element={<CdrTrafficDifferenceLayout />}>
              <Route
                index
                element={
                  <CdrHoc>
                    <TrafficDifferenceTrend />
                  </CdrHoc>
                }
              />
            </Route>
            <Route path="ranking" element={<CdrTrafficDifferenceLayout />}>
              <Route
                index
                element={
                  <CdrHoc>
                    <TrafficDifferenceRanking />
                  </CdrHoc>
                }
              />
            </Route>
          </Route>
        </Route>
      </Route>
      {/* Settings */}
      <Route path="settings">
        <Route element={<FullHeightLayout />}>
          <Route path="tenants" element={<PrivateHOC permissions={[U_PERMISSIONS.VIEW_TENANT_MODULE]} />}>
            <Route index element={<Tenants />} />
          </Route>
          <Route path="users" element={<PrivateHOC permissions={[U_PERMISSIONS.VIEW_USER_MODULE]} />}>
            <Route index element={<Users />} />
          </Route>
        </Route>
        <Route element={<Layout />}>
          <Route path="roles" element={<PrivateHOC permissions={[U_PERMISSIONS.VIEW_SECURITY_MODULE]} />}>
            <Route index element={<Security />} />
          </Route>
        </Route>
      </Route>
      {/* Profile */}
      <Route path="profile" element={<PrivateHOC />}>
        <Route element={<Layout />}>
          <Route index element={<Profile />} />
        </Route>
      </Route>
      {/* Unauthorized */}
      <Route element={<OptionalAuthLayout />}>
        <Route path="unauthorized" element={<Unauthorized />} />
      </Route>
    </Route>
  )
);

export default function Router() {
  return <RouterProvider router={router} />;
}
