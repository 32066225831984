import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getCdrTrafficVolumeTotal, getCdrTrafficVolumeTrendSeries } from 'store/actions/cdrReports/trafficVolume';
import {
  useCdrTrafficVolumeDateRange,
  useCdrTrafficVolumeFilter,
  useCdrTrafficVolumePeriod,
  useCdrTrafficVolumeSchema,
  useCdrTrafficVolumeTrendSeries,
  useCdrTrafficVolumeTotal,
} from 'store/selectors/cdrReports/trafficVolume';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { Chart } from '@athonet/ui/components/Data/Chart';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import { NoData } from '@athonet/ui/components/Data/NoData';
import filesize from 'filesize';
import moment from 'moment';
import { DATA_LIFECYCLE, isEntityLoading } from 'store/reducers';
import { getTooltipDateTitle, getTotalFileSize, metricTooltipFormatter } from '../utils/utils';
import {
  getCdrActiveSourceNetworkAggregation,
  useCdrActiveSourceNetwork,
} from 'store/selectors/cdrReports/sourceNetwork';
import theme from '@athonet/ui/theme';
import TrendChart from '../TrendChart';
import TrafficVolumeTrendChart from './TrafficVolumeTrendChart';
import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';

export function TrafficVolumeTrend() {
  const dispatch = useDispatch();
  const trafficVolumeSeries = useCdrTrafficVolumeTrendSeries();
  const trafficVolumeSchema = useCdrTrafficVolumeSchema();
  const trafficVolumeTotal = useCdrTrafficVolumeTotal();
  const trafficVolumeDateRange = useCdrTrafficVolumeDateRange();
  const trafficVolumePeriod = useCdrTrafficVolumePeriod();
  const trafficVolumeFilter = useCdrTrafficVolumeFilter();
  const activeSourceNetwork = useCdrActiveSourceNetwork();

  const tooltipDateTitle = useMemo(() => {
    return getTooltipDateTitle(trafficVolumePeriod);
  }, [trafficVolumePeriod]);

  const tooltipFormatter = useCallback(
    (params: any) => {
      return metricTooltipFormatter(params, tooltipDateTitle, 'fileSize');
    },

    [tooltipDateTitle]
  );

  useEffect(() => {
    if (
      !trafficVolumeDateRange ||
      !trafficVolumeDateRange.start ||
      !trafficVolumeDateRange.end ||
      !activeSourceNetwork
    ) {
      return;
    }
    dispatch(
      getCdrTrafficVolumeTotal({
        start_date: trafficVolumeDateRange.start,
        end_date: trafficVolumeDateRange.end,
        period: trafficVolumePeriod,
        group_by: trafficVolumeFilter.group_by || undefined,
        group_filter: trafficVolumeFilter.group_filter || undefined,
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
    dispatch(
      getCdrTrafficVolumeTrendSeries({
        start_date: trafficVolumeDateRange.start,
        end_date: trafficVolumeDateRange.end,
        period: trafficVolumePeriod,
        group_by: trafficVolumeFilter.group_by || undefined,
        group_filter: trafficVolumeFilter.group_filter || undefined,
        source_networks_aggregation: getCdrActiveSourceNetworkAggregation(activeSourceNetwork),
      })
    );
  }, [
    dispatch,
    activeSourceNetwork,
    trafficVolumeDateRange,
    trafficVolumeFilter.group_by,
    trafficVolumeFilter.group_filter,
    trafficVolumePeriod,
  ]);

  const totalFileSize = useMemo(() => {
    if (!trafficVolumeTotal.data) {
      return;
    }
    return getTotalFileSize(trafficVolumeTotal.data);
  }, [trafficVolumeTotal]);

  const seriesData = useMemo(() => {
    if (!trafficVolumeSeries.data) {
      return;
    }

    const series: {
      totallink: [string, number | string][];
      uplink: [string, number | string][];
      downlink: [string, number | string][];
    } = {
      totallink: [],
      uplink: [],
      downlink: [],
    };

    trafficVolumeSeries.data.forEach((item) => {
      const datetime = item.datetime.toString();
      series.totallink.push([datetime, item.totallink !== null ? item.totallink : '-']);
      series.downlink.push([datetime, item.downlink !== null ? item.downlink : '-']);
      series.uplink.push([datetime, item.uplink !== null ? item.uplink : '-']);
    });

    return series;
  }, [trafficVolumeSeries]);

  // TODO: handle data error here
  if (trafficVolumeSeries.state === DATA_LIFECYCLE.FAILURE) {
    return <p>Error</p>;
  }

  if (trafficVolumeSchema.state === DATA_LIFECYCLE.SUCCESS && trafficVolumeSchema.data === null) {
    return <NoData />;
  }

  return (
    <GridContainer>
      <GridItem
        size={{
          xs: 12,
          sm: 6,
          lg: 3,
        }}
      >
        {/* TODO: intl */}
        <Panel highlightedColor="series1" fullHeight>
          <PanelContent>
            {!isEntityLoading(trafficVolumeTotal) ? (
              <SquaredTile data-testid="cdr-traffic-volume-trend-kpi-total">
                <SquaredChildTile
                  mainValue={(totalFileSize?.totallink && totalFileSize.totallink[0].toString()) ?? ''}
                  mainValueUnit={totalFileSize?.totallink && totalFileSize.totallink[1]}
                  variant="large"
                  title="Total"
                  subtitle="Total traffic"
                  titleColor="series1"
                />
              </SquaredTile>
            ) : (
              <Skeleton height={113} variant="rectangular" />
            )}
          </PanelContent>
        </Panel>
      </GridItem>

      <GridItem
        size={{
          xs: 12,
          sm: 6,
          lg: 3,
        }}
      >
        {/* TODO: intl */}
        <Panel highlightedColor="series2" fullHeight>
          <PanelContent>
            {!isEntityLoading(trafficVolumeTotal) ? (
              <SquaredTile data-testid="cdr-traffic-volume-trend-kpi-downlink">
                <SquaredChildTile
                  mainValue={(totalFileSize?.downlink && totalFileSize.downlink[0].toString()) ?? ''}
                  mainValueUnit={totalFileSize?.downlink && totalFileSize?.downlink[1]}
                  variant="large"
                  title="Downlink"
                  subtitle="Total downlink traffic"
                  titleColor="series2"
                />
              </SquaredTile>
            ) : (
              <Skeleton height={113} variant="rectangular" />
            )}
          </PanelContent>
        </Panel>
      </GridItem>
      <GridItem
        size={{
          xs: 12,
          sm: 6,
          lg: 3,
        }}
      >
        {/* TODO: intl */}
        <Panel highlightedColor="series3" fullHeight>
          <PanelContent>
            {!isEntityLoading(trafficVolumeTotal) ? (
              <SquaredTile data-testid="cdr-traffic-volume-trend-kpi-uplink">
                <SquaredChildTile
                  mainValue={(totalFileSize?.uplink && totalFileSize.uplink[0].toString()) ?? ''}
                  mainValueUnit={totalFileSize?.uplink && totalFileSize?.uplink[1]}
                  variant="large"
                  title="Uplink"
                  titleColor="series3"
                  subtitle="Total Uplink traffic"
                />
              </SquaredTile>
            ) : (
              <Skeleton height={113} variant="rectangular" />
            )}
          </PanelContent>
        </Panel>
      </GridItem>
      <GridItem
        size={{
          xs: 12,
          sm: 6,
          lg: 3,
        }}
      >
        {/* TODO: intl */}
        <Panel fullHeight>
          <PanelContent>
            {!isEntityLoading(trafficVolumeTotal) ? (
              <Chart
                height={113}
                options={{
                  animation: false,
                  grid: {
                    borderColor: 'transparent',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  },
                  legend: {
                    show: false,
                  },
                  series: [
                    {
                      emphasis: {
                        scale: false,
                      },
                      type: 'pie',
                      radius: ['50%', '100%'],
                      name: 'MVNO',
                      label: {
                        formatter: ({ name, percent }) => {
                          return `${name}\n${percent}%`;
                        },
                        position: 'inside',
                        show: true,
                        fontSize: '12',
                        fontWeight: 'bold',
                        textBorderColor: '#fff',
                        textBorderWidth: 2,
                      },
                      labelLayout: {
                        align: 'center',
                      },
                      color: [theme.palette.series2.main, theme.palette.series3.main],
                      data: [
                        {
                          name: 'Downlink',
                          value: trafficVolumeTotal.data?.downlink || 0,
                        },
                        { name: 'Uplink', value: trafficVolumeTotal.data?.uplink || 0 },
                      ],
                      itemStyle: {
                        decal: { color: 'transparent' },
                      },
                    },
                  ],
                }}
                data-testid="cdr-traffic-volume-trend-chart"
              />
            ) : (
              <Skeleton height={113} variant="rectangular" />
            )}
          </PanelContent>
        </Panel>
      </GridItem>

      <GridItem
        size={{
          xs: 12,
        }}
      >
        <TrafficVolumeTrendChart
          isLoading={isEntityLoading(trafficVolumeSeries)}
          title="Traffic Trend"
          name="Trend"
          trafficVolumePeriod={'day'}
          seriesData={seriesData}
          data-testid="cdr-traffic-volume-trend-chart"
        />
      </GridItem>

      <GridItem
        size={{
          xs: 12,
        }}
      >
        <TrendChart
          isLoading={isEntityLoading(trafficVolumeSeries)}
          title="Traffic Trend"
          name="Total"
          height={240}
          seriesDataArray={[
            {
              name: 'Downlink',
              data: seriesData?.downlink,
              color: theme.palette.series2.main,
            },
            {
              name: 'Uplink',
              data: seriesData?.uplink,
              color: theme.palette.series3.main,
            },
          ]}
          xAxisFormat={(value: string) => moment(value).format(tooltipDateTitle)}
          yAxisFormat={(value: number) => filesize(value, { base: 2, standard: 'jedec' })}
          tooltipFormat={tooltipFormatter}
          data-testid="cdr-traffic-volume-trend-chart"
        />
      </GridItem>
    </GridContainer>
  );
}
