import { Text } from '@athonet/ui/components/Guidelines/Text';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { ReactNode } from 'react';

type KeyValueProps = {
  label: string;
  value?: string | number | boolean | null;
  valueComponent?: ReactNode;
  fullwidth?: boolean;
};

export default function KeyValue({ label, value, valueComponent, fullwidth }: KeyValueProps) {
  return (
    <GridItem size={{ xs: 12, ...(!fullwidth && { sm: 6 }) }}>
      <Stack spacing={0}>
        <Text fontWeight="bold">{label}</Text>
        {valueComponent || <Text>{value || '-'}</Text>}
      </Stack>
    </GridItem>
  );
}
