import { useIntl } from 'react-intl';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { getDefaultValue } from 'store/models/filters';
import { useCallback, useMemo } from 'react';
import { FiltersDrawer, FiltersDrawerItem, FiltersProps } from '../Drawer';
import { string } from 'yup';
import { getTenantsAutocompleteOptions } from 'store/actions/tenants';
import { useDispatch } from 'react-redux';
import { filtersSet } from 'store/actions/usimOrders';

export default function UsimOrders({ values }: FiltersProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'status',
        label: formatMessage({ id: 'usims.orders.filters.status' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        initial: values['status'],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: string(),
        autocompleteOptions: [
          { label: formatMessage({ id: 'usims.orders.status.pending' }), value: 'pending' },
          { label: formatMessage({ id: 'usims.orders.status.processing' }), value: 'processing' },
          { label: formatMessage({ id: 'usims.orders.status.ready' }), value: 'ready' },
          { label: formatMessage({ id: 'usims.orders.status.updating' }), value: 'updating' },
          { label: formatMessage({ id: 'usims.orders.status.completed' }), value: 'completed' },
        ],
      },
      {
        name: 'tenant_id',
        label: formatMessage({ id: 'usims.orders.filters.tenant' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        initial: values['tenant_id'],
        schema: string(),
        getInitialOptions: async () => dispatch(getTenantsAutocompleteOptions()),
      },
      /*{
      name: "batchId",
      label: formatMessage({id:"usims.orders.filters.batchId"}),
      placeholder: formatMessage({id:"usims.orders.filters.batchId.placeholder"}),
    },
    {
      name: "range",
      fieldComponent: DateTimeRange,
      label: formatMessage({id:"usims.orders.filters.date"}),
      placeholder: formatMessage({id:"usims.orders.filters.date.placeholder"}),
      error: formatMessage({ id: 'traffic.filters.date.placeholder' }),*/
      //   range: DateTimeRangeInitial(),
      // range: DateTimeRangeSchema(),
    ],
    [dispatch, formatMessage, values]
  );

  const handleSubmit = useCallback((filtersValues) => dispatch(filtersSet(filtersValues)), [dispatch]);

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
