import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Autocomplete, AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { ToggleButton } from '@athonet/ui/components/Input/ToggleButton';
import { ToggleButtonGroup } from '@athonet/ui/components/Input/ToggleButton/ToggleButtonGroup';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { editNode5g } from 'store/actions/nodes';
import { showSuccessToast } from 'store/actions/toast';
import { Node } from 'store/models/node';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import getStatesCountries, { getStateContryGroupBy } from 'utils/getStatesCountries';
import { object, string } from 'yup';

export function EditNode5g({
  onSubmit,
  node,
  initials,
}: {
  onSubmit?: () => Promise<void>;
  node: Node;
  initials: Partial<Node>;
}) {
  const { dialogClose } = useOverlay();
  const bootstrap = useBootstrapSelector();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const dispatch = useDispatch();

  const fields = useMemo(() => Object.keys(initials), [initials]);

  const schema = useMemo(
    () =>
      object().shape({
        ...(fields.includes('display_name') && { display_name: string().required() }),
        ...(fields.includes('product_version') && { product_version: string() }),
        ...(fields.includes('address') && { address: string().nullable() }),
        ...(fields.includes('country') && {
          country: object().shape({ label: string(), value: string(), group: string() }).nullable(),
        }),
        ...(fields.includes('endpoint') && { endpoint: string().nullable() }),
        ...(fields.includes('username') && { username: string().nullable() }),
        ...(fields.includes('password') && { password: string().nullable() }),
      }),
    [fields]
  );

  const existingPassword = useMemo(() => Boolean('password' in initials && initials['password']), [initials]);

  const handleEdit = useCallback(
    async (values) => {
      const { display_name, pass_override, password, country, ...submitValues } = values;

      let editData: Partial<Node> = {
        name: display_name || node.display_name,
        display_name: display_name || node.display_name,
        product_version: node.product_version,
      };

      Object.keys(submitValues).forEach((key) => {
        const typedValue = submitValues[key as keyof Partial<Node>];
        if (typedValue !== undefined) {
          editData = { ...editData, [key]: typedValue === '' ? null : typedValue };
        }
      });

      if (fields.includes('country')) {
        editData = { ...editData, country: country?.label || null };
      }

      const resultError = await dispatch(
        editNode5g({ ...editData, ...(((!existingPassword && password) || pass_override) && { password }) }, node)
      );
      if (onSubmit) {
        await onSubmit();
      }
      setLoading(false);
      if (resultError) {
        setError(typeof resultError === 'string' ? resultError : formatMessage({ id: 'common.unknownError' }));
      } else {
        setError(null);
        dispatch(showSuccessToast());
        dialogClose();
      }
    },
    [dialogClose, dispatch, existingPassword, fields, formatMessage, node, onSubmit]
  );

  const getInputTextsError = useCallback(
    (inputname, errors, touched) => {
      const fieldName = inputname;
      return {
        label: formatMessage({ id: `nodes.form.${fieldName}` }),
        placeholder: formatMessage({ id: `nodes.form.${fieldName}.placeholder` }),
        error: Boolean(errors[inputname] && touched[fieldName]),
        ...(Boolean(errors[fieldName] && touched[fieldName]) && {
          helperText: formatMessage({ id: `nodes.form.${fieldName}.error` }),
        }),
      };
    },
    [formatMessage]
  );

  const countryOptions = useMemo(() => getStatesCountries(), []);

  const initialValues = useMemo(() => {
    return {
      ...initials,
      ...(existingPassword && { password: '', pass_override: false }),
      ...('country' in initials && {
        country: countryOptions.find((option) => option.label === initials.country) || null,
      }),
    };
  }, [countryOptions, existingPassword, initials]);

  if (!bootstrap) return null;
  return (
    <Formik initialValues={initialValues} onSubmit={handleEdit} validationSchema={schema}>
      {({ isSubmitting, values, errors, touched, setFieldValue }) => {
        return (
          <Form noValidate autoComplete="off">
            <Stack fullWidth sx={{ p: 2 }} spacing={2}>
              {fields.includes('display_name') && (
                <Field name="display_name">
                  {({ field }: FieldProps<string>) => (
                    <TextField size="small" {...field} {...getInputTextsError('name', errors, touched)} />
                  )}
                </Field>
              )}

              {fields.includes('address') && (
                <Field name="address">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      disabled={isSubmitting}
                      {...getInputTextsError('address', errors, touched)}
                    />
                  )}
                </Field>
              )}
              {fields.includes('country') && (
                <Field name="country">
                  {({ field }: FieldProps<AutocompleteItemProps>) => (
                    <Autocomplete
                      size="small"
                      {...field}
                      disabled={isSubmitting}
                      {...getInputTextsError('country', errors, touched)}
                      options={countryOptions}
                      groupBy={getStateContryGroupBy}
                      onChange={(_, v) => setFieldValue('country', v)}
                    />
                  )}
                </Field>
              )}
              {fields.includes('endpoint') && (
                <Field name="endpoint">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      disabled={isSubmitting}
                      {...getInputTextsError('endpoint', errors, touched)}
                    />
                  )}
                </Field>
              )}
              {fields.includes('username') && (
                <Field name="username">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      {...getInputTextsError('username', errors, touched)}
                      disabled={isSubmitting}
                    />
                  )}
                </Field>
              )}
              {'password' in initials && existingPassword && (
                <Stack direction="row" fullWidth align="baseline" nowrap spacing={2}>
                  <Text>{formatMessage({ id: 'nodes.form.pass_set' })}</Text>
                  <Field name="pass_override">
                    {({ field }: FieldProps<string>) => (
                      <ToggleButtonGroup
                        size="small"
                        color={'secondary'}
                        exclusive={true}
                        {...field}
                        onChange={(value) => {
                          if (value !== null) {
                            setFieldValue('pass_override', value);
                          }
                        }}
                      >
                        <ToggleButton value={false} disabled={values['pass_override'] === false}>
                          {formatMessage({ id: 'nodes.form.pass_override.no' })}
                        </ToggleButton>
                        <ToggleButton value={true} disabled={values['pass_override'] === true}>
                          {formatMessage({ id: 'nodes.form.pass_override.yes' })}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  </Field>
                </Stack>
              )}
              {fields.includes('password') && (!existingPassword || Boolean(values['pass_override'])) && (
                <Field name="password">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      size="small"
                      {...field}
                      {...getInputTextsError('password', errors, touched)}
                      {...(existingPassword && { label: formatMessage({ id: 'nodes.form.password.insert_new' }) })}
                      disabled={isSubmitting}
                      showPasswordVisibility
                      type="password"
                    />
                  )}
                </Field>
              )}
              {error && (
                <Box sx={{ width: '100%' }}>
                  <Alert
                    severity="error"
                    title={formatMessage({ id: 'common.error' })}
                    message={formatMessage({ id: 'common.edit.error' }, { message: error })}
                  />
                </Box>
              )}
            </Stack>
            <DialogActions>
              <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                <Button
                  variant="outlined"
                  data-testid="footer-cancel"
                  onClick={dialogClose}
                  text={formatMessage({ id: 'common.form.cancel' })}
                  disabled={isSubmitting || loading}
                  loading={isSubmitting || loading}
                />
                <Button
                  data-testid="footer-continue"
                  type="submit"
                  text={
                    error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })
                  }
                  disabled={isSubmitting || loading}
                  loading={isSubmitting || loading}
                />
              </Stack>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
