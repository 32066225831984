import { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SelectOption } from 'utils/forms';
import { useDispatch } from 'react-redux';
import { assignTenantToNode, deassignTenantToNode, getTenantsByNode } from 'store/actions/tenants';

export default function useNodeHandlers(selectOptions: { tenants?: SelectOption[] }, id: string) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleGetTenantsByNode = useCallback(async () => dispatch(getTenantsByNode(id)), [dispatch, id]);

  const handleAddTenantToNode = useCallback(
    async (tenantId) => dispatch(assignTenantToNode(id, tenantId)),
    [dispatch, id]
  );

  const handleDeleteTenantFromNode = useCallback(
    async (tenantId) => dispatch(deassignTenantToNode(id, tenantId)),
    [dispatch, id]
  );

  const nodeTitles = useMemo(
    () => ({
      modalTitle: formatMessage({ id: 'nodes.form.manageTenants' }),
      fieldsetTitle: formatMessage({ id: 'nodes.form.addTenants' }),
    }),
    [formatMessage]
  );

  const nodeFields = useMemo(
    () => [
      {
        name: 'tenant',
        label: formatMessage({ id: 'nodes.form.tenant' }),
        placeholder: formatMessage({ id: 'nodes.form.tenant.placeholder' }),
        error: formatMessage({ id: 'nodes.form.tenant.error' }),
        options: selectOptions.tenants,

        gridClassName: 'fullwidth',
      },
    ],
    [formatMessage, selectOptions]
  );

  return {
    handleAddTenantToNode,
    handleDeleteTenantFromNode,
    handleGetTenantsByNode,
    nodeFields,
    nodeTitles,
  };
}
