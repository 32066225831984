import { useCallback } from 'react';
import { FileUpload, FileUploadProps } from '@athonet/ui/components/Input/FileUpload';
import { array } from 'yup';

type UploadProps = {
  name: string;
  setFieldValue: (name: string, files: File[]) => void;
  maxFileSize: number;
  values: FileUploadProps['value'];
} & Pick<FileUploadProps, 'accept' | 'maxSize' | 'label'>;

export function Upload({
  name,
  values,
  setFieldValue,
  accept,
  label,
  maxFileSize = 10 * 1024 ** 2, // 10MB
}: UploadProps) {
  const handleChange = useCallback(
    (files: File[]) => {
      setFieldValue(name, files);
    },
    [setFieldValue, name]
  );

  return <FileUpload label={label} maxSize={maxFileSize} value={values} onChange={handleChange} accept={accept} />;
}

export const UploadSchema = () => array().min(1).max(1).required();

export const UploadErrorMessage = 'Upload a file';
