import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SecurityComponent from 'components/Security';
import Create from 'components/Edit/Security/Create';
import Edit from 'components/Edit/Security/Edit';
import async from 'async';
import getFetchHeaders from 'utils/fetchHeaders';
import { U_PERMISSIONS, checkPermissionsList, checkPermissionToUse } from 'utils/permissionCodes';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { useIntl } from 'react-intl';
import config from 'config';
import { useFetchData } from 'hooks/useFetchData';
import { sentryLogError } from 'sentry';

const Security = () => {
  const fetchData = useFetchData();
  const userdata = useSelector((state) => state.user);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [updateItem, setUpdateItem] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [error, setError] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { confirmationDialogOpen } = useOverlay();
  const [listLoading, setListLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setListLoading(true);
    const loadRoles = (callback) => {
      const options = {
        url: `${config.apis.getRoles}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          callback(null, result);
        })
        .catch((e) => {
          callback(e);
        });
    };

    const loadPermissions = (callback) => {
      const options = {
        url: `${config.apis.getPermissions}`,
        method: 'GET',
        headers: getFetchHeaders(userdata),
      };

      fetchData(options)
        .then((result) => {
          callback(null, result);
        })
        .catch((e) => {
          callback(e);
        });
    };

    const tasks = {
      roles: loadRoles,
      permissions: loadPermissions,
    };

    async.parallel(tasks, (err, results) => {
      setListLoading(false);
      if (err) {
        setError(true);
        sentryLogError(err);
      } else {
        setError(false);
        setRoles(results.roles);
        setPermissions(results.permissions);
      }
    });
  };

  const permissionMap = (permissionsValues) => {
    let arr = [];
    permissionsValues.map((item) => arr.push({ label: formatMessage({ id: `permissions.${item}` }), value: item }));

    return arr;
  };

  const onDelete = (item) => {
    const options = {
      url: `${config.apis.deleteRole.replace('{id}', item)}`,
      method: 'DELETE',
      headers: getFetchHeaders(userdata),
    };

    fetchData(options)
      .then((result) => {
        let updatedRoles = roles.map((role) => ({ ...role }));
        updatedRoles = updatedRoles.filter((role) => role.id !== result.id);
        setRoles(updatedRoles);
        dispatch(showSuccessToast());
      })
      .catch((e) => {
        sentryLogError(e);
        dispatch(showErrorToast());
      });
  };

  const onEdit = (values, callback) => {
    if (updateItem) {
      const permissionsValues = values.permissions.map(({ value }) => value);

      const data = {
        id: updateItem,
        role: values.role,
        permissions: permissionsValues,
      };

      const options = {
        url: `${config.apis.updateRole.replace('{id}', updateItem)}`,
        method: 'PUT',
        headers: getFetchHeaders(userdata),
        data: data,
      };

      fetchData(options)
        .then(() => {
          loadData();
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    } else {
      callback(false);
    }
  };

  const onSubmitCreate = (values, callback) => {
    const permissionsValues = values.permissions.map(({ value }) => value);

    const data = {
      role: values.role,
      permissions: permissionsValues,
    };

    const options = {
      url: `${config.apis.createRole}`,
      method: 'POST',
      headers: getFetchHeaders(userdata),
      data: data,
    };

    fetchData(options)
      .then((result) => {
        const updatedRoles = roles.map((role) => ({ ...role }));
        updatedRoles.push(result);
        setRoles(updatedRoles);

        callback(true);
      })
      .catch((e) => {
        sentryLogError(e);
        callback(false);
      });
  };

  const onCheckPermissionsList = (list) => checkPermissionsList([userdata.tenant_type], list);

  const onOpen = (id) => {
    setUpdateItem(id);
    setEditOpen(true);
  };

  const onClose = () => {
    setUpdateItem(null);
    setEditOpen(false);
  };

  const toggleDelete = (id) => {
    const roleName = roles.find((role) => role.id === id).name;

    confirmationDialogOpen({
      title: formatMessage({ id: 'security.deleteRole.confirm.title' }),
      description: formatMessage({ id: 'security.deleteRole.confirm' }),
      alertMessage: formatMessage({ id: 'security.actions.itemsAffected' }, { element: roleName }),
      continueButtonText: formatMessage({ id: 'security.deleteRole.confirm.continueButton' }, { elements: 1 }),
      onConfirm: () => onDelete(id),
      severity: 'danger',
      dataTestid: 'confirm-delete-role',
    });
  };

  return (
    <>
      {checkPermissionToUse(userdata, U_PERMISSIONS.READ_ROLE) && (
        <SecurityComponent
          createComponent={
            checkPermissionToUse(userdata, U_PERMISSIONS.CREATE_ROLE) && (
              <Create
                data={permissionMap(permissions)}
                onSubmit={onSubmitCreate}
                checkPermissions={onCheckPermissionsList}
              />
            )
          }
          roles={roles}
          userdata={userdata}
          disableEdit={!checkPermissionToUse(userdata, U_PERMISSIONS.UPDATE_ROLE)}
          // disableEdit={true}
          disableDelete={!checkPermissionToUse(userdata, U_PERMISSIONS.DELETE_ROLE)}
          toggleEdit={onOpen}
          toggleDelete={toggleDelete}
          message={
            error ? formatMessage({ id: `security.message.error` }) : formatMessage({ id: `security.message.void` })
          }
          loading={listLoading}
        />
      )}

      {editOpen && (
        <Edit
          data={roles.find(({ id }) => id === updateItem)}
          options={permissionMap(permissions)}
          isOpen={editOpen}
          onEdit={onEdit}
          checkPermissions={onCheckPermissionsList}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default Security;
