import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import ChangePasswordDialog from '../ChangePasswordDialog';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { Panel } from '@athonet/ui/components/Surfaces/Panel';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import KeyValue from 'components/Details/KeyValue';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useUserSelector } from 'store/selectors/user';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';

export default function Authentication() {
  const { formatMessage } = useIntl();
  const user = useUserSelector();
  const { menuOpen, menuClose, dialogOpen } = useOverlay();

  const openChangePassword = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'profile.changePassword' }),
      content: () => <ChangePasswordDialog />,
      'data-testid': 'changePasswordDialog',
    });
  }, [dialogOpen, formatMessage]);

  const openEditAuth = useCallback(
    (e) => {
      menuOpen({
        anchorEl: e.currentTarget,
        items: [
          {
            label: formatMessage({ id: 'profile.changePassword' }),
            value: 'change-password',
            onClick: () => {
              openChangePassword();
              menuClose();
            },
          },
        ],
      });
    },
    [formatMessage, menuClose, menuOpen, openChangePassword]
  );

  if (!user) {
    return null;
  }

  return (
    <Panel
      title={formatMessage({ id: 'profile.authentication' })}
      headerActionsComponent={
        <IconButton
          name="Other"
          onClick={(e) => openEditAuth(e)}
          fontSize="small"
          sx={{ transform: 'rotate(90deg)' }}
          disabled={user.realm !== AUTH_PROVIDER.ENTERPRISE}
          data-testid="auth-dropdown-tools"
        />
      }
    >
      <PanelContent>
        <Stack direction="row" align="center" nowrap>
          <GridContainer sx={{ overflowX: 'auto' }}>
            <KeyValue
              label={formatMessage({ id: 'profile.realm' })}
              value={formatMessage({ id: `authProvider.${user.realm}` })}
            />
            <KeyValue label={formatMessage({ id: 'profile.name' })} value={user.name} />
          </GridContainer>
        </Stack>
      </PanelContent>
    </Panel>
  );
}
