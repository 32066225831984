import { AxiosRequestConfig } from 'axios';
import config from 'config';
import {
  FiltersObj,
  generateGpgKeysFiltersQuery,
  getFiltersByQueryString,
  GetResultsOptions,
  GetResultsRes,
} from 'store/models/filters';
import { GpgKey } from 'store/models/gpgKey';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { sentryLogError } from 'sentry';

export enum GPGKEYS_ACTION_TYPE {
  FILTERS_SET = 'GPGKEYS_FILTERS_SET',
  SORT_SET = 'GPGKEYS_SORT_SET',
  LIST_LOADING = 'GPGKEYS_LIST_LOADING',
  LIST_SUCCESS = 'GPGKEYS_LIST_SUCCESS',
  LIST_FAILURE = 'GPGKEYS_LIST_FAILURE',
  RESET = 'GPGKEYS_RESET',
}

export function listLoading() {
  return {
    type: GPGKEYS_ACTION_TYPE.LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: GPGKEYS_ACTION_TYPE.LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: GPGKEYS_ACTION_TYPE.LIST_FAILURE,
  };
}

export function reset() {
  return {
    type: GPGKEYS_ACTION_TYPE.RESET,
  };
}

export function getGpgKeys({ page = 0, sortBy, filterBy }: GetResultsOptions): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    if (sortBy) {
      dispatch({
        type: GPGKEYS_ACTION_TYPE.SORT_SET,
        payload: sortBy,
      });
    }

    const newFilterBy: FiltersObj = filterBy || {
      ...getFiltersByQueryString(),
      ...getState().gpgKeys.filters,
    };

    dispatch({
      type: GPGKEYS_ACTION_TYPE.FILTERS_SET,
      payload: newFilterBy,
    });

    const {
      bootstrap,
      gpgKeys: { filters, sort },
    } = getState();

    dispatch({
      type: GPGKEYS_ACTION_TYPE.LIST_LOADING,
    });

    try {
      const query = encodeURI(generateGpgKeysFiltersQuery(filters, '&'));
      const options: AxiosRequestConfig = {
        url: `${config.apis.getGpgKeys
          .replace('{sort}', sort)
          .replace('{limit}', bootstrap?.pageLimit)
          .replace('{page}', page)
          .replace('{filters}', query)}`,
        method: 'GET',
      };

      const result = { ...(await dispatch(fetchData<GetResultsRes<GpgKey>>(options))), page };
      dispatch({
        type: GPGKEYS_ACTION_TYPE.LIST_SUCCESS,
        payload: result,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({
        type: GPGKEYS_ACTION_TYPE.LIST_FAILURE,
      });
    }
  };
}

type CreateGpgKeyPayload = {
  name: string;
  is_private?: boolean;
  upload: File[];
  passphrase?: string;
};

export function createGpgKey({ name, is_private, upload, passphrase }: CreateGpgKeyPayload): Thunk<Promise<void>> {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('private', String(is_private));
  formData.append('keyfile', upload[0]);
  if (is_private && passphrase) formData.append('passphrase', passphrase);

  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.createGpgKey,
      method: 'POST',
      data: formData,
    };

    await dispatch(fetchData(options));
    await dispatch(getGpgKeys({}));
  };
}

export function deleteGpgKey(id: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deleteGpgKey.replace('{id}', id),
      method: 'DELETE',
    };
    await dispatch(fetchData(options));
    await dispatch(getGpgKeys({}));
  };
}
