import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SelectOption } from 'utils/forms';
import { number, object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { assignTenantToUsimProfile, deassignTenantToUsimProfile, getTenantsByUsimProfile } from 'store/actions/tenants';

export default function useUsimProfileHandlers(
  selectOptions: { permissions?: SelectOption[]; tenants?: SelectOption[] },
  id: string
) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const usimProfileSchema = useMemo(
    () =>
      object().shape({
        tenant: string().required(),
        permissions: number().required(),
      }),
    []
  );

  const handleGetTenantsByUsimProfile = useCallback(async () => {
    const owners = await dispatch(getTenantsByUsimProfile(id));
    return owners;
  }, [dispatch, id]);

  const usimProfileFormTitles = useMemo(
    () => ({
      modalTitle: formatMessage({ id: 'usimProfiles.form.usimProfile.manageTenants.title' }),
      fieldsetTitle: formatMessage({ id: 'usimProfiles.form.usimProfile.manageTenants' }),
    }),
    [formatMessage]
  );

  const handleAddTenantToUsimProfile = useCallback(
    async (tenant_id: string, permissions: string) => {
      return dispatch(
        assignTenantToUsimProfile(id, {
          tenant_id,
          permissions,
        })
      );
    },
    [dispatch, id]
  );

  const handleDeleteTenantFromUsimProfile = useCallback(
    async (tenantId) => {
      return dispatch(deassignTenantToUsimProfile(id, tenantId));
    },
    [dispatch, id]
  );

  const usimProfilefields = useMemo(
    () => [
      {
        name: 'tenant',
        label: formatMessage({ id: 'usimProfiles.form.usimProfile.tenant_id' }),
        placeholder: formatMessage({ id: 'usimProfiles.form.usimProfile.tenant_id.placeholder' }),
        error: formatMessage({ id: 'usimProfiles.form.usimProfile.tenant_id.error' }),
        options: selectOptions.tenants,
        gridClassName: 'fullwidth',
      },
      {
        name: 'permissions',
        label: formatMessage({ id: 'usimProfiles.form.usimProfile.permissions' }),
        placeholder: formatMessage({ id: 'usimProfiles.form.usimProfile.permissions.placeholder' }),
        error: formatMessage({ id: 'usimProfiles.form.usimProfile.permissions.error' }),
        gridClassName: 'fullwidth',
        options: [
          { value: 255, label: formatMessage({ id: 'usimProfiles.form.usimProfile.permissions.readWrite' }) },
          { value: 1, label: formatMessage({ id: 'usimProfiles.form.usimProfile.permissions.read' }) },
        ],
      },
    ],
    [formatMessage, selectOptions]
  );

  return {
    handleGetTenantsByUsimProfile,
    handleAddTenantToUsimProfile,
    handleDeleteTenantFromUsimProfile,
    usimProfileSchema,
    usimProfileFormTitles,
    usimProfilefields,
  };
}
