import { Button } from '@athonet/ui/components/Input/Button';
import { Select } from '@athonet/ui/components/Input/Select';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { ToggleButton } from '@athonet/ui/components/Input/ToggleButton';
import { ToggleButtonGroup } from '@athonet/ui/components/Input/ToggleButton/ToggleButtonGroup';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Usim5g } from 'store/models/usim5g';
import { object, string, number } from 'yup';
import { keysRegExp } from 'store/models/usim';
import { useDispatch } from 'react-redux';
import { editSingleUsim5g } from 'store/actions/usims5g';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Text } from '@athonet/ui/components/Guidelines/Text';

export function EditUsimCard5g({ usim5g }: { usim5g: Usim5g }) {
  const { formatMessage } = useIntl();
  const { dialogClose, snackbarClose } = useOverlay();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const dispatch = useDispatch();

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().required(),
        k: string().matches(keysRegExp),
        /* TODO: update when encryption is contemplated again */
        key_type: number().min(1).max(2),
        /* TODO: update when encryption is contemplated again */
        encrypt: number().min(0).max(0),
        // use_key: string().when(['encrypt', 'use_default_tk'], {
        //   is: (encryptValue, use_default_tk) => encryptValue === 1 && !use_default_tk,
        //   then: string().matches(tkRegExp),
        //   otherwise: string().nullable().matches(tkRegExp),
        // }),
        op: string().when(['key_type', 'key_override'], {
          is: (key_type, key_override) => key_type === 1 && (!usim5g['op'] || key_override),
          then: string().matches(keysRegExp).required(),
          otherwise: string().nullable().matches(keysRegExp),
        }),
        opc: string().when(['key_type', 'key_override'], {
          is: (key_type, key_override) => key_type === 2 && (!usim5g['opc'] || key_override),
          then: string().matches(keysRegExp).required(),
          otherwise: string().nullable().matches(keysRegExp),
        }),
      }),
    [usim5g]
  );

  const getInputTextsError = useCallback(
    (inputname, errors, touched) => ({
      label: formatMessage({ id: `usims.form.usim.${inputname}` }),
      placeholder: formatMessage({ id: `usims.form.usim.${inputname}.placeholder` }),
      error: Boolean(errors[inputname] && touched[inputname]),
      ...(Boolean(errors[inputname] && touched[inputname]) && {
        helperText: formatMessage({ id: `usims.form.usim.${inputname}.error` }),
      }),
    }),

    [formatMessage]
  );

  const getKeysInputTextsError = useCallback(
    (inputname: keyof Usim5g, errors, touched) => ({
      label:
        usim5g && usim5g[inputname]
          ? formatMessage({ id: `usims.form.usim.${inputname}.alreadySet` })
          : formatMessage({ id: `usims.form.usim.${inputname}` }),

      placeholder:
        usim5g && usim5g[inputname]
          ? formatMessage({ id: `usims.form.usim.${inputname}.alreadySetPlaceholder` })
          : formatMessage({ id: `usims.form.usim.${inputname}.placeholder` }),
      error: Boolean(errors[inputname] && touched[inputname]),
      ...(Boolean(errors[inputname] && touched[inputname]) && {
        helperText: formatMessage({ id: `usims.form.usim.${inputname}.error` }),
      }),
    }),
    [formatMessage, usim5g]
  );

  const handleKeyTypeChange = useCallback((e, setFieldValue) => {
    const v = e.target.value;
    setFieldValue('key_type', v);
    if (v === 0) {
      setFieldValue('op', '');
      setFieldValue('opc', '');
    }
    // OP
    if (v === 1) {
      setFieldValue('opc', '');
    }
    // OPC
    if (v === 2) {
      setFieldValue('op', '');
    }
  }, []);

  const initials = useMemo(
    () => ({
      ...usim5g,
      op: '',
      opc: '',
      k: '',
      key_type: !usim5g.op && !usim5g.opc ? 0 : usim5g.op && !usim5g.opc ? 1 : 2,
      key_override: false,
      use_default_tk: Boolean(!usim5g['use_key']),
    }),
    [usim5g]
  );

  const handleEditUsim = useCallback(
    /* TODO: update when encryption is contemplated again */

    async ({ name, k, key_type, op, opc /*encrypt, use_default_tk, use_key*/ }) => {
      setLoading(true);
      const resultError = await dispatch(
        editSingleUsim5g({ data: { name, k, key_type, op, opc /*encrypt, use_default_tk, use_key */ }, usim5g })
      );
      if (resultError) {
        setError(typeof resultError === 'string' ? resultError : formatMessage({ id: 'common.unknownError' }));
        snackbarClose();
      } else {
        setError(null);
        dialogClose();
      }
      setLoading(false);
    },
    [dialogClose, dispatch, formatMessage, snackbarClose, usim5g]
  );

  return (
    <>
      <Text type="body2" color="text.secondary" sx={{ px: 2 }}>
        {formatMessage({ id: 'usims.actions.edit.subtitle' }, { usim: usim5g.supi })}
      </Text>
      <Formik
        initialValues={initials}
        enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
        onSubmit={handleEditUsim}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ errors, setFieldValue, values, touched }) => {
          return (
            <Form
              noValidate
              autoComplete="off"
              style={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <DialogContent>
                <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
                  <Field name="name" key="name">
                    {({ field }: FieldProps<string>) => (
                      <TextField
                        required
                        fullWidth
                        {...field}
                        size="small"
                        {...getInputTextsError('name', errors, touched)}
                      />
                    )}
                  </Field>
                  <Field name="k" key="k">
                    {({ field }: FieldProps<string>) => (
                      <TextField fullWidth {...field} size="small" {...getKeysInputTextsError('k', errors, touched)} />
                    )}
                  </Field>
                  <Field name="encrypt" key="encrypt">
                    {({ field }: FieldProps<string>) => (
                      <Select
                        {...field}
                        size="small"
                        label={formatMessage({ id: 'usims.form.usim.encrypt' })}
                        onChange={(e) => setFieldValue('encrypt', e.target.value)}
                        multiple={false}
                        /* TODO: update when encryption is contemplated again */
                        disabled
                      >
                        {/* <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.encrypt.1' })}</MenuItem> */}
                        <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.encrypt.0' })}</MenuItem>
                      </Select>
                    )}
                  </Field>
                  {values['encrypt'] === 1 && (
                    <Field name="use_default_tk" key="use_default_tk">
                      {({ field }: FieldProps<string>) => (
                        <ToggleButtonGroup
                          {...field}
                          sx={{ alignSelf: 'flex-start' }}
                          key="use_default_tk"
                          size="small"
                          color={'secondary'}
                          exclusive={true}
                          onChange={(v) => {
                            if (v !== null) {
                              setFieldValue('use_default_tk', v);
                            }
                          }}
                          data-testid={'field-use_default_tk'}
                        >
                          <ToggleButton disabled={values['use_default_tk'] === true} value={true}>
                            {formatMessage({ id: 'usims.form.usim.use_default_tk_5g.true' })}
                          </ToggleButton>
                          <ToggleButton disabled={values['use_default_tk'] === false} value={false}>
                            {formatMessage({ id: 'usims.form.usim.use_default_tk_5g.false' })}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    </Field>
                  )}
                  {/* /* TODO: update when encryption is contemplated again */}
                  {/* {values['encrypt'] === 1 && !values['use_default_tk'] && (
                    <Field name="use_key" key="use_key">
                      {({ field }: FieldProps<string>) => (
                        <TextField
                          fullWidth
                          size="small"
                          {...field}
                          {...getInputTextsError('use_key', errors, touched)}
                        />
                      )}
                    </Field>
                  )} */}
                  <Field name="key_type" key="key_type">
                    {({ field }: FieldProps<string>) => (
                      <Select
                        size="small"
                        {...field}
                        label={formatMessage({ id: `usims.form.usim.key_type` })}
                        value={values['key_type']}
                        onChange={(e) => handleKeyTypeChange(e, setFieldValue)}
                      >
                        {/* /* TODO: update when encryption is contemplated again */}
                        {/* <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.key_type.defaultOP_5g' })}</MenuItem> */}
                        <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.key_type.customOP' })}</MenuItem>
                        <MenuItem value={2}>{formatMessage({ id: 'usims.form.usim.key_type.customOPC' })}</MenuItem>
                      </Select>
                    )}
                  </Field>
                  {Boolean(
                    (values['key_type'] === 1 && usim5g['op']) || (values['key_type'] === 2 && usim5g['opc'])
                  ) && (
                    <Field name="key_override" key="key_override">
                      {({ field }: FieldProps<boolean>) => (
                        <ToggleButtonGroup
                          size="small"
                          color={'secondary'}
                          exclusive={true}
                          {...field}
                          onChange={(value) => {
                            setFieldValue('key_override', value);
                            if (!value) {
                              setFieldValue('op', '');
                              setFieldValue('opc', '');
                            }
                          }}
                        >
                          <ToggleButton value={false}>
                            {formatMessage({ id: 'usims.form.usim.key_override.no' })}
                          </ToggleButton>
                          <ToggleButton value={true}>
                            {formatMessage({ id: 'usims.form.usim.key_override.yes' })}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    </Field>
                  )}
                  {values['key_type'] === 1 && (values['key_override'] === true || !usim5g['op']) && (
                    <Field name="op" key="op">
                      {({ field }: FieldProps<string>) => (
                        <TextField
                          fullWidth
                          size="small"
                          {...field}
                          {...getKeysInputTextsError('op', errors, touched)}
                        />
                      )}
                    </Field>
                  )}
                  {values['key_type'] === 2 && (values['key_override'] === true || !usim5g['opc']) && (
                    <Field name="opc" key="opc">
                      {({ field }: FieldProps<string>) => (
                        <TextField
                          fullWidth
                          size="small"
                          {...field}
                          {...getKeysInputTextsError('opc', errors, touched)}
                        />
                      )}
                    </Field>
                  )}
                  {error && (
                    <Box sx={{ width: '100%' }}>
                      <Alert
                        severity="error"
                        title="Error"
                        message={formatMessage({ id: 'usims.actions.edit.errorMessage.single' }, { message: error })}
                      />
                    </Box>
                  )}
                </Stack>
              </DialogContent>

              <DialogActions>
                <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                  <Button
                    variant="outlined"
                    data-testid="footer-cancel"
                    onClick={dialogClose}
                    text={formatMessage({ id: 'common.form.cancel' })}
                    loading={loading}
                  />
                  <Button
                    data-testid="footer-continue"
                    type="submit"
                    text={
                      error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })
                    }
                    loading={loading}
                  />
                </Stack>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
