// React
import { useEffect, useState } from 'react';

// External Components
import { object, string, boolean } from 'yup';

// Intl
import { useIntl } from 'react-intl';

// App Components
import Base from 'components/Edit/Base';

// Style
import { FormStyled } from '../styled';

const Download = ({ isOpen, onClose, data, onDownload }) => {
  const { formatMessage } = useIntl();
  const [gpgDisabled, setGpgDisabled] = useState(true);
  const [fieldlist, setFieldlist] = useState();

  const toggleGpg = (setFieldValue, val) => {
    setGpgDisabled(val.target.value === false);
  };

  useEffect(() => {
    setFieldlist(fieldset);
  }, [, gpgDisabled]);

  const fileTypes = [
    { value: 'zip', label: formatMessage({ id: 'usims.orders.form.order.download.fileTypes.zip' }) },
    { value: 'tar', label: formatMessage({ id: 'usims.orders.form.order.download.fileTypes.tar' }) },
  ];

  const fieldset = [
    {
      title: formatMessage({ id: 'usims.orders.form.order.download.settings' }),
      data: [
        {
          name: 'file_type',
          label: formatMessage({ id: 'usims.orders.form.order.download.fileType' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.download.fileType.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.download.fileType.error' }),
          gridClassName: 'fullwidth',
          options: fileTypes,
          disabled: true,
        },
        {
          name: 'encrypted',
          label: formatMessage({ id: 'usims.orders.form.order.download.encryptionLevel' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.download.encryptionLevel.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.download.encryptionLevel.error' }),
          gridClassName: 'fullwidth',
          options: [
            { value: false, label: formatMessage({ id: 'usims.orders.form.order.download.plainText' }) },
            { value: true, label: formatMessage({ id: 'usims.orders.form.order.download.encrypted' }) },
          ],
          onChange: toggleGpg,
        },
        {
          name: 'key_id',
          label: formatMessage({ id: 'usims.orders.form.order.download.gpgkey' }),
          placeholder: formatMessage({ id: 'usims.orders.form.order.download.gpgkey.placeholder' }),
          error: formatMessage({ id: 'usims.orders.form.order.download.gpgkey.error' }),
          gridClassName: 'fullwidth',
          options: data.keys,
          disabled: gpgDisabled,
        },
      ],
    },
  ];

  const schema = object().shape({
    encrypted: boolean().required(),
    file_type: string().required(),
    key_id: string().when('encrypted', {
      is: true,
      then: (fieldSchema) => fieldSchema.required(),
    }),
  });

  const initials = {
    encrypted: false,
    key_id: '',
    file_type: 'zip',
  };

  return (
    <FormStyled>
      <Base
        isOpen={isOpen}
        onClose={onClose}
        schema={schema}
        initials={initials}
        fieldset={fieldlist}
        onSubmit={onDownload}
        modalTitle={formatMessage({ id: 'usims.orders.form.order.download' })}
      />
    </FormStyled>
  );
};

export default Download;
