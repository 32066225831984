import { PageLayout } from '@athonet/ui/components/PageLayout';
import { AppBar } from '@athonet/ui/components/Surfaces/AppBar';
import { NavigationBurger } from '@athonet/ui/components/Navigation/Drawer/NavigationBurger';
import Navigation from 'containers/Navigation';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { ProfileCard } from '@athonet/ui/components/Persona/ProfileCard';
import { MenuStateItems } from '@athonet/ui/store/atoms/overlay/menu';
import { Breadcrumbs } from '@athonet/ui/components/Navigation/Breadcrumbs';
import { useUserSelector } from 'store/selectors/user';
import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'store/actions/login';
import { RESOURCE } from 'store/models/modal';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Button } from '@athonet/ui/components/Input/Button';
import { useBulkOperations, useInProgressBulkOperationCounter } from 'store/selectors/bulkOperations';
import { Badge } from '@athonet/ui/components/Feedback/Badge';
import { isEntityLoading } from 'store/reducers';
import LogoUpload from 'components/Modals/LogoUpload';
import { DetailPageParams } from 'Router';
import { OperationsDrawerContent } from 'containers/Operations';
import { checkPermissionsList, U_PERMISSIONS } from 'utils/permissionCodes';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { Icon54 } from '@athonet/ui/components/Guidelines/Icon/catalog';

type LayoutProps = {
  fullHeight?: true;
};

function AppBarBreadcrumbs() {
  const { pathname } = useLocation();
  const { id } = useParams<DetailPageParams>();
  const { formatMessage } = useIntl();

  const breadcrumbsRail = useMemo((): {
    path: string;
    label: string;
  }[] => {
    const rail = pathname.split('/').filter((path) => Boolean(path) && path !== id);

    const railArray = rail.map((path, i) => {
      let label = '';
      if (i === 0) {
        label = formatMessage({ id: `menu.${path}` });
      } else {
        label = formatMessage({
          id: `menu.${rail.slice(0, i).join('.')}.${path}`, // TODO: add proper translation on tabs
        });
      }
      return {
        path,
        label,
      };
    });
    return railArray.filter((railItem) => Boolean(railItem.label));
  }, [formatMessage, id, pathname]);

  return (
    <Breadcrumbs>
      {breadcrumbsRail.map((item) => (
        <span key={item.label}>{item.label}</span>
      ))}
    </Breadcrumbs>
  );
}

export default function Layout({ fullHeight }: LayoutProps) {
  const userdata = useUserSelector();
  const inProgressBulkOperationCounter = useInProgressBulkOperationCounter();
  const bulkOperations = useBulkOperations();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { menuClose, drawerOpen, dialogOpen, menuOpen } = useOverlay();
  const bootstrap = useBootstrapSelector();

  const handleLogout = useCallback(() => {
    menuClose();
    dispatch(logoutUser());
  }, [dispatch, menuClose]);

  const handleBranding = useCallback(() => {
    if (!userdata || !userdata?.tenant_id) {
      return;
    }
    menuClose();
    dialogOpen({
      title: `${formatMessage({ id: 'tenants.form.logoUpload.title' })} ${userdata.tenant_name}`,
      content: () => (userdata.tenant_id ? <LogoUpload resource={RESOURCE.USERS} id={userdata.tenant_id} /> : <></>),
      closeOnBackdropClick: false,
    });
  }, [dialogOpen, formatMessage, menuClose, userdata]);

  const handleNavigateToMenuItem = useCallback(
    (value: string) => {
      navigate(value);
      menuClose();
    },
    [menuClose, navigate]
  );

  const profileMenuItems = useMemo((): MenuStateItems => {
    const palette_icon: Icon54 = 'Palette';
    return [
      {
        label: formatMessage({ id: 'user.menu.profile' }),
        value: 'profile',
        iconName: 'Male-Circle-1',
        onClick: () => handleNavigateToMenuItem('/profile'),
      },
      ...(bootstrap && bootstrap.branding_enabled
        ? [
            {
              label: formatMessage({ id: 'user.menu.branding' }),
              value: 'branding',
              iconName: palette_icon,
              onClick: handleBranding,
            },
          ]
        : []),
      {
        label: 'Logout',
        value: 'logout',
        iconName: 'Logout',
        onClick: handleLogout,
      },
    ];
  }, [formatMessage, handleBranding, bootstrap, handleLogout, handleNavigateToMenuItem]);

  const handleOperationsDrawerOpen = useCallback(() => {
    drawerOpen({
      title: 'Operations',
      content: () => <OperationsDrawerContent />,
    });
  }, [drawerOpen]);

  const settingsMenuItems: MenuStateItems = useMemo(
    () =>
      checkPermissionsList(
        [userdata?.tenant_type].concat(userdata?.permissions),
        [
          {
            label: formatMessage({ id: 'menu.settings.tenants' }),
            iconName: 'User-Server',
            value: '/settings/tenants',
            permissions: [U_PERMISSIONS.VIEW_TENANT_MODULE, U_PERMISSIONS.READ_TENANT],
            onClick: () => handleNavigateToMenuItem('/settings/tenants'),
          },
          {
            label: formatMessage({ id: 'menu.settings.users' }),
            iconName: 'Male-and-Female-1',
            value: '/settings/users',
            permissions: [U_PERMISSIONS.VIEW_USER_MODULE, U_PERMISSIONS.READ_USER],
            onClick: () => handleNavigateToMenuItem('/settings/users'),
          },
          {
            label: formatMessage({ id: 'menu.settings.roles' }),
            iconName: 'User-Hierarchy',
            value: '/settings/roles',
            permissions: [U_PERMISSIONS.VIEW_SECURITY_MODULE, U_PERMISSIONS.READ_ROLE],
            onClick: () => handleNavigateToMenuItem('/settings/roles'),
          },
        ],
        true
      ),
    [formatMessage, handleNavigateToMenuItem, userdata]
  );

  const handleSettingsMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      menuOpen({
        anchorEl: event.currentTarget,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        items: settingsMenuItems,
      });
    },
    [menuOpen, settingsMenuItems]
  );

  return (
    <>
      <AppBar color="white" navigation>
        <Stack align="center" justify="space-between" direction="row" fullWidth nowrap>
          <Stack direction="row" align="center" nowrap>
            <NavigationBurger />
            <AppBarBreadcrumbs />
          </Stack>
          <Stack direction="row" align="center" spacing={3}>
            <Badge
              variant="standard"
              badgeContent={inProgressBulkOperationCounter}
              invisible={!inProgressBulkOperationCounter}
            >
              <Button
                text={formatMessage({ id: 'menu.operations' })}
                onClick={handleOperationsDrawerOpen}
                variant="outlined"
                startIcon="Update-Time"
                size="small"
                loading={!bulkOperations.entity.data && isEntityLoading(bulkOperations.entity)}
              />
            </Badge>
            <Button
              text={formatMessage({ id: 'menu.settings' })}
              onClick={handleSettingsMenuOpen}
              variant="outlined"
              iconVariant="solid"
              startIcon="Gear-1"
              size="small"
              disabled={!settingsMenuItems.length}
            />
            <ProfileCard
              fullname={userdata?.fullname || ''}
              image={userdata?.picture}
              menuItems={profileMenuItems}
              hideFullName={true}
            />
          </Stack>
        </Stack>
      </AppBar>
      <Navigation />
      <PageLayout appBar navigation fullWidth fullHeight={fullHeight}>
        <Outlet />
      </PageLayout>
    </>
  );
}

export function FullHeightLayout(props: LayoutProps) {
  return <Layout {...props} fullHeight />;
}
