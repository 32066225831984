import Page404 from 'containers/Errors/Page404';
import { Fragment, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@athonet/ui/components/Input/Button';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { DetailPageParams } from 'Router';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { getProfile } from 'store/actions/usimProfiles';
import { useDispatch } from 'react-redux';
import { getStatusType, UsimProfile } from 'store/models/usimProfile';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import KeyValue from 'components/Details/KeyValue';
import { useUsimProfileEntitySelector } from 'store/selectors/usims/usimProfiles';
import { useIntl } from 'react-intl';
import { bitRateToString } from 'utils/byteConverter';
import { STATUS, Status } from '@athonet/ui/components/Feedback/Status';
import { Divider } from '@athonet/ui/components/Layout/Divider';
import moment from 'moment';
import { humanizeSnakeCase } from 'utils/string';
import {
  useCanUserDeleteUsimProfileSelector,
  useCanUserUpdateUsimProfileSelector,
  useHasUserCPMasterTenantSelector,
} from 'store/selectors/user';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { usePollOperation } from 'hooks/usePollOperation';
import { isEntityFailure } from 'store/reducers';
import { MapBox } from '@athonet/ui/components/Data/MapBox';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { DetailPageSkeleton } from 'components/Skeletons/DetailPageSkeleton';

type UsimProfileDetailProps = {
  handleOpenEdit: (rowData: UsimProfile) => void;
  handleOpenManageNodes: (rowData: UsimProfile) => void;
  handleOpenManageTenants: (profileId: UsimProfile['id']) => void;
  /* NOTE: CC-1285 */
  handleOpenManageSegments: (rowData: UsimProfile) => void;
  handleOpenChangeSite: (rowData: UsimProfile) => void;
  handleOpenDelete: (rowData: UsimProfile) => void;
  gets6aNamText: (nam: number) => string | number;
  gets6dgrNamText: (nam: number) => string | number;
};

export default function UsimProfileDetail({
  handleOpenEdit,
  handleOpenManageNodes,
  handleOpenManageTenants,
  handleOpenManageSegments,
  handleOpenChangeSite,
  handleOpenDelete,
  gets6aNamText,
  gets6dgrNamText,
}: UsimProfileDetailProps) {
  const { id } = useParams<DetailPageParams>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usimProfileEntity = useUsimProfileEntitySelector();
  const { formatMessage } = useIntl();
  const { clearPollOperation } = usePollOperation();
  const canUserDeleteUsimProfile = useCanUserDeleteUsimProfileSelector();
  const canUserUpdateUsimProfile = useCanUserUpdateUsimProfileSelector();
  const hasUserCPMasterTenant = useHasUserCPMasterTenantSelector();
  const bootstrap = useBootstrapSelector();

  useEffect(() => {
    void dispatch(getProfile(id as string));

    return () => {
      clearPollOperation();
    };
  }, [clearPollOperation, dispatch, id]);

  const entity = useMemo(() => {
    return usimProfileEntity.data;
  }, [usimProfileEntity]);

  const siteMarker = useMemo(() => {
    if (!entity?.site || !entity.site.coords) {
      return;
    }

    return [
      {
        address: `${entity.site.address}, ${entity.site.country}`,
        text: entity.site.name,
        center: entity.site.coords.split(','),
      },
    ];
  }, [entity?.site]);

  if (!id || isEntityFailure(usimProfileEntity)) {
    return <Page404 message="page404.detail.message" intlMessage={true} />;
  }

  return (
    <>
      <Toolbar>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          text="Back"
          variant="text"
          color="secondary"
          startIcon="Left-7"
        />
        {entity && (
          <Box sx={{ marginLeft: 'auto' }}>
            <Button
              text={formatMessage({ id: 'common.button.delete' })}
              color="error"
              onClick={() => handleOpenDelete(entity)}
              disabled={!canUserDeleteUsimProfile}
            />
          </Box>
        )}
      </Toolbar>
      {!entity ? (
        <DetailPageSkeleton />
      ) : (
        <GridContainer sx={{ pt: 0.5 }}>
          <GridItem size={{ xs: 12, md: 6 }}>
            <Stack spacing={2}>
              <Panel
                title="General Data"
                headerActionsComponent={
                  <IconButton
                    name="Pencil"
                    onClick={() => handleOpenEdit(entity)}
                    fontSize="small"
                    disabled={!canUserUpdateUsimProfile}
                  />
                }
              >
                <PanelContent>
                  <GridContainer>
                    <KeyValue
                      label={formatMessage({ id: 'usimProfiles.table.name' })}
                      value={entity.name.toUpperCase()}
                      fullwidth
                    />
                    <KeyValue
                      label={formatMessage({ id: 'usimProfiles.table.ue_ambr_ul' })}
                      value={bitRateToString(entity.ue_ambr_ul)}
                    />
                    <KeyValue
                      label={formatMessage({ id: 'usimProfiles.table.ue_ambr_dl' })}
                      value={bitRateToString(entity.ue_ambr_dl)}
                    />
                    <KeyValue
                      label={formatMessage({ id: 'usimProfiles.table.s6a_nam' })}
                      value={gets6aNamText(entity.s6a_nam)}
                    />
                    <KeyValue
                      label={formatMessage({ id: 'usimProfiles.table.s6dgr_nam' })}
                      value={gets6dgrNamText(entity.s6dgr_nam)}
                    />
                    <KeyValue
                      label={formatMessage({ id: 'usimProfiles.table.roaming_allowed' })}
                      valueComponent={
                        <Status
                          label={
                            entity.roaming_allowed === 1
                              ? formatMessage({ id: 'common.form.allowed' })
                              : formatMessage({ id: 'common.form.notAllowed' })
                          }
                          status={entity.roaming_allowed === 1 ? STATUS.SUCCESS : STATUS.ERROR}
                        />
                      }
                    />
                    {/* TODO: usimProfile model needs to be updated with proper shape to be able to display regional_subscriptions_profile */}
                    {/* <KeyValue
                      label={formatMessage({ id: 'usimProfiles.table.regional_subscriptions_profileName' })}
                      value={entity.regional_subscriptions_profile?.name}
                      fullwidth
                    /> */}
                  </GridContainer>
                </PanelContent>
              </Panel>
              <Panel
                title={formatMessage({ id: 'usimProfiles.table.nodes' })}
                headerActionsComponent={
                  <IconButton
                    name="Pencil"
                    onClick={() => handleOpenManageNodes(entity)}
                    fontSize="small"
                    disabled={!canUserUpdateUsimProfile}
                  />
                }
              >
                <PanelContent>
                  <GridContainer>
                    {entity.nodes.map(
                      (node, i) =>
                        node && (
                          <Fragment key={node.id}>
                            <KeyValue label="Name" value={node.display_name} />
                            <KeyValue
                              label="Status"
                              valueComponent={
                                <Status
                                  status={getStatusType(node.status, node.updating)}
                                  label={humanizeSnakeCase(node.status)}
                                  asChip
                                />
                              }
                            />
                            {i + 1 < entity.nodes.length && (
                              <GridItem size={{ xs: 12 }}>
                                <Divider />
                              </GridItem>
                            )}
                          </Fragment>
                        )
                    )}
                  </GridContainer>
                </PanelContent>
              </Panel>
              <Panel
                title={formatMessage({ id: 'usimProfiles.table.siteName' })}
                headerActionsComponent={
                  <IconButton
                    name="Pencil"
                    onClick={() => handleOpenChangeSite(entity)}
                    fontSize="small"
                    disabled={!canUserUpdateUsimProfile}
                  />
                }
              >
                <PanelContent>
                  <GridContainer sx={{ mb: 2 }}>
                    <KeyValue label="Site" value={entity.site ? entity.site.name : null} />
                    {entity.site?.address && (
                      <KeyValue
                        label="Address"
                        value={entity.site ? `${entity.site.address}, ${entity.site.country}` : null}
                      />
                    )}
                  </GridContainer>
                  {siteMarker && bootstrap && bootstrap.mapbox_token && bootstrap.mapbox_styles && (
                    <MapBox
                      id="usimProfile-detail-map"
                      centerAddress={siteMarker[0].center}
                      height={260}
                      locations={siteMarker}
                      markers
                      zoom={5}
                      data-testid="usimProfile-detail-map"
                      accessToken={bootstrap.mapbox_token}
                      stylesUrl={bootstrap.mapbox_styles}
                    />
                  )}
                </PanelContent>
              </Panel>
              <Panel title="Creation Details">
                <PanelContent>
                  <GridContainer>
                    <KeyValue
                      label={formatMessage({ id: 'usimProfiles.table.created' })}
                      value={moment(entity.updated_at).format('MMMM DD, YYYY HH:mm:ss')}
                    />
                    <KeyValue
                      label={formatMessage({ id: 'usimProfiles.table.updated' })}
                      value={moment(entity.updated_at).format('MMMM DD, YYYY HH:mm:ss')}
                    />
                  </GridContainer>
                </PanelContent>
              </Panel>
            </Stack>
          </GridItem>
          <GridItem size={{ xs: 12, md: 6 }}>
            <Stack spacing={2}>
              <Panel title={formatMessage({ id: 'usimProfiles.table.status' })}>
                <PanelContent>
                  <Status status={getStatusType(entity.status)} label={humanizeSnakeCase(entity.status)} asChip />
                </PanelContent>
              </Panel>
              {hasUserCPMasterTenant && (
                <Panel
                  title={formatMessage({ id: 'usimProfiles.table.tenants' })}
                  headerActionsComponent={
                    <IconButton
                      name="Pencil"
                      fontSize="small"
                      onClick={() => handleOpenManageTenants(id)}
                      disabled={!canUserUpdateUsimProfile}
                    />
                  }
                >
                  <PanelContent>
                    {entity.owners.length
                      ? entity.owners.map((owner, i) => (
                          <Fragment key={owner.id}>
                            <KeyValue label="Name" value={owner.name} />
                            {i + 1 < entity.owners.length && (
                              <GridItem size={{ xs: 12 }} sx={{ mt: 1, mb: 1 }}>
                                <Divider />
                              </GridItem>
                            )}
                          </Fragment>
                        ))
                      : '-'}
                  </PanelContent>
                </Panel>
              )}
              <Panel
                title={formatMessage({ id: 'usimProfiles.table.segments' })}
                headerActionsComponent={
                  <IconButton
                    name="Pencil"
                    fontSize="small"
                    onClick={() => handleOpenManageSegments(entity)}
                    disabled={!canUserUpdateUsimProfile}
                  />
                }
              >
                <PanelContent>
                  {/*  NOTE: CC-1285  */}
                  {entity.segments.map((segment, i) => (
                    <GridContainer key={segment.id}>
                      <KeyValue
                        label="Name"
                        valueComponent={
                          <Link
                            onClick={() => {
                              navigate(`/4g-provisioning/apn-profiles/${segment.id}`);
                            }}
                          >
                            <Text>{segment.name}</Text>
                          </Link>
                        }
                      />
                      <KeyValue
                        label="Status"
                        valueComponent={
                          <Status status={getStatusType(segment.status)} label={humanizeSnakeCase(segment.status)} />
                        }
                      />

                      {i + 1 < entity.segments.length && (
                        <GridItem size={{ xs: 12 }} sx={{ mt: 1, mb: 1 }}>
                          <Divider />
                        </GridItem>
                      )}
                    </GridContainer>
                  ))}
                </PanelContent>
              </Panel>
            </Stack>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
