import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { EnvironmentConfiguration } from 'store/models/environmentConfiguration';
import { Thunk } from '.';
import { setCdrActiveSourceNetwork } from './cdrReports/sourceNetwork';
import { fetchData } from './fetchData';
import { hydrateHuser } from './login';
import { sentryLogError } from 'sentry';

export enum BOOTSTRAP_ACTION_TYPE {
  GET_BOOTSTRAP = 'GET_BOOTSTRAP',
  SET_PAGE_LIMIT = 'SET_PAGE_LIMIT',
}

export type BootstrapAction =
  | {
      type: BOOTSTRAP_ACTION_TYPE.GET_BOOTSTRAP;
      payload: EnvironmentConfiguration;
    }
  | {
      type: BOOTSTRAP_ACTION_TYPE.SET_PAGE_LIMIT;
      payload: number;
    };

export function getBootstrap(): Thunk<Promise<void>> {
  return async (dispatch) => {
    await dispatch(hydrateHuser());

    const options: AxiosRequestConfig = {
      url: config.apis.bootstrap,
      method: 'GET',
    };
    let payload: EnvironmentConfiguration | null = null;
    try {
      payload = await dispatch(fetchData<EnvironmentConfiguration>(options));
    } catch (e) {
      sentryLogError(e);
    }

    if (payload?.source_networks) {
      dispatch(setCdrActiveSourceNetwork(payload.source_networks[0]));
    }

    dispatch({
      type: BOOTSTRAP_ACTION_TYPE.GET_BOOTSTRAP,
      payload,
    });
  };
}

export function setPageLimit(pageLimit: number) {
  return {
    type: BOOTSTRAP_ACTION_TYPE.SET_PAGE_LIMIT,
    payload: pageLimit,
  };
}
