import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { useCallback, useMemo } from 'react';
import { useUsims5gDetailsSelector } from 'store/selectors/5gProvisioning/usimCards';
import { useIntl } from 'react-intl';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import KeyValue from 'components/Details/KeyValue';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { isEntityLoading } from 'store/reducers';
import { getKText } from 'store/models/usim';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { EditUsimCard5g } from 'components/Edit/5gProvisioning/UsimCards/Edit';
import UsimStatus from 'components/UsimStatus';
import { DetailPageSkeleton } from 'components/Skeletons/DetailPageSkeleton';

export function UsimDetails() {
  const usim5gDetailsEntity = useUsims5gDetailsSelector();
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();

  const entity = useMemo(() => {
    return usim5gDetailsEntity.data;
  }, [usim5gDetailsEntity]);

  const handleOpenEditGeneral = useCallback(() => {
    if (!entity) {
      return;
    }

    dialogOpen({
      title: formatMessage({ id: 'usims.actions.edit.title' }),
      content: () => <EditUsimCard5g usim5g={entity} />,
    });
  }, [dialogOpen, entity, formatMessage]);

  //const openProvisioningStatusEditor = useCallback(() => {
  //  if (!entity) {
  //    return;
  //  }
  //  dialogOpen({
  //    title: formatMessage({ id: 'usims.actions.edit.title' }),
  //    content: () => <></>,
  //  });
  //}, [dialogOpen, formatMessage, entity]);

  if (!entity) {
    return null;
  }

  return isEntityLoading(usim5gDetailsEntity) ? (
    <DetailPageSkeleton />
  ) : entity ? (
    <GridContainer sx={{ mt: 1 }}>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={2}>
          <Panel
            title={formatMessage({ id: 'usims.id.details.generalData' })}
            headerActionsComponent={<IconButton name="Pencil" onClick={handleOpenEditGeneral} fontSize="small" />}
          >
            <PanelContent>
              <GridContainer>
                <KeyValue label={formatMessage({ id: 'usims.table.name' })} value={entity.name} fullwidth />
                <KeyValue label={formatMessage({ id: 'usims.table.supi' })} value={entity.imsi} />
                <KeyValue label={formatMessage({ id: 'usims.table.msisdn' })} value={entity.msisdn} />
              </GridContainer>
            </PanelContent>
            <PanelContent title="AKA Authentication" titleColor="text.secondary">
              <GridContainer>
                <KeyValue
                  label={formatMessage({ id: 'usims.table.k' })}
                  value={getKText({
                    k: entity.k,
                    encrypt: entity.encrypt,
                    use_key: entity.use_key,
                  })}
                />
                <KeyValue label={formatMessage({ id: 'usims.table.op' })} value={entity.op ? 'Set' : 'Not Set'} />
                <KeyValue label={formatMessage({ id: 'usims.table.opc' })} value={entity.opc ? 'Set' : 'Not Set'} />
              </GridContainer>
            </PanelContent>
          </Panel>
        </Stack>
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={2}>
          <Panel
            title={formatMessage({ id: 'usims.table.status' })}
            //</Stack>headerActionsComponent={
            // <IconButton name="Pencil" onClick={() => openProvisioningStatusEditor()} fontSize="small" />
            //}
          >
            <PanelContent>
              <GridContainer>
                <KeyValue
                  label={formatMessage({ id: 'usims.id.details.provisioningStatus' })}
                  valueComponent={<UsimStatus status={entity.status || ''} error={undefined} updating={undefined} />}
                />
                <KeyValue label={formatMessage({ id: 'usims.table.nodeName' })} value={entity.node_name || '-'} />
              </GridContainer>
            </PanelContent>
          </Panel>
          <Box>
            <GridContainer>
              <GridItem size={{ xs: 12, sm: 6 }}>
                <Panel title={formatMessage({ id: 'usims.table.tenantName' })}>
                  <PanelContent>
                    <Text>{entity.tenant.name || '-'}</Text>
                  </PanelContent>
                </Panel>
              </GridItem>
              <GridItem size={{ xs: 12, sm: 6 }}>
                <Panel title={formatMessage({ id: 'usims.table.profileName' })}>
                  <PanelContent>
                    <Text>{entity.profile_name || '-'}</Text>
                  </PanelContent>
                </Panel>
              </GridItem>
            </GridContainer>
          </Box>
        </Stack>
      </GridItem>
    </GridContainer>
  ) : null;
}
