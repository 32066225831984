import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { createPlmn } from 'store/actions/plmns';
import { useNodeEntitySelector } from 'store/selectors/nodes';
import { number, object, string } from 'yup';

export function CreatePlmnContent() {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const nodeEntity = useNodeEntitySelector();

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().trim().required(),
        mcc: number().max(999).required(),
        mnc: number().max(999).required(),
      }),
    []
  );

  const initials = useMemo(
    () => ({
      name: '',
      mcc: '',
      mnc: '',
    }),
    []
  );

  const handleCreatePlmn = useCallback(
    async ({ name, mnc, mcc }) => {
      if (!nodeEntity.data) {
        return;
      }
      const paddedMcc = String(mcc).padStart(3, '0');
      const paddedMnc = String(mnc).padStart(2, '0');

      setLoading(true);
      const errorRes = await dispatch(
        createPlmn({ plmn: { name, mcc: paddedMcc, mnc: paddedMnc }, nodeId: nodeEntity.data.id })
      );
      setLoading(false);
      if (errorRes) {
        setError(errorRes);
      } else {
        setError(null);
        dialogClose();
      }
    },
    [dialogClose, dispatch, nodeEntity]
  );

  const getInputTextsError = useCallback(
    (inputname, errors, touched) => ({
      label: formatMessage({ id: `plmns.form.${inputname}` }),
      placeholder: formatMessage({ id: `plmns.form.${inputname}.placeholder` }),
      error: Boolean(errors[inputname] && touched[inputname]),
      ...(Boolean(errors[inputname] && touched[inputname]) && {
        helperText: formatMessage({ id: `plmns.form.${inputname}.error` }),
      }),
    }),
    [formatMessage]
  );

  return (
    <Formik
      initialValues={initials}
      enableReinitialize={true}
      onSubmit={handleCreatePlmn}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ errors, touched }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <DialogContent>
              <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
                <Field name="name" key="name">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      required
                      fullWidth
                      {...field}
                      size="small"
                      {...getInputTextsError('name', errors, touched)}
                    />
                  )}
                </Field>
                <Field name="mcc" key="mcc">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      required
                      fullWidth
                      {...field}
                      size="small"
                      {...getInputTextsError('mcc', errors, touched)}
                    />
                  )}
                </Field>
                <Field name="mnc" key="mnc">
                  {({ field }: FieldProps<string>) => (
                    <TextField
                      required
                      fullWidth
                      {...field}
                      size="small"
                      {...getInputTextsError('mnc', errors, touched)}
                    />
                  )}
                </Field>
              </Stack>
            </DialogContent>

            <DialogActions>
              <Stack spacing={2} sx={{ pt: 2 }} align="flex-end">
                {error && (
                  <Box sx={{ width: '100%' }}>
                    <Alert
                      severity="error"
                      title="Error"
                      message={`There was an unexpected error and the PLMN could not be created (${error})`}
                    />
                  </Box>
                )}
                <Stack spacing={2} direction="row" justify="flex-end">
                  <Button
                    variant="outlined"
                    data-testid="footer-cancel"
                    onClick={dialogClose}
                    text={formatMessage({ id: 'common.form.cancel' })}
                    loading={loading}
                  />
                  <Button
                    data-testid="footer-continue"
                    type="submit"
                    text={
                      error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })
                    }
                    loading={loading}
                  />
                </Stack>
              </Stack>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
