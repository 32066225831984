import { Button } from '@athonet/ui/components/Input/Button';
import { TextField } from '@athonet/ui/components/Input/TextField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';
import { NewNodeStepProps } from './CreateNodeContent';

export function Step2({ onCompleteStep, initialValues }: NewNodeStepProps) {
  const { formatMessage } = useIntl();

  const validationSchema = useMemo(() => {
    return object().shape({
      display_name: string().required(),
    });
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      onCompleteStep(1, values);
    },
    [onCompleteStep]
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ errors, touched }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
              <Field name="display_name">
                {({ field }: FieldProps<string>) => (
                  <TextField
                    fullWidth
                    size="small"
                    {...field}
                    label={formatMessage({ id: 'nodes.form.name' })}
                    placeholder={formatMessage({ id: 'nodes.form.display_name.placeholder' })}
                    error={Boolean(errors['display_name'] && touched['display_name'])}
                    {...(Boolean(errors['display_name'] && touched['display_name']) && {
                      helperText: formatMessage({ id: 'nodes.form.display_name.error' }),
                    })}
                  />
                )}
              </Field>

              <Button type="submit" variant="outlined" text={formatMessage({ id: 'common.form.next' })} />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
