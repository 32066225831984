import { Select, SelectChangeEvent } from '@athonet/ui/components/Input/Select';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import theme from '@athonet/ui/theme';
import filesize from 'filesize';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { CdrPeriod, CdrTrafficVolumeMetricsField } from 'store/models/cdr';
import { useCdrTrafficVolumeRankingMetricOptions } from 'store/selectors/cdrReports/trafficVolume';
import { getTooltipDateTitle, metricTooltipFormatter } from '../utils/utils';
import TrendChart, { TrendChartProps } from '../TrendChart';

export const volumePaletteColors = {
  totallink: theme.palette.series1.main,
  downlink: theme.palette.series2.main,
  uplink: theme.palette.series3.main,
};

export type TrafficVolumeTrendChartProps = Pick<TrendChartProps, 'isLoading' | 'title' | 'name' | 'height'> & {
  trafficVolumePeriod: CdrPeriod;
  seriesData:
    | {
        totallink: [string, number | string][];
        uplink: [string, number | string][];
        downlink: [string, number | string][];
      }
    | undefined;
};

export default function TrafficVolumeTrendChart({
  isLoading,
  title,
  name,
  seriesData,
  height = 180,
  trafficVolumePeriod,
}: TrafficVolumeTrendChartProps) {
  const metricOptions = useCdrTrafficVolumeRankingMetricOptions();

  const [metric, setMetric] = useState<CdrTrafficVolumeMetricsField>('totallink');

  const tooltipDateTitle = useMemo(() => {
    return getTooltipDateTitle(trafficVolumePeriod);
  }, [trafficVolumePeriod]);

  const tooltipFormatter = useCallback(
    (params: any) => {
      return metricTooltipFormatter(params, tooltipDateTitle, 'fileSize');
    },

    [tooltipDateTitle]
  );

  const handleMetricChange = useCallback(
    (e: SelectChangeEvent) => {
      setMetric(e.target.value as CdrTrafficVolumeMetricsField);
    },
    [setMetric]
  );

  return (
    <TrendChart
      height={height}
      isLoading={isLoading}
      title={title}
      name={name}
      seriesData={seriesData && seriesData[metric]}
      color={volumePaletteColors[metric]}
      xAxisFormat={(value: string) => moment(value).format(tooltipDateTitle)}
      yAxisFormat={(value: number) => filesize(value, { base: 2, standard: 'jedec' })}
      legendFormat={`${metric}`}
      tooltipFormat={tooltipFormatter}
      data-testid="cdr-traffic-difference-totallink-trend-chart"
      headerActionsComponent={
        <Box sx={{ width: { xs: '100%', md: '150px' } }}>
          <Select
            label="Traffic"
            onChange={handleMetricChange}
            size={'small'}
            value={metricOptions.length ? metric : ''}
            data-testid="cdr-toolbar-metric-select"
          >
            {metricOptions?.map((option) => (
              <MenuItem value={option.value} key={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      }
    />
  );
}
