import { useEffect, useState, useCallback, useMemo } from 'react';
import { useFetchData } from 'hooks/useFetchData';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import Base from 'components/Edit/Base';
import { FormStyled, AddListStyled } from '../styled';
import TenantsSitesList from 'components/Edit/Nodes/TenantsSitesList';
import config from 'config';
import { useDispatch } from 'react-redux';
import { getNode } from 'store/actions/nodes';
import { sentryLogError } from 'sentry';

const ManageSites = ({ isOpen, onClose, nodeId }) => {
  const { formatMessage } = useIntl();
  const fetchData = useFetchData();
  const [allTenants, setAllTenants] = useState([]);
  const [sites, setSites] = useState([]);
  const [siteDisabled, setSiteDisabled] = useState(true);
  const [tenantList, setTenantList] = useState([]);
  const dispatch = useDispatch();

  const getNodeTenantsList = useCallback(async () => {
    const options = {
      url: `${config.apis.getNodeTenants.replace('{node_id}', nodeId)}`,
    };

    fetchData(options)
      .then((result) => {
        setTenantList(result);
      })
      .catch((e) => {
        sentryLogError(e);
      });
  }, [fetchData, nodeId]);

  const onAddTenantSite = (values, callback) => {
    const tenantSiteData = {
      tenant_id: values.tenant,
      site_id: values.site,
      node_id: nodeId,
    };

    const options = {
      url: config.apis.addTenantToNode,
      method: 'POST',
      data: tenantSiteData,
    };

    fetchData(options)
      .then(() => {
        void getNodeTenantsList();
        callback(true);
      })
      .catch((error) => {
        callback(
          false,
          error?.response?.status === 409 ? formatMessage({ id: 'nodes.form.tenants.error.conflict' }) : undefined
        );
      })
      .finally(() => void dispatch(getNode(nodeId, true)));
  };

  useEffect(() => {
    void getNodeTenantsList();
  }, [getNodeTenantsList]);

  const getAllTenants = useCallback(async () => {
    return new Promise(function (resolve, reject) {
      const options = {
        url: config.apis.getAllTenantsWithSites,
      };

      fetchData(options)
        .then((result) => {
          resolve(result);
        })
        .catch(() => {
          reject();
        });
    });
  }, [fetchData]);

  useEffect(() => {
    void getAllTenants().then((result) => {
      setAllTenants(result);
    });
  }, [getAllTenants]);

  const onDeleteTenantSite = (tenant_id, site_id) => {
    const deleteData = {
      tenant_id: tenant_id,
      site_id: site_id,
      node_id: nodeId,
    };

    const options = {
      url: config.apis.deleteTenantToNode,
      method: 'POST',
      data: deleteData,
    };

    fetchData(options)
      .then(() => {
        void getNodeTenantsList();
      })
      .catch((e) => {
        sentryLogError(e);
      })
      .finally(() => void dispatch(getNode(nodeId, true)));
  };

  const onChangeTenant = useCallback(
    (setFieldValue, val) => {
      const tenantsSites = allTenants.filter((item) => item.id === val.target.value)[0]?.sites;
      setFieldValue('site', '');
      setSiteDisabled(false);
      setSites(tenantsSites);
    },
    [allTenants]
  );

  const schema = object().shape({
    tenant: string().required(),
    site: string().required(),
  });

  const fieldset = useMemo(
    () => [
      {
        title: formatMessage({ id: 'nodes.form.addSites' }),
        data: [
          {
            name: 'tenant',
            label: formatMessage({ id: 'nodes.form.tenant' }),
            placeholder: formatMessage({ id: 'nodes.form.tenant.placeholder' }),
            error: formatMessage({ id: 'nodes.form.tenant.error' }),
            options: allTenants?.map((item) => {
              return { value: item.id, label: item.name };
            }),
            gridClassName: 'fullwidth',
            onChange: onChangeTenant,
          },
          {
            name: 'site',
            label: formatMessage({ id: 'nodes.form.site' }),
            placeholder: formatMessage({ id: 'nodes.form.site.placeholder' }),
            error: formatMessage({ id: 'nodes.form.site.error' }),
            options: sites?.map((item) => {
              return { value: item.id, label: item.name };
            }),
            gridClassName: 'fullwidth',
            disabled: siteDisabled,
          },
        ],
      },
    ],
    [allTenants, formatMessage, onChangeTenant, siteDisabled, sites]
  );

  const initials = {
    tenant: '',
    site: '',
  };

  return (
    <FormStyled>
      <Base
        schema={schema}
        initials={initials}
        isOpen={isOpen}
        onClose={onClose}
        fieldset={fieldset}
        onSubmit={onAddTenantSite}
        modalTitle={formatMessage({ id: 'nodes.form.manageSites' })}
        ComponentBottom={<TenantsSitesList data={tenantList} onDeleteTenant={onDeleteTenantSite} />}
        labelContinue={formatMessage({ id: 'common.button.add' })}
        labelCancel={formatMessage({ id: 'common.form.close' })}
        StyleFormWrapper={AddListStyled}
        keepOpen
      />
    </FormStyled>
  );
};

export default ManageSites;
