import { useCallback, useMemo } from 'react';
import { string } from 'yup';
import { useIntl } from 'react-intl';
import { filtersSet } from 'store/actions/nodes';
import { useDispatch } from 'react-redux';
import { getDefaultValue } from 'store/models/filters';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { FiltersDrawer, FiltersDrawerItem, FiltersProps } from '../Drawer';

export default function Nodes({ values }: FiltersProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'name',
        label: formatMessage({ id: 'nodes.filters.name' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['display_name'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: string(),
      },
    ],
    [formatMessage, values]
  );

  const handleSubmit = useCallback((filtersValues) => dispatch(filtersSet(filtersValues)), [dispatch]);
  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
