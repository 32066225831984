import moment from 'moment';
import { Locale } from 'utils/locale';
import { lsSetLocale } from 'utils/localStorage';

export enum LOCALE_ACTION_TYPE {
  SET_LOCALE = 'SET_LOCALE',
}

export type LocaleAction = {
  type: LOCALE_ACTION_TYPE;
  payload: Locale;
};

export function setLocale(payload: Locale) {
  moment.locale(payload);
  lsSetLocale(payload);
  return {
    type: LOCALE_ACTION_TYPE.SET_LOCALE,
    payload,
  };
}
